import * as React from 'react';
import { useCallback } from 'react';
import { getCustomersSortByFilter } from '../../services/utils/sortData';
import { EntityPaginationData } from '../../components/molecules/EntityPaginationData';
import { SORT_BY_CUSTOMERS, SUBSCRIPTION } from '../../interfaces/enumerables';
import { useCustomers } from '../../api/hooks/customers/useCustomers';
import { CustomerItemCard } from './components/CustomerItemCard';
import { usePlanIsActive } from '../../services/utils/usePlanIsActive';

export const CustomersScreen = () => {
  const showSubtotals = usePlanIsActive(SUBSCRIPTION.SUBTOTALS);
  const sortByFilter = useCallback(() => getCustomersSortByFilter(showSubtotals), [showSubtotals]);

  return (
    <EntityPaginationData
      useDataHook={useCustomers}
      ItemCardData={CustomerItemCard}
      sortByFilter={sortByFilter()}
      sortByDefaultKey={SORT_BY_CUSTOMERS.COMPANY_NAME}
    />
  );
};
