export const emptyProductObject = {
  productId: null,
  product: null,
  name: '',
  description: '',
  quantity: 1,
  unit: '',
  price: 0,
  finalCost: 0,
  discountPercentage: 0,
  vatPercentage: 0,
  vatExceptionCategoryId: null,
  saleCategoryId: null,
  saleCharacterizationId: null,
  productCategoryId: null
};

// TODO: add currency
// TODO: add type of service/product
// TODO: add quantity type to db

export const MAP_VAT_REGIME: Record<string, { field: string | null; value: string | null }> = {
  '1': { field: 'value', value: null },
  '2': { field: 'reducedValue', value: null },
  '3': { field: null, value: '0' },
};
