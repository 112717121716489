import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IonItem, IonTextarea } from '@ionic/react';

interface Props {
  field: any;
  formik: any;
}

export const DocFooter = ({ field, formik }: Props) => {
  const { t } = useTranslation();

  return (
    <div style={{ minHeight: 200 }} className="docForm padding-t-24 padding-b-24 padding-l-8 padding-r-8">
      <div className="margin-b-16 margin-l-12">
        <div className="mediumText6 margin-b-8">{t('FORMS.PUBLIC_NOTES')}</div>
        <div className="mediumText4 margin-b-16">{t('FORMS.PUBLIC_NOTES_DESCRIPTION')}</div>
      </div>
      <IonItem className="new-sale-notes border-radius-input g-select-border" mode="md" lines="none">
        <div className="border-radius-24">
          <IonTextarea
            className="new-sale-notes"
            mode="md"
            id={`textarea-${field.name}`}
            name={field.name}
            slot="floating"
            maxlength={400}
            onIonChange={formik.handleChange}
            value={formik.values?.[field.name]}
            placeholder={t('FORMS.PUBLIC_NOTES_DESCRIPTION')}
            autoGrow={true}
          />
        </div>
      </IonItem>
    </div>
  );
};
