import * as React from 'react';
import { useCallback } from 'react';
import { InvoiceInterface } from '../../../../interfaces/invoice.interface';
import { useTranslation } from 'react-i18next';
import { getTotalQuantities } from '../../../../services/utils/productCost';
import { getDecimalFixed } from '../../../../config/utils/decimalUtls';

interface Props {
  doc: InvoiceInterface;
}

export const DocViewTotalQuantities = ({ doc }: Props) => {
  const { t } = useTranslation();

  const getSections = useCallback(() => {
    const quantities = getTotalQuantities(doc.products);

    return quantities.map((quan) => {
      return (
        <div className="flex-start-start-row-nowrap" key={quan.label}>
          <div className="width60 normalText1">{t(`PRODUCT_UNITS_SHORT.${quan.label}`)}</div>
          <div className="normalText1">{getDecimalFixed(quan.value)}</div>
        </div>
      );
    });
  }, [doc.products]);

  return (
    <div className="simpleBorder">
      <div className="flex-start-start-column-nowrap padding-2">
        <div className="boldText1">{t('SALE.TOTAL')}</div>
        {getSections()}
      </div>
    </div>
  );
};
