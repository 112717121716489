import * as React from 'react';
import { useCallback } from 'react';
import { LoginForm } from './components/LoginForm';
import { IonImg } from '@ionic/react';
import logo from '../../theme/assets/logo.png';

export const LoginScreen = () => {
  const getLogo = useCallback(() => {
    return (
      <div className="login-logo">
        <IonImg src={logo} style={styles.logo} />
      </div>
    );
  }, []);

  return (
    <div className="unauthorized-view">
      <LoginForm />
      {getLogo()}
    </div>
  );
};

const styles = {
  logo: {
    width: 200,
    height: 100,
  },
};
