import * as React from 'react';
import { useCallback } from 'react';
import { Customer } from '../../../../../interfaces/customer.interface';
import { StyleSheet, View } from '@react-pdf/renderer';
import { CustomerTableLinePdf } from './CustomerTableLinePdf';

interface Props {
  customers: Customer[];
}

export const CustomersReportTableListPdf = ({ customers }: Props) => {
  const getItems = useCallback(() => {
    return customers?.map((item, index) => {
      return (
        <CustomerTableLinePdf
          key={index}
          index={index}
          customer={item}
        />
      );
    });
  }, [customers]);

  return <View style={styles.container}>{getItems()}</View>;
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: 2,
  },
});
