import * as React from 'react';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { SORT_DIRECTION } from '../../interfaces/enumerables';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PATHS } from '../../navigation/data';
import { arrowDownOutline, arrowUpOutline } from 'ionicons/icons';
import { IonButton, IonFabButton, IonIcon, IonSearchbar, IonSelect, IonSelectOption } from '@ionic/react';
import { SortBy } from '../../interfaces/forms.inteface';
import { setSearchParams } from '../../config/utils/urlsUtils';

interface Props {
  sortBy: SortBy;
  setSortBy: (sortBy: SortBy) => void;
  sortDirection: SORT_DIRECTION;
  setSortDirection: (sortDirection: SORT_DIRECTION) => void;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  sortByFilterData: { [key: string]: SortBy };
  hideCreateButton?: boolean;
  hideSearchButton?: boolean;
}

export const Filters = ({
  sortBy,
  setSortBy,
  sortDirection,
  setSortDirection,
  search,
  setSearch,
  sortByFilterData,
  hideCreateButton,
  hideSearchButton,
}: Props) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  // Helper to update query params
  const updateQueryParams = useCallback(
    (params: { [key: string]: string }) => {
      const searchParams = setSearchParams(params, location.search);
      history.replace({ search: searchParams.toString() });
    },
    [history, location.search],
  );

  const addNew = useCallback(() => {
    history.push(`${history.location.pathname}/${PATHS.CREATE_SUB_PATH}`);
  }, [history]);

  const onSortByChange = useCallback(
    (event: any) => {
      const newSortBy = sortByFilterData[event.detail.value];
      setSortBy(newSortBy);
      updateQueryParams({ sortBy: newSortBy.value });
    },
    [setSortBy, sortByFilterData, updateQueryParams],
  );

  const toggleSortDirection = useCallback(() => {
    const newSortDirection = sortDirection === SORT_DIRECTION.ASC ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC;
    setSortDirection(newSortDirection);
    updateQueryParams({ sortDirection: newSortDirection });
  }, [setSortDirection, sortDirection, updateQueryParams]);

  const getSortIcon = useCallback(() => {
    if (sortDirection === SORT_DIRECTION.DESC) return arrowDownOutline;

    return arrowUpOutline;
  }, [sortDirection]);

  const handleSearchChange = useCallback(
    (ev: any) => {
      const value = ev?.detail?.value || '';
      setSearch(value);
      updateQueryParams({ search: value, page: '1' });
    },
    [setSearch, updateQueryParams],
  );

  const getSelectOptions = useCallback(() => {
    return Object.keys(sortByFilterData).map((sortByKey: any) => {
      const val = sortByFilterData[sortByKey].value;
      return (
        <IonSelectOption key={val} value={val}>
          {sortByFilterData[sortByKey].label}
        </IonSelectOption>
      );
    });
  }, [sortByFilterData]);

  const getCreateButton = useCallback(() => {
    if (hideCreateButton) {
      return null;
    }

    return (
      <div className="margin-r-6 margin-t-16">
        <IonButton onClick={addNew}>{t('SHARED.ADD')}</IonButton>
      </div>
    );
  }, [addNew, hideCreateButton, t]);

  const getSearchInput = useCallback(() => {
    if (hideSearchButton) return null;

    return (
      <IonSearchbar
        mode="md"
        debounce={300}
        className="g-search"
        placeholder={t('FILTERS.SEARCH')}
        onIonInput={handleSearchChange}
        value={search}
      />
    );
  }, [hideSearchButton, t, handleSearchChange, search]);

  return (
    <div className="flex-space-between-center-row-wrap margin-b-10">
      <div className="flex-start-center-row-wrap">
        {getCreateButton()}
        <div className="margin-r-6 margin-t-16">
          <IonSelect
            mode="ios"
            interface="action-sheet"
            value={sortBy.value}
            placeholder={t('FILTERS.SORT_BY')}
            cancelText={t('SHARED.CANCEL')}
            onIonChange={onSortByChange}>
            {getSelectOptions()}
          </IonSelect>
        </div>
        <div className="margin-t-16">
          <IonFabButton size="small" onClick={toggleSortDirection}>
            <IonIcon icon={getSortIcon()} size="small" />
          </IonFabButton>
        </div>
      </div>
      <div className="margin-t-16">{getSearchInput()}</div>
    </div>
  );
};
