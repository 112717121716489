import { IonItem } from '@ionic/react';
import { Select } from 'antd';
import { FormikValues } from 'formik/dist/types';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { showError } from '../../../config/utils/formDiffUtils';
import { DocumentTypeInterface } from '../../../interfaces/documentType.interface';
import { DELIVERY_NOTE_DOC_TYPE_AADE_CODE } from '../../../interfaces/enumerables';
import { EntityFormField } from '../../../interfaces/forms.inteface';
import { useIsCloneOrEdit } from '../../../services/hooks/useIsCloneOrEdit';
import { ErrorForm } from '../../atoms/ErrorForm';

interface Props {
  field: EntityFormField<any>;
  formik: FormikValues;
}

const docsRequireTransportDate = ['1.1', '1.2', '1.12', '9.3'];

export const DocType = ({ field, formik }: Props) => {
  const { t } = useTranslation();
  const { originalCustomer } = useIsCloneOrEdit();
  const customerSelected = useMemo(() => formik?.values?.customerId, [formik?.values?.customerId]);
  const customerAddress = useMemo(() => customerSelected?.address, [customerSelected?.city]);
  const hasError = useMemo(
    () => showError(formik, formik?.errors?.[field.name]),
    [formik?.errors?.[field.name], formik.touched],
  );

  /**
   * When delivery note set `deliverTo` address with customer address
   */
  useEffect(() => {
    const aadeCode = formik?.values?.[field.name]?.aadeCode || formik?.values?.[field.name]?.object?.aadeCode;
    if (aadeCode === DELIVERY_NOTE_DOC_TYPE_AADE_CODE) {
      if (customerAddress && customerSelected?.id !== originalCustomer && customerAddress !== field.value) {
        formik?.setFieldValue('shipTo', customerAddress?.slice(0, 198));
      }
    }
  }, [formik?.values?.[field.name], customerSelected]);

  /**
   * Following is needed only for initial render on clone doc
   */
  useEffect(() => {
    const aadeCode = formik?.values?.[field.name]?.aadeCode || formik?.values?.[field.name]?.object?.aadeCode;
    const res = docsRequireTransportDate.includes(aadeCode);
    if (!res) {
      formik.setFieldValue('shipDate', null);
    }
  }, []);

  const updateTransportDate = useCallback((docType: DocumentTypeInterface) => {
    const res = docsRequireTransportDate.includes(docType?.aadeCode);
    if (!res) {
      formik.setFieldValue('shipDate', null);
    } else {
      formik.setFieldValue('shipDate', new Date(formik.values?.['shipDate'] || '').toISOString());
    }
  }, []);

  const updateDependencies = useCallback(
    (docType: DocumentTypeInterface) => {
      updateTransportDate(docType);
    },
    [updateTransportDate],
  );

  const onChange = (value: string, option: any) => {
    formik.setFieldValue(field.name, option);
    updateDependencies(option?.object);
  };

  const getOptions = useCallback(() => {
    // @ts-expect-error mismatch of interfaces
    return field?.data?.map((option: DocumentTypeInterface) => {
      return {
        label: option ? `(${option?.aadeCode}) ` + t(`DOC_TYPES.${option?.name}`) : '',
        value: option.id,
        object: option,
      };
    });
  }, [field?.data]);

  return (
    <div>
      <div className="padding-b-16 padding-l-12">{t('SALE.DOC_TYPE')} *</div>
      <div
        key={field.name}
        className="flex-start-center-column-nowrap positionRelative min-width-300px max-width-460px">
        <IonItem
          className={`min-width-300px max-width-460px border-radius-input g-select-border ${
            hasError && 'b-field-border-error'
          }`}
          mode="md">
          <Select
            placeholder={t(`SALE.${field?.placeholder}`) + '...'}
            className="my-ant-select normalText5"
            variant="borderless"
            onChange={onChange}
            options={getOptions()}
            defaultValue={formik.values[field.name]}
          />
        </IonItem>
        <ErrorForm styleName="margin-t-minus-14">
          {formik.errors?.[field.name] && formik.errors?.[field.name]}
        </ErrorForm>
      </div>
    </div>
  );
};
