import { Search } from 'history';

export const setSearchParams = (params: { [key: string]: string }, locationSearch: Search) => {
  const searchParams = new URLSearchParams(locationSearch);
  Object.keys(params).forEach((key) => {
    if (params[key]) {
      searchParams.set(key, params[key]);
    } else {
      searchParams.delete(key);
    }
  });

  return searchParams;
};
