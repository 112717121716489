import * as React from 'react';
import { useCallback } from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import { useHistory, useLocation } from 'react-router-dom';

export const MyBackButton = () => {
  const history = useHistory();
  const location = useLocation();

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const getButton = useCallback(() => {
    if (location?.pathname !== '/') {
      return (
        <IonButton onClick={goBack}>
          <IonIcon slot="icon-only" icon={arrowBack} />
        </IonButton>
      );
    }

    return null;
  }, [goBack, location?.pathname]);

  return getButton();
};
