import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { pdfStyles } from '../../../../../components/molecules/pdf/pdfStyles';

export const CustomersReportTableHeaderPdf = () => {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <Text style={[pdfStyles.smallTitleRegular, styles.section, styles.center, { flex: 1, paddingLeft: 4 }]}>
        {t('CUSTOMERS_REPORT.CUSTOMER_NAME')}
      </Text>
      <Text style={[pdfStyles.smallTitleRegular, styles.section, styles.center, pdfStyles.width80]}>
        {t('CUSTOMERS_REPORT.CUSTOMER_SUBTOTAL')}
      </Text>
      <Text style={[pdfStyles.smallTitleRegular, styles.center, pdfStyles.width80]}>
        {t('CUSTOMERS_REPORT.TRADER_SUBTOTAL')}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    backgroundColor: '#c9cace',
    padding: 2,
  },
  section: {
    borderRightWidth: 1,
    borderColor: 'white',
  },
  center: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
