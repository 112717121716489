import * as React from 'react';
import { useCallback } from 'react';
import { DocumentProps, PDFDownloadLink } from '@react-pdf/renderer';
import { pdfStyles } from './pdfStyles';
import { LoadingIndicator } from '../../atoms/LoadingIndicator';

interface Props {
  document: React.ReactElement<DocumentProps>;
  fileName: string;
  text: string;
  isLoading?: boolean;
}

export const PdfDownloadLinkExtended = ({ document, fileName, text, isLoading }: Props) => {
  const getLoading = useCallback(() => {
    if (isLoading) {
      return (
        <div
          className="loading-indicator"
          style={{ position: 'absolute', width: '100%', height: 36, alignItems: 'center', justifyContent: 'center' }}>
          <div className="flex-center-center-column-nowrap width-100">
            <LoadingIndicator name="dots" size={34} />
          </div>
        </div>
      );
    }
  }, [isLoading]);

  return (
    <div className="positionRelative">
      {getLoading()}
      <PDFDownloadLink document={document} fileName={fileName} style={pdfStyles.ionButton}>
        {text}
      </PDFDownloadLink>
    </div>
  );
};
