import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  formik: any;
  index: number;
}

export const DocProductVat = ({ formik, index }: Props) => {
  const { t } = useTranslation();
  const vat = useMemo(
    () => formik.values?.products?.[index]?.vatPercentage,
    [formik.values?.products?.[index]?.vatPercentage],
  );

  return (
    <div className="border-1-solid border-radius-10 padding-10">
      {t('SALE.VAT')} {vat}%
    </div>
  );
};
