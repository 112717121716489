import * as React from 'react';
import { useCallback } from 'react';
import { IonSpinner } from '@ionic/react';
import { useAppSelector } from '../../store/hooks';
import { selectCoreServerError } from '../../store/core/coreSlice';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const logo = require('../../theme/assets/logo.png');

export const LoadingScreen = () => {
  const error = useAppSelector(selectCoreServerError);

  const getLoading = useCallback(() => {
    if (error) {
      return (
        <div className="mediumText6 text-center darkText" style={{ whiteSpace: 'pre-wrap' }}>
          {error}
        </div>
      );
    }

    return <IonSpinner name="circular" />;
  }, [error]);

  return (
    <div style={styles.container}>
      {/*<IonImg src={logo} style={styles.logo} />*/}
      <div className="boldTextHead padding-b-16">BillEase</div>
      {getLoading()}
    </div>
  );
};

const styles = {
  container: {
    background: '#c1cad0',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column nowrap',
    zIndex: 100,
  },
  logo: {
    width: 250,
    height: 150,
    // width: 200,
    // height: 100,
    zIndex: 101,
  },
};
