import * as React from 'react';
import { Customer } from '../../../../../interfaces/customer.interface';

interface Props {
  index: number;
  customer: Customer;
}

export const CustomerTableLineView = ({ index, customer }: Props) => {
  return (
    <div className="flex-start-start-row-nowrap padding-2 width-100 positionRelative">
      <div className="flex1 flex-start-start-column-nowrap padding-l-4">
        <div className="productIndexSection">
          <div className="normalText1">{index + 1}.</div>
        </div>
        <div className="boldText1 truncate-2lines">{customer?.companyName}</div>
      </div>
      <div className="normalText1 text-center width110">{customer?.customerSubtotal}</div>
      <div className="normalText1 text-center width110">{customer?.supplierSubtotal}</div>
    </div>
  );
};
