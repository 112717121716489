import * as React from 'react';
import { useCallback, useContext } from 'react';
import { ProductInOrder } from '../../../../interfaces/product.interface';
import { DocViewProduct } from './DocViewProduct';
import { getProdIndexInDoc } from '../../../../services/utils/pdf';
import {
  SaleContextInterface,
  SaleScreenContextProvider,
} from '../../../../screens/Sales/components/SaleScreenContext';

interface Props {
  products: ProductInOrder[];
  docHasVatExceptionCategory: boolean;
}

export const DocViewProductsList = ({ products, docHasVatExceptionCategory }: Props) => {
  const { doc } = useContext<SaleContextInterface>(SaleScreenContextProvider);

  const getProductIndex = useCallback(
    (product: ProductInOrder) => {
      if (doc) {
        return getProdIndexInDoc(doc?.products, product);
      }

      return 0;
    },
    [doc],
  );

  const getProducts = () => {
    return products?.map((prod, index) => {
      const itemIndex = getProductIndex(prod);
      return (
        <DocViewProduct
          key={index}
          product={prod}
          index={itemIndex}
          docHasVatExceptionCategory={docHasVatExceptionCategory}
        />
      );
    });
  };

  return <div className="flex-start-start-column-nowrap padding-2 width100">{getProducts()}</div>;
};
