import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useAppSelector } from '../../../store/hooks';
import { selectShippingOfCompany } from '../../../store/companies/companiesSlice';
import { BSelectSearchInput } from '../BSelectSearchInput';
import { TEST_IDS } from '../../../config/utils/constants';

interface Props {
  field: any;
  formik: any;
}

export const DocDelivery = ({ field, formik }: Props) => {
  const companyShippingSelector = useAppSelector(selectShippingOfCompany);

  const companyShipping = useMemo(() => {
    return companyShippingSelector.map((option) => {
      return {
        isMine: true,
        label: option,
        value: option,
      };
    });
  }, [companyShippingSelector]);

  const customerShipping = useMemo(() => {
    const list = formik?.values?.['customerId']?.shipping || [];

    return list.map((option: string) => {
      return {
        isMine: false,
        label: option,
        value: option,
      };
    });
  }, [formik?.values?.['customerId']]);

  const getShipping = useCallback(() => {
    return [...companyShipping, ...customerShipping];
  }, [companyShipping, customerShipping]);

  return (
    <BSelectSearchInput
      field={field}
      formik={formik}
      options={getShipping()}
      testId={TEST_IDS.Invoice.shippingSelect}
    />
  );
};
