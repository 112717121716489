import { GenericResponse } from '../../../interfaces/shared.interface';
import { apiRQ } from '../../api';
import { useMutation } from 'react-query';
import { store } from '../../../store/store';
import { setToast } from '../../../store/core/coreSlice';
import i18next from 'i18next';
import { PATHS } from '../../../navigation/data';
import { queryClient } from '../../APIProvider';

const deleteMoneyCollection = async (data: any): Promise<GenericResponse> => {
  const resp = await apiRQ.delete<GenericResponse>('/money-collection', { data });

  return resp.data;
};

export const useDeleteMoneyCollection = (history: any) => {
  return useMutation<GenericResponse, Error>(deleteMoneyCollection, {
    onSuccess: (data) => {
      if (data.success) {
        store.dispatch(setToast({ value: { show: true, msg: i18next.t('SHARED.DELETE_SUCCESSFUL') } }));
        history.replace(PATHS.MONEY_COLLECTION);
        return;
      }

      let errorMsg = i18next.t('API_ERRORS.SOMETHING_WENT_WRONG');

      if (typeof data?.error === 'string') errorMsg = i18next.t(`API_ERRORS.${data.error}`);
      if (data?.errorCode) errorMsg = i18next.t(`API_ERRORS.${data.errorCode}`);

      store.dispatch(
        setToast({
          value: { show: true, msg: errorMsg, isError: true },
        }),
      );
    },
    onSettled: () => {
      queryClient?.invalidateQueries({ queryKey: ['useMoneyCollectionList'] });
    },
  });
};
