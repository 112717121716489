import { IonButtons, IonContent, IonHeader, IonMenuButton, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LogoutButton } from '../components/atoms/LogoutButton';
import { MyBackButton } from '../components/atoms/MyBackButton';

const screens: Record<string, { title: string }> = {
  sales: {
    title: 'SALES',
  },
  customers: {
    title: 'CUSTOMERS',
  },
  products: {
    title: 'PRODUCTS',
  },
  companies: {
    title: 'COMPANIES',
  },
  'money-collection': {
    title: 'MONEY_COLLECTION',
  },
  reports: {
    title: 'REPORTS',
  },
};

interface Props {
  children?: JSX.Element;
}

const Navbar: React.FC<Props> = ({ children }: Props) => {
  let title = 'HOME';
  const { t } = useTranslation();
  const location = useLocation();
  const path = location?.pathname?.split('/')[1];
  title = screens?.[path]?.title || title;

  return (
    <>
      <IonHeader mode="md">
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
            <MyBackButton />
          </IonButtons>
          <IonTitle className="nav-title">{t(`TABS.${title}`)}</IonTitle>
          <IonButtons slot="end">
            <LogoutButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {/*<IonHeader collapse="condense">*/}
        {/*  <IonToolbar>*/}
        {/*    <IonTitle size="large">{t(`TABS.${title}`)} 1</IonTitle>*/}
        {/*  </IonToolbar>*/}
        {/*</IonHeader>*/}
        {children}
      </IonContent>
    </>
  );
};

export default Navbar;
