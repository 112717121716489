import * as React from 'react';
import { useCallback } from 'react';
import { Customer } from '../../../../../interfaces/customer.interface';
import { useTranslation } from 'react-i18next';
import { CURRENCY } from '../../../../../interfaces/enumerables';
import { addWithDecimals } from '../../../../../config/utils/calcUtils';

interface Props {
  customers: Customer[];
}

export const CustomerTableTotalsView = ({ customers }: Props) => {
  const { t } = useTranslation();

  const getCustomersSubtotal = useCallback(() => {
    let initVal = '0.00';
    for (let i = 0; i < customers.length; i++) {
      initVal = addWithDecimals(initVal, customers[i]?.customerSubtotal);
    }

    return initVal;
  }, [customers]);

  const getSuppliersSubtotal = useCallback(() => {
    let initVal = '0.00';
    for (let i = 0; i < customers.length; i++) {
      initVal = addWithDecimals(initVal, customers[i]?.supplierSubtotal);
    }

    return initVal;
  }, [customers]);

  return (
    <div className="flex-start-start-row-nowrap padding-t-10">
      <div className="flex1 flex-start-start-column-nowrap padding-l-8">
        <div className="boldText1">{t('CUSTOMERS_REPORT.TOTALS')}</div>
      </div>
      <div className="boldText1 text-center width110">{getCustomersSubtotal()} {CURRENCY.EURO}</div>
      <div className="boldText1 text-center width110">{getSuppliersSubtotal()} {CURRENCY.EURO}</div>
    </div>
  );
};
