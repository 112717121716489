import * as React from 'react';
import { useCallback } from 'react';
import { EntityFormField } from '../../../interfaces/forms.inteface';
import { UseQueryResult } from 'react-query';
import { Error, GetEntities } from '../../../interfaces/shared.interface';
import { FormikValues } from 'formik/dist/types';
import { BSelectSearch } from '../../../components/molecules/BSelectSearch';
import { useParams } from 'react-router';

interface Props {
  field: EntityFormField<any>;
  useHook: (
    page: number,
    orderBy: string | undefined,
    order: string | undefined,
    search: string,
  ) => UseQueryResult<GetEntities<any>, Error>;
  formik: FormikValues;
}

export const CustomerDoySelect = ({ field, useHook, formik }: Props) => {
  const { id } = useParams<{ id: string }>();
  const formValue = formik?.values?.[field.name]?.id || formik?.values?.[field.name];

  const getSelect = useCallback(() => {
    if (id === undefined && formValue?.label) {
      return <BSelectSearch key="2" field={field} useHook={useHook} formik={formik} />;
    }

    return <BSelectSearch key="1" field={field} useHook={useHook} formik={formik} />;
  }, [formValue, formik, useHook, id]);

  return <>{getSelect()}</>;
};
