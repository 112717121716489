import * as React from 'react';
import { useMemo } from 'react';
import { InvoiceInterface } from '../../../interfaces/invoice.interface';
import { useTranslation } from 'react-i18next';
import { CURRENCY } from '../../../interfaces/enumerables';
import { getCustomerNewSubtotal } from '../../../services/utils/pdf';
import { DOCUMENT_TYPE_AADE_CODE } from '../../../config/utils/constants';

interface Props {
  doc: InvoiceInterface;
}

export const DocViewCustomerDebt = ({ doc }: Props) => {
  const { t } = useTranslation();
  const newTotal = useMemo(() => getCustomerNewSubtotal(doc), [doc]);
  const currencySymbol = useMemo(() => CURRENCY[doc?.currency || 'EURO'], [doc?.currency]);
  const hideSection = useMemo(
    () => doc?.documentType?.aadeCode === DOCUMENT_TYPE_AADE_CODE.DELIVERY_NOTE,
    [doc?.documentType?.aadeCode],
  );

  if (hideSection || doc?.currentSubtotal === null) return null;

  return (
    <div className="flex-start-start-column-nowrap simpleBorder width110">
      <div className="flex-start-start-column-nowrap padding-2">
        <div className="boldText1">{t('SALE.CUSTOMER_DEBT')}</div>
        <div className="normalText1">
          {t('SALE.OLD_DEBT')}:{' '}
          <b>
            {doc?.currentSubtotal} {currencySymbol}
          </b>
        </div>
        <div className="normalText1">
          {t('SALE.NEW_DEBT')}:{' '}
          <b>
            {newTotal} {currencySymbol}
          </b>
        </div>{' '}
      </div>
    </div>
  );
};
