import * as React from 'react';
import { useCallback } from 'react';
import { ItemCard } from '../../../components/atoms/ItemCard';
import { PATHS } from '../../../navigation/data';
import { ItemCardField } from '../../../components/atoms/ItemCardField';
import { Customer } from '../../../interfaces/customer.interface';
import { isNegative, isZero, minusWithDecimals } from '../../../config/utils/calcUtils';
import { CURRENCY, SUBSCRIPTION } from '../../../interfaces/enumerables';
import { usePlanIsActive } from '../../../services/utils/usePlanIsActive';

interface Props {
  data: Customer;
}

export const CustomerItemCard = ({ data }: Props) => {
  const showSubtotals = usePlanIsActive(SUBSCRIPTION.SUBTOTALS);

  const getSubtotal = useCallback(() => {
    if (showSubtotals) {
      const subtotal = minusWithDecimals(data?.customerSubtotal, data?.supplierSubtotal);
      const isNegativeSubtotal = isNegative(subtotal);
      const isZeroSubtotal = isZero(subtotal);
      let cssClass = 'text-success';
      if (isNegativeSubtotal) cssClass = 'text-danger';
      if (isZeroSubtotal) cssClass = 'text-neutral';
      const textToShow = `${subtotal} ${CURRENCY[data?.currency?.name]}`;

      return <ItemCardField classes={`margin-r-24 ${cssClass}`} width="width-120px" value={textToShow} />;
    }
  }, [data?.customerSubtotal, data?.supplierSubtotal, data?.currency?.name, showSubtotals]);

  return (
    <div className="width-100 itemOfList positionRelative">
      <ItemCard routerLink={`${PATHS.CUSTOMERS}/view/${data.id}`}>
        <div className="flex-space-between-center-row-wrap width-100">
          <ItemCardField classes="margin-r-24" width="truncate-1-lines width-460px" value={data.companyName} />
          {getSubtotal()}
          <ItemCardField classes="margin-r-24" width="width-120px" value={data?.vatNumber} />
        </div>
      </ItemCard>
    </div>
  );
};
