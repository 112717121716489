import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IonFab, IonFabButton, IonIcon, IonInput, IonItem } from '@ionic/react';
import { TextFieldTypes } from '@ionic/core';
import { FieldArray, FormikProvider } from 'formik';
import { closeCircleOutline } from 'ionicons/icons';
import { disableSubmitOnEnter } from '../../../config/utils/formUtils';

interface Props {
  shippingField: any;
  formik: any;
  index: number;
}

export const CustomerShippingItem = ({ formik, shippingField: field, index }: Props) => {
  const ref = useRef<any>(null);
  const { t } = useTranslation();
  const formName = `[${field.name}][${index}]`;

  useEffect(() => {
    setTimeout(() => {
      ref?.current?.setFocus();
    });
  }, []);

  // TODO: on remove of field form is not updating correctly
  //  and the errors are still present => user cannot submit
  const getRemoveButton = () => {
    if (formik.values?.shipping?.length > 1) {
      return (
        <IonFab className="delete-customer-shipping">
          <FormikProvider value={formik}>
            <FieldArray
              name="shipping"
              render={({ remove }) => (
                <IonFabButton onClick={() => remove(index)} color="transparent">
                  <IonIcon icon={closeCircleOutline} size="medium" color="danger" />
                </IonFabButton>
              )}
            />
          </FormikProvider>
        </IonFab>
      );
    }
  };

  return (
    <div key={formName} className="flex-start-center-column-nowrap positionRelative width-300px">
      <IonItem
        id={formName}
        className="width-300px height-50px border-radius-input g-select-border cursorPointer"
        mode="md">
        <IonInput
          ref={ref}
          className="height-48px"
          mode="md"
          type={field?.type as TextFieldTypes}
          aria-label="delivery"
          onKeyPress={disableSubmitOnEnter}
          name={formName}
          placeholder={t(`FORMS.${field?.placeholder}`) + '...'}
          onIonChange={formik.handleChange}
          value={formik?.values?.[field?.name][index]}
          maxlength={field.maxLength}
        />
      </IonItem>
      {getRemoveButton()}
    </div>
  );
};
