import * as React from 'react';
import { DocType } from './DocType';
import { DocDate } from './DocDate';
import { DocSeries } from './DocSeries';
import { useTranslation } from 'react-i18next';
import { DocPurpose } from './DocPurpose';
import { DocPaymentType } from './DocPaymentType';

interface Props {
  type: any;
  purposeField: any;
  date: any;
  paymentField: any;
  seriesName: any;
  serialNumber: any;
  formik: any;
  isClone?: boolean;
}

const docsRequireTransportDate = ['1.1', '1.2', '1.12', '9.3'];

export const DocMainInfoSection = ({
  type,
  purposeField,
  date,
  paymentField,
  seriesName,
  serialNumber,
  formik,
  isClone,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="docForm">
      <div className="padding-t-24 padding-b-24 padding-l-8 padding-r-8 flex-start-center-row-wrap flex-gap-30">
        <DocType field={type} formik={formik} />
        <DocSeries seriesNameField={seriesName} serialNumberField={serialNumber} formik={formik} isClone={isClone} />
        <DocPurpose field={purposeField} formik={formik} />
        <DocPaymentType field={paymentField} formik={formik} />
        <DocDate field={date} formik={formik} title={`${t('SALE.DOC_DATE')} *`} />
      </div>

      {/*<div className="margin-t-10">*/}
      {/*TODO: Map of doc types to `Σκοπός έκδοσης` TODO: add missing doc types*/}
      {/*aadeId: 1.1, 1.2, 1.3, 1.12 -> 01 */}
      {/*aadeId: 5.1, 5.2 -> 02/05 */}
      {/*aadeId: 9.3 -> all */}
      {/*aadeId: 1.12 -> 01 */}
      {/*credit invoice return (5.1) Πιστωτικό Τιμολόγιο Επιστροφής Συσχετιζόμενο -> 02/05*/}
      {/*credit invoice return (5.2) Πιστωτικό Τιμολόγιο Επιστροφής Μη Συσχετιζόμενο-> 02/05*/}
      {/*credit invoice sale (5.2) Πιστωτικό Τιμολόγιο Έκπτωσης -> 11*/}
      {/*</div>*/}
    </div>
  );
};
