import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { DocDelivery } from './DocDelivery';
import { DocSimpleInput } from './DocSimpleInput';
import { DocDate } from './DocDate';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { DocDeliverToCity } from './DocDeliverToCity';
import { DocDeliverToPostalCode } from './DocDeliverToPostalCode';
import { TEST_IDS } from '../../../config/utils/constants';

interface Props {
  shippingField: any;
  shipFrom: any;
  shipTo: any;
  dueDate: any;
  shipDate: any;
  shipToCity: any;
  shipToPostalCode: any;
  formik: any;
}

const docsRequireTransportDate = ['1.1', '1.2', '1.12', '9.3'];

export const DocPaymentDelivery = ({
  shippingField,
  shipFrom,
  shipTo,
  shipToCity,
  shipToPostalCode,
  dueDate,
  shipDate,
  formik,
}: Props) => {
  const { t } = useTranslation();
  const minDate = useMemo(() => dayjs(formik.values?.documentDate || ''), [formik.values?.documentDate]);

  const isDateRequired = useMemo(
    () => docsRequireTransportDate.includes(formik.values?.['documentTypeId']?.object?.aadeCode),
    [formik.values?.['documentTypeId']],
  );

  const getTransportDate = useCallback(() => {
    if (isDateRequired) {
      const maxDate = minDate.add(30, 'd');
      return (
        <DocDate field={shipDate} formik={formik} title={t('SALE.DELIVERY_DATE')} minDate={minDate} maxDate={maxDate} />
      );
    }
  }, [shipDate, formik, isDateRequired, t, minDate]);

  return (
    <div className="docForm padding-t-24 padding-b-24 padding-l-8 padding-r-8 flex-start-center-row-wrap flex-gap-30">
      <DocDelivery field={shippingField} formik={formik} />
      <DocSimpleInput field={shipFrom} formik={formik} testId={TEST_IDS.Invoice.shipFromInput} />
      <DocSimpleInput field={shipTo} formik={formik} testId={TEST_IDS.Invoice.shipToAddressInput} />
      <DocDeliverToCity field={shipToCity} formik={formik} />
      <DocDeliverToPostalCode field={shipToPostalCode} formik={formik} />
      <DocDate field={dueDate} formik={formik} title={t('SALE.DUE_DATE')} minDate={minDate} />
      {getTransportDate()}
    </div>
  );
};
