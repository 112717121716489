import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { BSelectSearchInput } from '../BSelectSearchInput';
import { useIsCloneOrEdit } from '../../../services/hooks/useIsCloneOrEdit';
import { TEST_IDS } from '../../../config/utils/constants';

interface Props {
  field: any;
  formik: any;
}

export const DocDeliverToCity = ({ field, formik }: Props) => {
  const { originalCustomer } = useIsCloneOrEdit();
  const customerSelected = useMemo(() => formik?.values?.customerId, [formik?.values?.customerId]);
  const customerCity = useMemo(() => customerSelected?.city, [customerSelected?.city]);

  useEffect(() => {
    if (customerCity && customerSelected?.id !== originalCustomer && customerCity !== field.value) {
      formik.setFieldValue(field.name, customerCity);
    }
  }, [field.name, customerCity, customerSelected?.id, originalCustomer, field.value]);

  const getOptions = useCallback(() => {
    if (customerCity) {
      return [
        {
          isMine: true,
          label: customerCity,
          value: customerCity,
        },
      ];
    }

    return [];
  }, [customerCity]);

  return (
    <BSelectSearchInput
      field={field}
      formik={formik}
      options={getOptions()}
      testId={TEST_IDS.Invoice.shipToCitySelect}
    />
  );
};
