import i18next from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import * as yup from 'yup';
import { useCustomer } from '../../api/hooks/customers/useCustomer';
import { CustomerFormData, useCustomerForm } from '../../api/hooks/customers/useCustomerForm';
import { useCountries } from '../../api/hooks/useCountries';
import { useDoy } from '../../api/hooks/useDoy';
import { Loading } from '../../components/atoms/Loading';
import { CUSTOMER_TYPE_AADE_CODE, CUSTOMER_VAT_REGIME_AADE_CODE } from '../../config/utils/constants';
import { getLocalDate } from '../../config/utils/dateHelpers';
import { Country, Customer, CustomerType, Doy } from '../../interfaces/customer.interface';
import { EntityFormInterface, INPUT_TYPE } from '../../interfaces/forms.inteface';
import { CustomerVatRegime } from '../../interfaces/invoice.interface';
import { useHasActivePlan } from '../../services/utils/useHasActivePlan';
import { CustomerForm } from './components/CustomerForm';

const defaultVatRegime = (data: CustomerVatRegime[]) => {
  return data.map((entity) => {
    return {
      label: i18next.t(`VAT_REGIME.${entity?.name}`),
      value: entity?.id,
      object: entity,
    };
  });
};

const mapData = (selectedEntity: Doy | Country | CustomerType | undefined, translationKey: string, data?: any[]) => {
  if (selectedEntity) {
    return [
      {
        label: selectedEntity.name,
        value: selectedEntity?.id,
        object: selectedEntity,
      },
    ];
  }

  if (data) {
    return data.map((entity) => {
      return {
        label: entity?.name,
        value: entity?.id,
        object: entity,
      };
    });
  }

  return null;
};

const isCurrencyDisabled = (entityData: Customer | null | undefined) => {
  if (entityData?.id) {
    return !entityData?.canEditCurrency;
  }

  return false;
};

// TODO: add select for multiple bank info per customer
const getFormData = (
  id?: number | string,
  data?: CustomerFormData,
  entityData?: Customer | null | undefined,
): EntityFormInterface<Doy> => {
  const currencyDisabled = isCurrencyDisabled(entityData);

  return {
    id,
    fields: [
      {
        name: 'countryId',
        label: 'COUNTRY',
        placeholder: 'COUNTRY',
        value: mapData(entityData?.country, 'FORMS', data?.countries || [])?.[0] || null,
        fieldToSubmit: 'value',
        fieldToSubmit2: 'id',
        // @ts-expect-error happens
        useHook: useCountries,
        // labelStyle: 'b-select-label',
        // heightLarge: true,
        type: INPUT_TYPE.ANTD_SELECT_SEARCH,
        required: true,
      },
      {
        name: 'customerTypeId',
        label: 'CUSTOMER_TYPE',
        placeholder: 'CUSTOMER_TYPE',
        value: mapData(entityData?.customerType, 'FORMS', data?.customerTypes || [])?.[0] || null,
        fieldToSubmit: 'value',
        labelStyle: 'padding-b-16 padding-l-12',
        translationGroupKey: 'CUSTOMER_TYPES',
        data: data?.customerTypes,
        type: INPUT_TYPE.ANTD_SELECT,
        required: true,
      },
      {
        name: 'vatNumber',
        label: 'VAT_NUMBER',
        placeholder: 'VAT_NUMBER',
        value: entityData?.vatNumber || null,
        type: INPUT_TYPE.B_INPUT,
        inputType: 'text',
        required: true,
        maxLength: 40,
        disabled: id ? !entityData?.canDelete : false,
        defaultValue: null,
      },
      {
        name: 'companyName',
        label: 'COMPANY_NAME_CLIENT',
        placeholder: 'COMPANY_NAME_CLIENT',
        value: entityData?.companyName || '',
        required: true,
        type: INPUT_TYPE.B_INPUT,
        inputType: 'text',
        maxLength: 100,
      },
      {
        name: 'doyId',
        label: 'DOY',
        placeholder: 'DOY',
        value: mapData(entityData?.doy, 'FORMS')?.[0] || null,
        fieldToSubmit: 'value',
        fieldToSubmit2: 'id',
        useHook: useDoy,
        required: true,
        type: INPUT_TYPE.ANTD_SELECT_SEARCH,
        allowClear: true,
      },
      {
        name: 'email',
        label: 'EMAIL',
        placeholder: 'EMAIL',
        value: entityData?.email || '',
        type: INPUT_TYPE.B_INPUT,
        inputType: 'text',
        maxLength: 254,
      },
      {
        name: 'phoneNumber',
        label: 'PHONE_NUMBER',
        placeholder: 'PHONE_NUMBER',
        value: entityData?.phoneNumber || '',
        type: INPUT_TYPE.B_INPUT,
        inputType: 'text',
        maxLength: 15,
      },
      {
        name: 'profession',
        label: 'PROFESSION',
        placeholder: 'PROFESSION',
        value: entityData?.profession || '',
        type: INPUT_TYPE.B_INPUT,
        inputType: 'text',
        maxLength: 498,
      },
      {
        name: 'address',
        label: 'ADDRESS',
        placeholder: 'ADDRESS',
        value: entityData?.address || '',
        type: INPUT_TYPE.B_INPUT,
        inputType: 'text',
        maxLength: 200,
      },
      {
        name: 'city',
        label: 'CITY',
        placeholder: 'CITY',
        value: entityData?.city || '',
        type: INPUT_TYPE.B_INPUT,
        inputType: 'text',
      },
      {
        name: 'postalCode',
        label: 'POSTAL_CODE',
        placeholder: 'POSTAL_CODE',
        value: entityData?.postalCode || '',
        type: INPUT_TYPE.B_INPUT,
        inputType: 'text',
      },
      {
        name: 'currencyId',
        label: 'CURRENCY',
        placeholder: 'CURRENCY',
        value: mapData(entityData?.currency, 'FORMS', data?.currency || [])?.[0] || null,
        fieldToSubmit: 'value',
        labelStyle: 'padding-b-16 padding-l-12',
        translationGroupKey: 'CURRENCIES',
        disabled: currencyDisabled,
        tooltip: currencyDisabled ? 'CUSTOMER_CURRENCY_DISABLED' : '',
        data: data?.currency,
        type: INPUT_TYPE.ANTD_SELECT,
      },
      {
        name: 'vatRegimeId',
        label: 'VAT_REGIME',
        placeholder: 'VAT_REGIME',
        value: mapData(entityData?.vatRegime, 'FORMS', data?.customerVatRegimes)?.[0] || null,
        fieldToSubmit: 'value',
        fieldToSubmit2: 'id',
        labelStyle: 'padding-b-16 padding-l-12',
        translationGroupKey: 'VAT_REGIME',
        data: data?.customerVatRegimes,
        type: INPUT_TYPE.ANTD_SELECT,
        required: true,
      },
      {
        name: 'vatExceptionCategoryId',
        label: 'VAT_EXCEPTION_CAT',
        placeholder: 'VAT_EXCEPTION_CAT',
        value: mapData(entityData?.vatExceptionCategory, 'FORMS')?.[0] || null,
        fieldToSubmit: 'value',
        fieldToSubmit2: 'id',
        labelStyle: 'padding-b-16 padding-l-12',
        translationGroupKey: 'VAT_EXCEPTION_CAT',
        allowClear: true,
        required: true,
        data: data?.vatExceptionCategories,
        type: INPUT_TYPE.ANTD_SELECT,
      },
      {
        name: 'shipping',
        label: 'SHIPPING_FIELD_DATA',
        placeholder: 'SHIPPING_FIELD_DATA',
        value: entityData?.shipping || [''],
        type: INPUT_TYPE.B_INPUT,
        maxLength: 40,
      },
    ],
    onlyViewFields: [
      {
        name: 'createdAt',
        label: 'CREATED_AT',
        placeholder: 'CREATED_AT',
        value: entityData?.createdAt ? getLocalDate(String(entityData?.createdAt)) : '',
        type: 'text',
        disabled: true,
      },
      {
        name: 'updatedAt',
        label: 'UPDATED_AT',
        placeholder: 'UPDATED_AT',
        value: entityData?.updatedAt ? getLocalDate(String(entityData?.updatedAt)) : '',
        type: 'text',
        disabled: true,
      },
    ],
  };
};

const validationSchema = yup.object({
  countryId: yup.object().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
  customerTypeId: yup.object().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
  companyName: yup.string().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')).max(100),
  vatNumber: yup.string().when('customerTypeId', {
    is: (customerTypeId: any) => {
      return customerTypeId?.object?.aadeCode === CUSTOMER_TYPE_AADE_CODE.SAME_COUNTRY_CLIENT;
    },
    then: () =>
      yup
        .string()
        .required(i18next.t('FORMS.ERRORS.VALID_REQUIRED_VAT_NUMBER'))
        .length(9, i18next.t('FORMS.ERRORS.VALID_REQUIRED_VAT_NUMBER')),
    otherwise: () =>
      yup.string().when('customerTypeId', {
        is: (customerTypeId: any) => customerTypeId?.object?.aadeCode !== CUSTOMER_TYPE_AADE_CODE.PERSON,
        then: () => yup.string().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')).max(40),
        otherwise: () => yup.string().nullable(),
      }),
  }),
  doyId: yup.object().when('customerTypeId', {
    is: (customerTypeId: any) => {
      return customerTypeId?.object?.aadeCode === CUSTOMER_TYPE_AADE_CODE.SAME_COUNTRY_CLIENT;
    },
    then: () => yup.object().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
    otherwise: () => yup.object().nullable(),
  }),
  email: yup.string().nullable().max(254),
  phoneNumber: yup.string().nullable().max(15),
  profession: yup.string().nullable().max(498),
  address: yup.string().nullable().max(200),
  city: yup.string().when('countryId', {
    is: (countryId: any) => {
      return countryId?.object?.code !== 'GR' && countryId?.code !== 'GR';
    },
    then: () => yup.string().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')).max(100),
    otherwise: () => yup.string().nullable().max(100),
  }),
  postalCode: yup.string().when('countryId', {
    is: (countryId: any) => {
      return countryId?.object?.code !== 'GR' && countryId?.code !== 'GR';
    },
    then: () => yup.string().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')).max(40),
    otherwise: () => yup.string().nullable().max(40),
  }),
  currencyId: yup.object().nullable(),
  vatRegimeId: yup.object().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
  vatExceptionCategoryId: yup
    .object()
    .when('vatRegimeId', {
      is: (vatRegimeId: any) => vatRegimeId?.object?.aadeCode === CUSTOMER_VAT_REGIME_AADE_CODE.ZERO_VAT,
      then: () => yup.object().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
      otherwise: () => yup.object().notRequired(),
    })
    .nullable(),
  shipping: yup
    .array()
    .of(yup.string().max(100))
    .test('at-least-one', 'At least one shipping address is required', function (value) {
      // TODO: fix form edge case
      // console.log('value: ', value)
      // If there's only one address, it can be empty
      if (value?.length === 1) {
        return true;
      }
      // If there are multiple addresses, all must have a value
      return value?.every((address) => !!address);
    }),
});

interface FormState {
  formData: any;
  entityData: Customer | null;
}

export const NewCustomerScreen = () => {
  useHasActivePlan(true);
  const { id } = useParams<{ id: string }>();
  const isCreate = useMemo(() => !id, [id]);
  const { data: entityData, isLoading: isLoadingEntity, isFetched } = useCustomer(id);
  const { data: formData, isLoading } = useCustomerForm();
  const [data, setData] = useState<FormState | null>(null);
  const [isReady, setIsReady] = useState(false);
  const entityDataReady = useMemo(() => isCreate || entityData, [isCreate, entityData]);

  useEffect(() => {
    if (formData && entityDataReady) {
      const helpData: FormState = { formData, entityData: null };
      if (id && entityData) {
        helpData.entityData = entityData;
      }

      setData({ ...helpData });
      setIsReady(true);
    }
  }, [formData, entityData]);

  const getLoading = useCallback(() => {
    if (isLoading || isLoadingEntity) {
      return <Loading backgroundFull />;
    }
  }, [isLoading, isLoadingEntity]);

  const getMainSection = useCallback(() => {
    if (isReady) {
      const dataForm = getFormData(id, data?.formData, data?.entityData);
      return <CustomerForm data={dataForm} validationSchema={validationSchema} />;
    }
  }, [isReady, id, data?.formData, data?.entityData]);

  return (
    <div>
      {getLoading()}
      {getMainSection()}
    </div>
  );
};
