import { CustomerVatRegime } from '../../../interfaces/invoice.interface';
import { apiRQ } from '../../api';
import { useQuery } from 'react-query';
import { Country, Currency, CustomerType, VatExceptionCategory } from '../../../interfaces/customer.interface';

export interface CustomerFormData {
  customerTypes: CustomerType[];
  currency: Currency[];
  customerVatRegimes: CustomerVatRegime[];
  vatExceptionCategories: VatExceptionCategory[];
  countries: Country[];
}

export const getCustomerFormData = async (): Promise<CustomerFormData> => {
  const resp = await apiRQ.get<CustomerFormData>('/form-data/customer');

  return resp.data;
};

export const useCustomerForm = () => {
  return useQuery<CustomerFormData, Error>(['useCustomerForm'], () => getCustomerFormData());
};
