import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { IonInput, IonItem } from '@ionic/react';
import { TextFieldTypes } from '@ionic/core';
import { disableSubmitOnEnter } from '../../../config/utils/formUtils';
import { showError } from '../../../config/utils/formDiffUtils';

interface Props {
  field: any;
  formik: any;
}

export const MoneyCollectionSimpleInput = ({ field, formik }: Props) => {
  const { t } = useTranslation();
  const hasErrorInValue = useMemo(() => showError(formik, formik?.errors?.[field.name]), [formik?.errors, field.name]);

  const getTitle = useCallback(() => {
    const translation = t(`FORMS.${field.label}`);
    if (field.required) return translation + ' *';

    return translation;
  }, [field.label, field.required]);

  return (
    <div>
      <div className="padding-b-16 padding-l-12">{getTitle()}</div>
      <div key={field?.name} className="flex-start-center-column-nowrap positionRelative width-300px">
        <IonItem
          id={field.name}
          className={`width-300px height-50px border-radius-input g-select-border cursorPointer ${
            hasErrorInValue && 'b-field-border-error'
          }`}
          mode="md">
          <IonInput
            className="height-48px"
            mode="md"
            type={field.type as TextFieldTypes}
            aria-label="delivery"
            name={field.name}
            placeholder={t(`FORMS.${field.placeholder}`) + '...'}
            onIonChange={formik.handleChange}
            value={formik.values?.[field.name]}
            maxlength={field?.maxLength}
            onKeyPress={disableSubmitOnEnter}
          />
        </IonItem>
      </div>
    </div>
  );
};
