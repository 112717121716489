import * as React from 'react';
import { useCallback } from 'react';
import { Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { pdfStyles } from './pdfStyles';
import { PdfCompanyTitle } from './PdfCompanyTitle';
import { PdfDocTitle } from './PdfDocTitle';
import { PdfCustomerData } from './PdfCustomerData';
import { PdfExtraInfo } from './PdfExtraInfo';
import { PdfProductsSection } from './products/PdfProductsSection';
import { PdfTotalCost } from './products/PdfTotalCost';
import { PdfNotes } from './PdfNotes';
import { PdfAadeData } from './PdfAadeData';
import { Company } from '../../../interfaces/company.interface';
import { InvoiceInterface } from '../../../interfaces/invoice.interface';
import { ProductInOrder } from '../../../interfaces/product.interface';
import { PdfTotalQuantities } from './products/PdfTotalQuantities';
import { PdfCompanyLogo } from './PdfCompanyLogo';
import { PdfBankDetails } from './PdfBankDetails';
import { PdfCopyright } from './PdfCopyright';
import { PdfCustomerDebt } from './PdfCustomerDebt';
import { BILL_EASE_VAT_NUMBER } from '../../../interfaces/enumerables';

interface Props {
  company: Company;
  doc: InvoiceInterface;
  products: ProductInOrder[];
  index: number;
  numberOfPages: number;
  logo: string | null | undefined;
}

export const PdfPage = ({ company, doc, products, index, numberOfPages, logo }: Props) => {
  const getBankDetails = useCallback(() => {
    if (company.vatNumber === BILL_EASE_VAT_NUMBER && doc.notes?.includes('RF ')) {
      return null;
    }

    return <PdfBankDetails bankDetails={company?.bankDetails || []} />
  }, [doc.customer?.rfPaymentCode, company?.bankDetails]);

  const getBotSection = useCallback(() => {
    if (index + 1 === numberOfPages) {
      return (
        <View>
          <View style={styles.containerCost}>
            <View>
              <View style={{ marginBottom: 6 }}>
                <PdfTotalQuantities doc={doc} />
              </View>
              <PdfCustomerDebt doc={doc} />
            </View>
            <View>
              {getBankDetails()}
            </View>
            <PdfTotalCost doc={doc} />
          </View>
          <View style={{ marginTop: 2, borderWidth: 1 }}>
            <PdfNotes doc={doc} />
            <PdfCopyright doc={doc} />
          </View>
        </View>
      );
    }
  }, [doc, numberOfPages, index, getBankDetails]);

  return (
    <Page size="A4" style={styles.page}>
      {/*{getLogo()}*/}
      <View style={styles.index}>
        <Text style={pdfStyles.smallTitleRegular}>
          {index + 1}/{numberOfPages}
        </Text>
      </View>
      <View style={styles.section}>
        <View>
          <View style={pdfStyles.flexStartSpaceRow}>
            <PdfCompanyTitle company={company} />
            <PdfCompanyLogo company={company} logo={logo} />
          </View>
          <View style={{ paddingTop: 10 }}>
            <PdfDocTitle doc={doc} />
          </View>
          <View style={[{ paddingTop: 4 }, pdfStyles.flexStartStartRow]}>
            <PdfCustomerData doc={doc} />
            <PdfExtraInfo doc={doc} />
          </View>
          {/* TODO: Enable when feature is ready */}
          {/*<PdfRelatedDocs doc={doc} />*/}
          <View style={{ paddingTop: 4 }}>
            <PdfProductsSection
              doc={doc}
              products={products}
              docHasVatExceptionCategory={!!doc.vatExceptionCategoryId}
            />
          </View>
        </View>
        {getBotSection()}
      </View>
      <View style={styles.footer}>
        <PdfAadeData doc={doc} />
      </View>
    </Page>
  );
};

const styles = StyleSheet.create({
  index: {
    position: 'absolute',
    top: 2,
    left: 6,
  },
  page: {
    fontFamily: 'Manrope',
    fontSize: 12,
    flexDirection: 'column',
    backgroundColor: 'white',
  },
  section: {
    margin: 10,
    padding: 10,
    marginBottom: 60,
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  containerCost: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  footer: {
    position: 'absolute',
    bottom: 2,
    left: 10,
    width: '100%',
  },
  logoContainer: {
    position: 'absolute',
    top: 0,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  logo: {
    width: 60,
    height: 30,
  },
});
