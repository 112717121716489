import {
  SORT_BY_CUSTOMERS,
  SORT_BY_DELIVERY_NOTES,
  SORT_BY_MONEY_COLLECTION,
  SORT_BY_PRODUCTS,
  SORT_BY_SALES,
} from '../../interfaces/enumerables';
import i18next from 'i18next';
import { SortBy } from '../../interfaces/forms.inteface';

export const getProductsSortByFilter: () => { [key in SORT_BY_PRODUCTS]: SortBy } = () => {
  return {
    [SORT_BY_PRODUCTS.NAME]: { value: SORT_BY_PRODUCTS.NAME, label: i18next.t('FILTERS.NAME') },
    [SORT_BY_PRODUCTS.INTERNAL_ID]: { value: SORT_BY_PRODUCTS.INTERNAL_ID, label: i18next.t('FILTERS.INTERNAL_ID') },
    [SORT_BY_PRODUCTS.INTERNAL_SERIAL]: {
      value: SORT_BY_PRODUCTS.INTERNAL_SERIAL,
      label: i18next.t('FILTERS.INTERNAL_SERIAL'),
    },
    [SORT_BY_PRODUCTS.CREATED_AT]: { value: SORT_BY_PRODUCTS.CREATED_AT, label: i18next.t('FILTERS.CREATED_AT') },
  };
};

export const getCustomersSortByFilter: (showSubtotals?: boolean) => { [key in SORT_BY_CUSTOMERS]: SortBy } = (
  showSubtotals?: boolean,
) => {
  // @ts-expect-error to be solved
  const data: { [key in SORT_BY_CUSTOMERS]: SortBy } = {
    [SORT_BY_CUSTOMERS.COMPANY_NAME]: {
      value: SORT_BY_CUSTOMERS.COMPANY_NAME,
      label: i18next.t('FILTERS.COMPANY_NAME'),
    },
    [SORT_BY_CUSTOMERS.VAT_NUMBER]: { value: SORT_BY_CUSTOMERS.VAT_NUMBER, label: i18next.t('FILTERS.VAT_NUMBER') },
    [SORT_BY_CUSTOMERS.CREATED_AT]: { value: SORT_BY_CUSTOMERS.CREATED_AT, label: i18next.t('FILTERS.CREATED_AT') },
  };

  if (showSubtotals) {
    data[SORT_BY_CUSTOMERS.CUSTOMER_SUBTOTAL] = {
      value: SORT_BY_CUSTOMERS.CUSTOMER_SUBTOTAL,
      label: i18next.t('FILTERS.CUSTOMER_SUBTOTAL'),
    };
    data[SORT_BY_CUSTOMERS.SUPPLIER_SUBTOTAL] = {
      value: SORT_BY_CUSTOMERS.SUPPLIER_SUBTOTAL,
      label: i18next.t('FILTERS.SUPPLIER_SUBTOTAL'),
    };
  }

  return data;
};

export const getSalesSortByFilter: () => { [key in SORT_BY_SALES]: SortBy } = () => {
  return {
    [SORT_BY_SALES.SERIES_NAME]: { value: SORT_BY_SALES.SERIES_NAME, label: i18next.t('FILTERS.SERIES_NAME') },
    [SORT_BY_SALES.SERIAL_NUMBER]: { value: SORT_BY_SALES.SERIAL_NUMBER, label: i18next.t('FILTERS.SERIAL_NUMBER') },
    [SORT_BY_SALES.YEAR]: { value: SORT_BY_SALES.YEAR, label: i18next.t('FILTERS.YEAR') },
    [SORT_BY_SALES.SENT_TO_AADE]: { value: SORT_BY_SALES.SENT_TO_AADE, label: i18next.t('FILTERS.SENT_TO_AADE') },
    [SORT_BY_SALES.CUSTOMER]: { value: SORT_BY_SALES.CUSTOMER, label: i18next.t('FILTERS.CUSTOMER') },
    [SORT_BY_SALES.PAYMENT_TYPE]: { value: SORT_BY_SALES.PAYMENT_TYPE, label: i18next.t('FILTERS.PAYMENT_TYPE') },
    [SORT_BY_SALES.DOCUMENT_TYPE]: { value: SORT_BY_SALES.DOCUMENT_TYPE, label: i18next.t('FILTERS.DOCUMENT_TYPE') },
    [SORT_BY_SALES.TOTAL_VALUE]: { value: SORT_BY_SALES.TOTAL_VALUE, label: i18next.t('FILTERS.TOTAL_VALUE') },
    [SORT_BY_SALES.CREATED_AT]: { value: SORT_BY_SALES.CREATED_AT, label: i18next.t('FILTERS.CREATED_AT') },
  };
};

export const getDeliveryNotesSortByFilter: () => { [key in SORT_BY_DELIVERY_NOTES]: SortBy } = () => {
  return {
    [SORT_BY_DELIVERY_NOTES.SERIES_NAME]: {
      value: SORT_BY_DELIVERY_NOTES.SERIES_NAME,
      label: i18next.t('FILTERS.SERIES_NAME'),
    },
    [SORT_BY_DELIVERY_NOTES.SERIAL_NUMBER]: {
      value: SORT_BY_DELIVERY_NOTES.SERIAL_NUMBER,
      label: i18next.t('FILTERS.SERIAL_NUMBER'),
    },
    [SORT_BY_DELIVERY_NOTES.YEAR]: { value: SORT_BY_DELIVERY_NOTES.YEAR, label: i18next.t('FILTERS.YEAR') },
    [SORT_BY_DELIVERY_NOTES.SENT_TO_AADE]: {
      value: SORT_BY_DELIVERY_NOTES.SENT_TO_AADE,
      label: i18next.t('FILTERS.SENT_TO_AADE'),
    },
    [SORT_BY_DELIVERY_NOTES.CUSTOMER]: { value: SORT_BY_DELIVERY_NOTES.CUSTOMER, label: i18next.t('FILTERS.CUSTOMER') },
    [SORT_BY_DELIVERY_NOTES.CREATED_AT]: {
      value: SORT_BY_DELIVERY_NOTES.CREATED_AT,
      label: i18next.t('FILTERS.CREATED_AT'),
    },
  };
};

export const getMoneyCollectionByFilter: () => { [key in SORT_BY_MONEY_COLLECTION]: SortBy } = () => {
  return {
    [SORT_BY_MONEY_COLLECTION.CREATED_AT]: {
      value: SORT_BY_MONEY_COLLECTION.CREATED_AT,
      label: i18next.t('FILTERS.CREATED_AT'),
    },
  };
};
