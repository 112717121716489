import * as React from 'react';
import { BSelect } from '../BSelect';

interface Props {
  field: any;
  formik: any;
}

export const DocPurpose = ({ field, formik }: Props) => {
  return <BSelect formik={formik} field={field} />;
};
