import * as React from 'react';
import { useCallback } from 'react';
import { Image, View } from '@react-pdf/renderer';
import { Company } from '../../../interfaces/company.interface';

interface Props {
  company: Company; // TODO: probably remove, its not used
  logo: string | null | undefined;
}

export const PdfCompanyLogo = ({ logo }: Props) => {
  const getImage = useCallback(() => {
    if (logo) {
      return <Image src={'data:image/png;base64,' + logo} style={{ maxWidth: 200, maxHeight: 66 }} />;
    }
  }, [logo]);

  return <View style={{ borderWidth: 0 }}>{getImage()}</View>;
};
