import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { MoneyCollectionSimpleInput } from './MoneyCollectionSimpleInput';
import { MONEY_COLLECTION_PAYMENT_TYPE } from '../../../api/hooks/useFormMoneyCollection';
import { DocDate } from '../../../components/molecules/docForm/DocDate';
import { useTranslation } from 'react-i18next';
import { MoneyCollectionPaymentType } from './MoneyCollectionPaymentType';
import { nowDate } from '../../../config/utils/dateHelpers';
import { ViewOnlyField } from '../../../components/molecules/ViewOnlyField';
import { CURRENCY } from '../../../interfaces/enumerables';

interface Props {
  formik: any;
  paymentTypeField: any;
  paycheckBank: any;
  paycheckNumber: any;
  paycheckDate: any;
}

export const MoneyCollectionPaymentForm = ({
  formik,
  paymentTypeField,
  paycheckBank,
  paycheckNumber,
  paycheckDate,
}: Props) => {
  const { t } = useTranslation();
  const customerSelected = useMemo(() => formik.values?.customerId, [formik.values?.customerId]);
  const isClearingPaymentType = useMemo(() => {
    const currentPaymentType = formik.values?.paymentType?.value || formik.values?.paymentType;
    return MONEY_COLLECTION_PAYMENT_TYPE.CLEARING === currentPaymentType;
  }, [formik.values?.paymentType]);
  const currency = useMemo(() => customerSelected?.currency?.name || 'EURO', [customerSelected?.currency?.name]);
  const paymentTypeValue = useMemo(
    () => formik.values?.[paymentTypeField.name]?.value || formik.values?.[paymentTypeField.name],
    [formik.values?.[paymentTypeField.name]],
  );

  useEffect(() => {
    if (
      paymentTypeValue !== MONEY_COLLECTION_PAYMENT_TYPE.MONEY_CHECK &&
      paymentTypeValue !== MONEY_COLLECTION_PAYMENT_TYPE.RETURN_MONEY_CHECK
    ) {
      formik.setFieldValue(paycheckBank.name, null);
      formik.setFieldValue(paycheckNumber.name, null);
      formik.setFieldValue(paycheckDate.name, null);
    } else {
      if (formik.values[paycheckDate.name] === null) {
        formik.setFieldValue(paycheckDate.name, nowDate());
      }
    }
  }, [paymentTypeValue]);

  const getCheckDate = useCallback(() => {
    if (formik.values?.[paycheckDate.name]) {
      return <DocDate field={paycheckDate} formik={formik} title={`${t(`FORMS.${paycheckDate.label}`)} *`} />;
    }
  }, [paycheckDate, formik, formik.values?.[paycheckDate.name]]);

  const getExtraFields = useCallback(() => {
    if (
      paymentTypeValue === MONEY_COLLECTION_PAYMENT_TYPE.MONEY_CHECK ||
      paymentTypeValue === MONEY_COLLECTION_PAYMENT_TYPE.RETURN_MONEY_CHECK
    ) {
      return (
        <>
          <MoneyCollectionSimpleInput field={paycheckBank} formik={formik} />
          <MoneyCollectionSimpleInput field={paycheckNumber} formik={formik} />
          {getCheckDate()}
        </>
      );
    }
  }, [formik, paycheckBank, paycheckNumber, getCheckDate, paymentTypeValue]);

  const getTraderSubtotals = useCallback(() => {
    if (customerSelected && isClearingPaymentType) {
      return (
        <>
          <ViewOnlyField
            field={{
              name: 'customerSubtotal',
              label: `${t('FORMS.CUSTOMER_SUBTOTAL')} (${CURRENCY[currency]})`,
              value: customerSelected?.customerSubtotal,
              tooltip: 'CUSTOMER_SUBTOTAL_INFO',
            }}
          />
          <ViewOnlyField
            field={{
              name: 'supplierSubtotal',
              label: `${t('FORMS.SUPPLIER_SUBTOTAL')} (${CURRENCY[currency]})`,
              value: customerSelected?.supplierSubtotal,
              tooltip: 'SUPPLIER_SUBTOTAL_INFO',
            }}
          />
        </>
      );
    }
  }, [customerSelected, isClearingPaymentType, currency]);

  return (
    <div style={{ minHeight: 160 }} className="docForm">
      <div className="padding-t-24 padding-b-24 padding-l-8 padding-r-8 flex-start-center-column-nowrap flex-gap-30">
        <div className="flex-start-center-row-wrap flex-gap-30">
          <MoneyCollectionPaymentType field={paymentTypeField} formik={formik} />
          {getExtraFields()}
        </div>
        <div className="flex-start-center-row-wrap flex-gap-30">{getTraderSubtotals()}</div>
      </div>
    </div>
  );
};
