import { IonButton } from '@ionic/react';
import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UseMutationResult } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AnyObject } from 'yup';
import { getChangedFields } from '../../config/utils/formDiffUtils';
import { formDataToSubmit, getFormInput, getOnlyViewField } from '../../config/utils/formUtils';
import { EntityFormInterface, INPUT_TYPE } from '../../interfaces/forms.inteface';
import { GenericResponse } from '../../interfaces/shared.interface';
import { PATHS } from '../../navigation/data';
import { isObjectEmpty } from '../../services/utils/helpers';
import { setToast } from '../../store/core/coreSlice';
import { DeleteIconButton } from '../atoms/DeleteIconButton';
import { ErrorForm } from '../atoms/ErrorForm';
import { Loading } from '../atoms/Loading';

interface Props {
  data: EntityFormInterface<any>;
  validationSchema: AnyObject;
  submitHook: () => UseMutationResult<GenericResponse, Error, void, unknown>;
  deleteHook?: () => UseMutationResult<GenericResponse, Error, void, unknown>;
  deleteDataFields?: 'id'[];
  showSubmitButton?: boolean;
  showDeleteButton?: boolean;
  isClone?: boolean;
}

export const EntityForm = ({
  data,
  validationSchema,
  submitHook,
  deleteHook,
  deleteDataFields,
  showSubmitButton = true,
  showDeleteButton = true,
  isClone = false,
}: Props) => {
  const { t } = useTranslation();
  const initialFormData = data.fields.reduce((acc: Record<string, any>, field: any) => {
    acc[field.name] = field.value;
    return acc;
  }, {});
  const history = useHistory();
  const dispatch = useDispatch();
  const { mutate: updateCreateModel, isLoading: isLoadingMutate, isSuccess, data: resp } = submitHook();

  useEffect(() => {
    if (isSuccess) {
      if (resp?.success) {
        history.goBack();
      }

      dispatch(
        setToast({
          value: {
            show: true,
            msg: resp?.error || resp?.errorCode || t('SHARED.UPDATE_SUCCESSFUL'),
            isError: !resp?.success || false,
          },
        }),
      );
    }
  }, [dispatch, history, isSuccess, resp?.error, resp?.errorCode, resp?.success, t]);

  const formik = useFormik({
    initialValues: initialFormData,
    validationSchema,
    onSubmit: (values) => {
      let dataToSubmit: Record<string, any> = { ...values };
      // console.log('=== INIT DATA: ', dataToSubmit);

      // Update model
      if (data.id !== PATHS.CREATE_SUB_PATH && !isClone) {
        const changedFields = getChangedFields(data.fields, initialFormData, dataToSubmit);
        // console.log('=== changedFields: ', changedFields);

        // Nothing changed, triggered info message
        if (isObjectEmpty(changedFields)) {
          dispatch(setToast({ value: { show: true, msg: t('SHARED.NO_CHANGES'), isError: false } }));
          return;
        }

        dataToSubmit = {
          id: data.id,
          ...changedFields,
        };
      }

      // console.log('=== dataToSubmit: ', dataToSubmit);
      const finalData = formDataToSubmit(data.fields, dataToSubmit);

      // console.log('=== finalData: ', finalData);
      // return;
      // @ts-expect-error happens
      updateCreateModel({ ...finalData, isClone });
    },
  });

  const isSubmitDisabled = useMemo(
    () => !formik.isValid || (!formik.dirty && !isClone),
    [formik.dirty, formik.isValid, isClone],
  );

  useEffect(() => {
    if (formik.values && data.actionsOnFormValueChanges) {
      data.actionsOnFormValueChanges(formik);
    }
  }, [formik.values]);

  const onKeyUp = (event: any) => {
    // Submit form on enter
    if (event.nativeEvent.key === 'Enter' && event.nativeEvent?.target?.['s-hn'] !== 'ION-TEXTAREA') {
      // formik.handleSubmit();
    }
  };

  const getFields = () => {
    return data.fields.map((field: any) => {
      const styleName = field.type === INPUT_TYPE.ANTD_SELECT ? '' : '';

      return (
        <div key={field.name} className="flex-start-center-column-nowrap positionRelative">
          {getFormInput(field, formik)}
          <ErrorForm styleName={styleName}>{formik.errors?.[field.name] && formik.errors?.[field.name]}</ErrorForm>
        </div>
      );
    });
  };

  const getOnlyViewFields = () => {
    return data?.onlyViewFields?.map((field: any) => {
      if (field?.value) {
        return (
          <div key={field.name} className="flex-start-center-column-nowrap positionRelative">
            {getOnlyViewField(field)}
          </div>
        );
      }
    });
  };

  const getDeleteButton = () => {
    if (!data?.canDelete || data?.id === 'create') return null;

    // if (deleteHook && deleteDataFields) {
    //   const deleteData: { id: number; userId?: number } = { id: Number(data[deleteDataFields[0]]) };
    //   if (deleteDataFields[1]) {
    //     deleteData.userId = Number(data[deleteDataFields[1]]);
    //   }
    //
    //   return <DeleteIconButton submitData={deleteData} deleteHook={deleteHook} />;
    // }

    if (showDeleteButton && deleteHook && data.id) {
      const deleteData: { id: string | number } = { id: data.id };

      return <DeleteIconButton submitData={deleteData} deleteHook={deleteHook} />;
    }

    return null;
  };

  const getIdToShow = useCallback(() => {
    if (data.idToShow) {
      return <div className="entityFormId text-shade normalText4">({data.idToShow})</div>;
    }
  }, [data.idToShow]);

  const getSubmitButton = useCallback(() => {
    if (showSubmitButton) {
      return (
        <div className="flex-center-center-row-wrap margin-t-16">
          <IonButton type="submit" disabled={isSubmitDisabled}>
            {t('FORMS.SAVE')}
          </IonButton>
        </div>
      );
    }
  }, [showSubmitButton, isSubmitDisabled, t]);

  return (
    <div>
      {getIdToShow()}
      {getDeleteButton()}
      <form onSubmit={formik.handleSubmit} onKeyUp={($event) => onKeyUp($event)}>
        <div className="padding-20">
          <div className="flex-center-end-row-wrap margin-b-24 padding-t-20 flex-gap-16-36">
            {getFields()}
            {getOnlyViewFields()}
          </div>
          {getSubmitButton()}
        </div>
      </form>
      {isLoadingMutate && <Loading />}
    </div>
  );
};
