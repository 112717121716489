import * as React from 'react';
import { MoneyCollection } from '../../../../interfaces/moneyCollection.interface';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { pdfStyles } from '../pdfStyles';
import { TRADER_TYPE } from '../../../../interfaces/enumerables';
import { getLocalDate } from '../../../../config/utils/dateHelpers';

interface Props {
  doc: MoneyCollection;
}

export const PdfMoneyCollectionTradeInfo = ({ doc }: Props) => {
  const { t } = useTranslation();

  return (
    <View style={styles.row}>
      <View style={styles.column}>
        <Text style={pdfStyles.smallTitle}>{t('PDF.OTHER_END')}</Text>
        <Text style={[pdfStyles.body1]}>{t(`PDF.${TRADER_TYPE?.[doc?.traderType]?.name}`)}</Text>
      </View>
      <View style={styles.columnCenter}>
        <Text style={pdfStyles.smallTitle}>{t('PDF.TRANSACTION')}</Text>
        <Text style={[pdfStyles.body1]}>{t(`MONEY_COLLECTION_PAYMENT_TYPES.${doc.paymentType}`)}</Text>
      </View>
      <View style={styles.columnEnd}>
        <Text style={pdfStyles.smallTitle}>{t('PDF.TRANSACTION_DATE')}</Text>
        <Text style={[pdfStyles.body1]}>{getLocalDate(doc.date)}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 10,
  },
  column: {
    flexDirection: 'column',
    width: '33%',
    alignItems: 'flex-start',
  },
  columnCenter: {
    flexDirection: 'column',
    width: '33%',
    alignItems: 'center',
  },
  columnEnd: {
    flexDirection: 'column',
    width: '33%',
    alignItems: 'flex-end',
  },
});
