import * as React from 'react';
import { CustomerSimpleInput } from './CustomerSimpleInput';
import { CustomerDoySelect } from './CustomerDoySelect';

interface Props {
  companyNameField: any;
  doyField: any;
  emailField: any;
  phoneNumberField: any;
  professionField: any;
  addressField: any;
  cityField: any;
  postalCodeField: any;
  formik: any;
}

export const CustomerSecondaryInfo = ({
  doyField,
  companyNameField,
  emailField,
  phoneNumberField,
  professionField,
  addressField,
  cityField,
  postalCodeField,
  formik,
}: Props) => {
  return (
    <div className="docForm">
      <div className="padding-t-24 padding-b-24 padding-l-8 padding-r-8 flex-start-center-row-wrap flex-gap-30">
        <CustomerDoySelect field={doyField} useHook={doyField.useHook} formik={formik} />
        <CustomerSimpleInput field={companyNameField} formik={formik} />
        <CustomerSimpleInput field={cityField} formik={formik} />
        <CustomerSimpleInput field={postalCodeField} formik={formik} />
        <CustomerSimpleInput field={addressField} formik={formik} />
        <CustomerSimpleInput field={professionField} formik={formik} />
        <CustomerSimpleInput field={emailField} formik={formik} />
        <CustomerSimpleInput field={phoneNumberField} formik={formik} />
      </div>
    </div>
  );
};
