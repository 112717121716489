import * as React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { pdfStyles } from '../pdfStyles';
import { MoneyCollection } from '../../../../interfaces/moneyCollection.interface';

interface Props {
  doc: MoneyCollection;
}

export const PdfMoneyCollectionBotSection = ({ doc }: Props) => {
  const { t } = useTranslation();

  return (
    <View>
      <View style={styles.section}>
        <View style={styles.container}>
          <Text style={pdfStyles.regularTitle}>{t('PDF.NOTES')}</Text>
        </View>
        <View style={styles.notes}>
          <Text style={pdfStyles.body1}>{doc?.notes}</Text>
        </View>
      </View>
      <View style={[pdfStyles.row, { paddingRight: 60, paddingLeft: 40, paddingTop: 10 }]}>
        <View style={{ paddingRight: 100 }}>
          <Text style={[pdfStyles.smallTitle, styles.placeholder]}>{t('PDF.PUBLISHER')}</Text>
        </View>
        <View>
          <Text style={[pdfStyles.smallTitle, styles.placeholder]}>{t('PDF.RECEIVER')}</Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  section: {
    flexDirection: 'column',
    height: 90,
    borderWidth: 1,
  },
  container: {
    paddingLeft: 4,
    backgroundColor: '#9d9fa6',
    justifyContent: 'center',
  },
  notes: {
    paddingLeft: 4,
    paddingTop: 4,
  },
  placeholder: {
    width: 60,
  },
});
