import * as React from 'react';
import { Company } from '../../../interfaces/company.interface';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from '@react-pdf/renderer';

interface Props {
  company: Company;
}

export const DocViewCompanyTitle = ({ company }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="flex-start-start-column-nowrap">
      <div className="boldText3">{company?.title}</div>
      <div className="truncate-2lines normalText2">{company?.profession}</div>
      <div className="flex-start-start-row-wrap">
        <div className="normalText2">
          {t('PDF.VAT_NUMBER')}: {company?.vatNumber}
          {' - '}
        </div>
        <div className="normalText2">
          {t('PDF.DOY')}: {company?.doy?.name}
        </div>
      </div>
      <div className="flex-start-start-row-wrap normalText2">
        {`${t('PDF.ADDRESS')}: ${company?.address} ${company?.city} ${company?.postalCode}`}
      </div>
      <div className="flex-start-start-row-wrap">
        {company?.phoneNumber && (
          <div className="normalText2">
            {t('PDF.PHONE_NUMBER')}: {company?.phoneNumber}
          </div>
        )}
        {company?.email && (
          <div className="normalText2">
            {' - '} {t('PDF.EMAIL')}: {company?.email}
          </div>
        )}
        {company?.websiteUrl && (
          <div className="normalText2">
            {' - '} {t('SETTINGS.WEBSITE')}: {company?.websiteUrl}
          </div>
        )}
      </div>
      <div className="flex-start-start-row-wrap normalText2">
        {company?.gemiInfo && `${t('PDF.GEMI_NUMBER')}: ${company?.gemiInfo}`}
        {company?.menoNumber && ` - ${t('PDF.MENO_NUMBER')}: ${company?.menoNumber}`}
        {company?.eaeNumber && ` - ${t('PDF.EAE_NUMBER')}: ${company?.eaeNumber}`}
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  container2: {
    flexDirection: 'row',
  },
  body: {
    fontSize: 10,
  },
});
