import * as React from 'react';
import { useTranslation } from 'react-i18next';

export const CustomersReportTableHeaderView = () => {
  const { t } = useTranslation();

  return (
    <div className="width-100 tableContainer">
      <div className="normalText1 tableTitle centerTitle padding-l-4 flex1 height-22px">
        {t('CUSTOMERS_REPORT.CUSTOMER_NAME')}
      </div>
      <div className="normalText1 tableTitle center width110 text-center height-22px">
        {t('CUSTOMERS_REPORT.CUSTOMER_SUBTOTAL')}
      </div>
      <div className="normalText1 center width110 text-center">{t('CUSTOMERS_REPORT.TRADER_SUBTOTAL')}</div>
    </div>
  );
};
