import * as React from 'react';
import { MoneyCollection } from '../../../../interfaces/moneyCollection.interface';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { pdfStyles } from '../pdfStyles';

interface Props {
  doc: MoneyCollection;
}

export const PdfMoneyCollectionTrader = ({ doc }: Props) => {
  const { t } = useTranslation();

  return (
    <View style={styles.row}>
      <View style={styles.column}>
        <Text style={pdfStyles.smallTitle}>
          {t('PDF.TRADER')}
        </Text>
        <View style={{ flexDirection: 'row' }}>
          <Text style={pdfStyles.body}>{doc?.customer?.companyName}</Text>
        </View>
      </View>
      <View style={styles.columnEnd}>
        <Text style={pdfStyles.smallTitle}>
          {t('PDF.VAT_NUMBER')}
        </Text>
        <View style={{ flexDirection: 'row' }}>
          <Text style={pdfStyles.body}>{doc?.customer?.vatNumber}</Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 10,
  },
  column: {
    flexDirection: 'column',
    width: '50%',
    alignItems: 'flex-start',
  },
  columnCenter: {
    flexDirection: 'column',
    width: '33%',
    alignItems: 'center',
  },
  columnEnd: {
    flexDirection: 'column',
    width: '50%',
    alignItems: 'flex-end',
  },
});
