import * as React from 'react';
import { BSelect } from '../BSelect';
import { TEST_IDS } from '../../../config/utils/constants';

interface Props {
  formik: any;
  taxWithholdField: any;
}

export const DocTaxWithhold = ({ formik, taxWithholdField }: Props) => {
  return (
    <div className="flex-center-start-column-nowrap padding-t-20 padding-b-20">
      <BSelect
        testId={TEST_IDS.Invoice.taxWithholdSelect}
        field={taxWithholdField}
        formik={formik}
        widthClass="width-320px"
      />
    </div>
  );
};
