import * as React from 'react';
import { useCallback } from 'react';
import { useCompanyLogo } from '../../../../api/hooks/useCompanyLogo';

interface Props {
  logoUrl: string | null;
}

export const CompanyLogo = ({ logoUrl }: Props) => {
  const { data: logoData } = useCompanyLogo(logoUrl || '');

  const getLogo = useCallback(() => {
    if (logoData) {
      return <img src={'data:image/png;base64,' + logoData} alt="logo" style={{ maxWidth: 200, maxHeight: 66 }} />;
    }
  }, [logoData]);

  return <div>{getLogo()}</div>;
};
