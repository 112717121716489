import * as React from 'react';
import { CustomersReport } from './components/customers/CustomersReport';
import { useHasActivePlan } from '../../services/utils/useHasActivePlan';
import { SUBSCRIPTION } from '../../interfaces/enumerables';

export const ReportsScreen = () => {
  useHasActivePlan(true, SUBSCRIPTION.SUBTOTALS);

  return (
    <>
      <div className="width-100 padding-t-16 padding-b-16 flex-start-center-column-nowrap padding-r-24 padding-l-24">
        <CustomersReport />
      </div>
    </>
  );
};
