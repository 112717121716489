import * as React from 'react';
import { useCallback } from 'react';
import { InvoiceInterface } from '../../../interfaces/invoice.interface';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { pdfStyles } from './pdfStyles';
import { getDateOfDoc } from '../../../services/utils/pdf';

interface Props {
  doc: InvoiceInterface;
}

export const PdfDocTitle = ({ doc }: Props) => {
  const { t } = useTranslation();

  const getDate = useCallback(() => {
    return getDateOfDoc(doc?.documentDate, doc?.sentToAadeAt);
  }, [doc?.documentDate, doc?.sentToAadeAt]);

  return (
    <View style={styles.row}>
      <View style={styles.column}>
        <Text style={pdfStyles.smallTitle}>{t('PDF.DOC_TYPE')}</Text>
        <View style={styles.row}>
          <Text style={[pdfStyles.body1, { paddingRight: 2 }]}>
            {t(`DOC_TYPES.${doc?.documentType?.name}`)}
          </Text>
        </View>
      </View>

      <View style={[styles.columnCenter]}>
        <Text style={pdfStyles.smallTitle}>
          {t('PDF.SERIES')} - {t('PDF.SERIAL_NUMBER')}
        </Text>
        <View style={styles.flexCenterSpaceRow}>
          <Text style={pdfStyles.body1}>{doc?.seriesName || '0'}</Text>
          <Text>-</Text>
          <Text style={pdfStyles.body1}>{doc?.serialNumber || 'empty'}</Text>
        </View>
      </View>

      <View style={styles.columnEnd}>
        <Text style={pdfStyles.smallTitle}>{t('PDF.DATE')}</Text>
        <Text style={pdfStyles.body1}>{getDate()}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  column: {
    flexDirection: 'column',
    width: '33%',
    alignItems: 'flex-start'
  },
  columnCenter: {
    flexDirection: 'column',
    width: '33%',
    alignItems: 'center'
  },
  columnEnd: {
    flexDirection: 'column',
    width: '33%',
    alignItems: 'flex-end',
  },
  flexCenterSpaceRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontFamily: 'Manrope',
    fontWeight: 'bold',
  },
  regularTitle: {
    fontSize: 10,
  },
  smallTitle: {
    fontSize: 8,
    fontWeight: 'bold',
  },
});
