import dayjs from 'dayjs';

export const nowDateInUtc = () => {
  return dayjs().format('YYYY-MM-DD');
};

export const isAfter24Hours = (date: string) => {
  const res = dayjs().diff(dayjs(date), 'hours');
  return res > 24;
};
