import { InvoiceInterface, TaxWithhold } from '../../interfaces/invoice.interface';
import i18next from 'i18next';

export const isDocTypeCodeValidForTaxWithhold = (docTypeCode: string) => {
  return docTypeCode === '2.1';
};

export const shouldShowTaxWithhold = (doc: InvoiceInterface) => {
  if (doc.taxWithholdPercentage && isDocTypeCodeValidForTaxWithhold(doc.documentType?.aadeCode)) return true;

  return false;
};

export const defaultTaxWithhold = (data: TaxWithhold[]) => {
  return data?.map((entity) => {
    return {
      label: i18next.t(`TAX_WITHHOLD.${entity?.name}`),
      value: entity?.id,
      object: entity,
    };
  });
};
