import * as React from 'react';
import { useCallback } from 'react';
import { MoneyCollection } from '../../../../interfaces/moneyCollection.interface';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { pdfStyles } from '../pdfStyles';
import { TRADER_TYPE } from '../../../../interfaces/enumerables';

interface Props {
  doc: MoneyCollection;
}

export const PdfMoneyCollectionTitle = ({ doc }: Props) => {
  const { t } = useTranslation();

  const getTitle = useCallback(() => {
    if (doc.traderType === TRADER_TYPE[1].id) {
      return 'INCOME_FROM_CUSTOMER';
    }
    return 'PAYMENT_TO_SUPPLIER';
  }, []);

  return (
    <View style={styles.title}>
      <Text style={pdfStyles.headerTitle}>{t(`PDF.${getTitle()}`)}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    textDecoration: 'underline',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
