import * as React from 'react';
import { useCallback } from 'react';
import { InvoiceInterface } from '../../../interfaces/invoice.interface';
import { useTranslation } from 'react-i18next';
import { QRCode } from 'antd';

interface Props {
  doc: InvoiceInterface;
}

export const DocViewAadeData = ({ doc }: Props) => {
  const { t } = useTranslation();
  const qrCodeLink = doc?.aadeQr || 'https://billease.gr';

  const getAadeData = useCallback(() => {
    if (doc?.sentToAade) {
      return (
        <div className="flex-start-center-row-nowrap">
          <QRCode value={qrCodeLink} size={70} className="margin-r-10" bordered={false} />
          <div className="flex-start-start-column-nowrap">
            <div className="flex-start-center-row-wrap">
              <div className="boldText0 width40">{t('PDF.AADE_ID')}</div>
              <div className="padding-r-6">:</div>
              <div className="normalText0">{doc?.aadeUID}</div>
            </div>
            <div className="flex-start-center-row-wrap">
              <div className="boldText0 width40">{t('PDF.AADE_MARK')}</div>
              <div className="padding-r-6">:</div>
              <div className="normalText0">{doc?.mark}</div>
            </div>
          </div>
        </div>
      );
    }
  }, [doc?.sentToAade]);

  const getSection = useCallback(() => {
    return (
      <div className="flex-start-center-row-nowrap">
        <div className="min-width-240px">{getAadeData()}</div>
        <div className="flex-start-start-row-nowrap padding-l-120 padding-b-36">
          <div className="boldText1 padding-r-100">{t('PDF.PUBLISHER')}</div>
          <div className="boldText1">{t('PDF.RECEIVER')}</div>
        </div>
      </div>
    );
  }, [getAadeData]);

  return getSection();
};
