import i18n from 'i18next';
import { useMutation } from 'react-query';
import { GenericResponse } from '../../../interfaces/shared.interface';
import { getSelectedCompanyId } from '../../../services/localStorage.service';
import { setToast } from '../../../store/core/coreSlice';
import { store } from '../../../store/store';
import { apiRQ } from '../../api';
import { queryClient } from '../../APIProvider';
import { getHttpMethod } from '../../helpers';

const updateCreateEntity = async (variables: any): Promise<GenericResponse> => {
  const resp = await apiRQ[getHttpMethod(variables?.id)]<GenericResponse>('/customers/', { data: variables });

  return resp.data;
};

export const useMutateCustomer = () => {
  return useMutation<GenericResponse, Error>(updateCreateEntity, {
    onSuccess: (data) => {
      if (data?.success) {
        store.dispatch(
          setToast({
            value: {
              show: true,
              msg: i18n.t('SHARED.UPDATE_SUCCESSFUL'),
              isError: !data?.success || false,
              position: 'top',
            },
          }),
        );
      }
    },
    onSettled: () => {
      queryClient?.invalidateQueries({ queryKey: ['getCustomers'] });
    },
  });
};

const deleteEntity = async (data: any): Promise<GenericResponse> => {
  data.companyId = await getSelectedCompanyId();
  const resp = await apiRQ.delete<GenericResponse>('/customers/', { data });

  return resp.data;
};

export const useDeleteCustomer = () => {
  return useMutation<GenericResponse, Error>(deleteEntity, {
    onSettled: (data) => {
      queryClient?.invalidateQueries({ queryKey: ['getCustomers'] });
      if (data?.success) {
        store.dispatch(
          setToast({
            value: {
              show: true,
              msg: i18n.t('SHARED.DELETE_SUCCESSFUL'),
              isError: false,
            },
          }),
        );
        return;
      }

      const errorMsg = data?.errorCode || 'SOMETHING_WENT_WRONG';
      store.dispatch(
        setToast({
          value: {
            show: true,
            msg: i18n.t(`API_ERRORS.${errorMsg}`),
            isError: true,
          },
        }),
      );
    },
  });
};
