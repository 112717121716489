import * as React from 'react';
import { MoneyCollection } from '../../../../interfaces/moneyCollection.interface';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { pdfStyles } from '../pdfStyles';
import { getLocalDate } from '../../../../config/utils/dateHelpers';

interface Props {
  doc: MoneyCollection;
}

export const PdfMoneyCollectionCheckDetails = ({ doc }: Props) => {
  const { t } = useTranslation();

  return (
    <View style={styles.row}>
      <View style={styles.column}>
        <Text style={pdfStyles.smallTitle}>{t('PDF.BANK')}</Text>
        <Text style={[pdfStyles.body1]}>{doc?.paycheckBank || '-'}</Text>
      </View>
      <View style={styles.columnCenter}>
        <Text style={pdfStyles.smallTitle}>{t('PDF.CHECK_NUMBER')}</Text>
        <Text style={[pdfStyles.body1]}>
          {doc?.paycheckNumber || '-'}
        </Text>
      </View>
      <View style={styles.columnEnd}>
        <Text style={pdfStyles.smallTitle}>{t('PDF.CHECK_DATE')}</Text>
        <Text style={[pdfStyles.body1]}>
          {doc?.paycheckDate ? getLocalDate(doc?.paycheckDate) : '-'}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 10,
  },
  column: {
    flexDirection: 'column',
    width: '33%',
    alignItems: 'flex-start',
  },
  columnCenter: {
    flexDirection: 'column',
    width: '33%',
    alignItems: 'center',
  },
  columnEnd: {
    flexDirection: 'column',
    width: '33%',
    alignItems: 'flex-end',
  },
});
