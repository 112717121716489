import { TextFieldTypes } from '@ionic/core';
import { Error, GetEntities } from './shared.interface';
import { UseQueryResult } from 'react-query';

export enum INPUT_TYPE {
  SELECT_SEARCH = 'selectSearch',
  ANTD_SELECT_SEARCH = 'antdSelectSearch',
  ANTD_SELECT = 'antdSelect',
  MULTI_SELECT_SEARCH = 'multiSelectSearch',
  TOGGLE = 'toggle',
  DATE = 'date',
  SELECT = 'select',
  TEXT_AREA = 'textArea',
  TEXT = 'text',
  NUMBER = 'number',
  B_INPUT = 'billEaseInput',
}

export interface EntityFormField<T> {
  name: string; // to use in forms
  label: string; // translation
  labelPrefix?: (data: any) => string;
  placeholder: string;
  value: string | number | any;
  listForSelect?: any[];
  type?: TextFieldTypes | INPUT_TYPE;
  allowClear?: boolean;
  labelStyle?: string;
  inputType?: TextFieldTypes;
  heightLarge?: boolean;
  disabled?: boolean;
  disabledCheck?: (data: any) => boolean;
  fieldToSubmit?: any;
  fieldToSubmit2?: any;
  fieldToSearch?: string; // Use for BSelectSearch to search/show results by specific field in results
  fieldMap?: (data: any[]) => any;
  translationGroupKey?: string;
  tooltip?: string;
  defaultValue?: any;
  prefillValue?: any;
  fieldValue?: string;
  fieldValue2?: string;
  focusOnMount?: boolean;
  required?: boolean;
  useHook?: (
    page: number,
    orderBy: string | undefined,
    order: string | undefined,
    search: string,
  ) => UseQueryResult<GetEntities<T>, Error>;
  data?: Record<string, any>[];
  maxLength?: number;
}

export interface EntityFormInterface<T> {
  id?: number | string;
  canDelete?: boolean;
  idToShow?: string | number | null;
  fields: EntityFormField<T>[];
  onlyViewFields?: EntityFormField<T>[];
  actionsOnFormValueChanges?: (formik: any) => void;
}

export interface GetPaginationData<T> {
  data: T[];
  page: number;
  total: number;
  hasMore: boolean;
}

export interface SortBy {
  value: string;
  label: string;
}
