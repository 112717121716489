import * as React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  link: string;
  children: JSX.Element;
  data?: any;
  classes?: any;
}

export const LinkTo = ({ link, children, data = {}, classes = {} }: Props) => {
  const params = { pathname: link, state: data || {} };

  return (
    <Link to={params} onClick={(e) => e.stopPropagation()} className={`flex-center-center-row-wrap ${classes}`}>
      {children}
    </Link>
  );
};
