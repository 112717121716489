import * as React from 'react';
import { useCallback } from 'react';
import { InvoiceInterface } from '../../../../interfaces/invoice.interface';
import { useTranslation } from 'react-i18next';
import { CURRENCY } from '../../../../interfaces/enumerables';
import { decimalToFixed2 } from '../../../../config/utils/calcUtils';
import { shouldShowTaxWithhold } from '../../../../config/utils/salesHelper';
import { useDocValues } from '../../../../services/hooks/useDocValues';

interface Props {
  doc: InvoiceInterface;
}

export const DocViewTotalCost = ({ doc }: Props) => {
  const { t } = useTranslation();
  const docValues = useDocValues(doc.products, doc.vatExceptionCategoryId, doc?.taxWithholdPercentage);

  const getVatValuesView = useCallback(() => {
    return docValues?.valuesByVatCategory?.map?.((cat, index) => {
      return (
        <div className="flex-space-between-start-row-nowrap width-100 normalText1" key={`${cat.percentage}-${index}`}>
          <div className="width64">{cat.percentage}%</div>
          <div className="width180 textAlignEnd">
            {decimalToFixed2(cat.cleanCost)} {CURRENCY.EURO}
          </div>
          <div className="width120 textAlignEnd">
            {decimalToFixed2(cat.discount)} {CURRENCY.EURO}
          </div>
          <div className="width180 textAlignEnd">
            {decimalToFixed2(cat.finalCleanCost)} {CURRENCY.EURO}
          </div>
          <div className="width120 textAlignEnd">
            {decimalToFixed2(cat.total)} {CURRENCY.EURO}
          </div>
        </div>
      );
    });
  }, [docValues?.valuesByVatCategory]);

  const getTaxWithholdSection = useCallback(() => {
    if (shouldShowTaxWithhold(doc)) {
      return (
        <div className="flex-space-between-start-row-nowrap width-100 padding-t-6">
          <div className="flex-start-center-column-nowrap">
            <div className="flex-start-center-row-nowrap">
              <div className="boldText1 padding-r-4">{t('SALE.TAX_WITHHOLD')}</div>
              <div className="normalText0">({doc?.taxWithholdPercentage}%)</div>
            </div>
            <div className="normalText0">({t(`TAX_WITHHOLD.${doc?.taxWithhold?.name}_REASON`)})</div>
          </div>
          <div className="boldText1">
            -{docValues?.taxWithholdAmount} {CURRENCY.EURO}
          </div>
        </div>
      );
    }
  }, [doc, t, docValues?.taxWithholdAmount]);

  return (
    <div className="totalCostContainer normalText1 simpleBorder">
      <div className="flex-space-between-start-row-nowrap width100 padding-b-6 boldText1">
        <div className="width64 boldText1">{t('SALE.VAT')} %</div>
        <div className="width180 textAlignEnd boldText1">{t('SALE.CLEAN_COST_BEFORE_DISCOUNT')}</div>
        <div className="width120 textAlignEnd boldText1">{t('SALE.DISCOUNT')}</div>
        <div className="width180 textAlignEnd boldText1">{t('SALE.CLEAN_COST')}</div>
        <div className="width120 textAlignEnd boldText1">
          {t('SALE.VAT')} {CURRENCY.EURO}
        </div>
      </div>
      {getVatValuesView()}
      <div className="separator" />
      <div className="flex-space-between-start-row-nowrap width-100 padding-b-6">
        <div className="boldText1">{t('SALE.TOTAL_CLEAN_VALUE')}</div>
        <div className="boldText1">
          {docValues?.cleanValueOfProducts} {CURRENCY.EURO}
        </div>
      </div>
      <div className="flex-space-between-start-row-nowrap width-100 padding-b-6 boldText1">
        <div className="boldText1">{t('SALE.TOTAL_VAT')}</div>
        <div className="boldText1">
          {docValues?.totalVat} {CURRENCY.EURO}
        </div>
      </div>
      <div className="flex-space-between-start-row-nowrap width-100 boldText1">
        <div className="boldText1">{t('SALE.TOTAL')}</div>
        <div className="boldText1">
          {docValues?.totalGrossAmount} {CURRENCY.EURO}
        </div>
      </div>
      {getTaxWithholdSection()}
      <div className="flex-space-between-start-row-nowrap width-100 boldText1 padding-t-20">
        <div className="boldText1">{t('SALE.PAYABLE_AMOUNT')}</div>
        <div className="boldText1">
          {docValues?.payableAmount} {CURRENCY.EURO}
        </div>
      </div>
    </div>
  );
};
