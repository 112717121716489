import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Company } from '../../../../../interfaces/company.interface';
import { Customer } from '../../../../../interfaces/customer.interface';
import { getFullDateTime } from '../../../../../config/utils/dateHelpers';
import { CustomersReportTableSectionView } from './CustomersReportTableSectionView';

interface Props {
  company: Company;
  customers: Customer[];
}

export const CustomersReportView = ({ company, customers }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="margin-b-80">
      <div className="a4-container-only-width">
        <div className="section">
          <div className="padding-t-10">
            <div className="flex-center-center-column-nowrap">
              <div className="boldText3">{t('CUSTOMERS_REPORT.CURRENT_CUSTOMERS_SUBTOTALS')}</div>
              <div className="normalText3 padding-t-10">
                {company?.title} - {t('CUSTOMERS_REPORT.VAT_NUMBER')}: {company?.vatNumber}
              </div>
              <div className="normalText1 padding-t-10">{getFullDateTime(new Date().toISOString())}</div>
            </div>
            <CustomersReportTableSectionView customers={customers} />
          </div>
        </div>
      </div>
    </div>
  );
};
