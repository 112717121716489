import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SetBooleanPayload } from '../../interfaces/store.interface';
import { CompaniesSlice, CompaniesState, SetCompaniesPayload, SetCompanyPayload } from './companiesSlice.interface';
import { Company } from '../../interfaces/company.interface';
import { getCompanies } from '../../api/hooks/useCompanies';
import { getSelectedCompanyStorage, setSelectedCompanyStorage } from '../../services/localStorage.service';
import { selectUser } from '../user/userSlice';
import { RootState } from '../store';
import { batch } from 'react-redux';
import { setServerError } from '../core/coreSlice';
import i18next from 'i18next';
import { queryClient } from '../../api/APIProvider';

const initialState: CompaniesState = {
  loading: false,
  selectedCompany: null,
  companies: [],
};

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setCompaniesLoading: (state: CompaniesState, action: PayloadAction<SetBooleanPayload>): void => {
      state.loading = action.payload.value;
    },
    setSelectedCompany: (state: CompaniesState, action: PayloadAction<SetCompanyPayload>): void => {
      state.selectedCompany = action.payload.company;
    },
    setCompanies: (state: CompaniesState, action: PayloadAction<SetCompaniesPayload>): void => {
      state.companies = action.payload.companies;
    },
    resetCompanies: (): CompaniesState => initialState,
  },
});

//////////////////////////////// ACTIONS ////////////////////////////////
export const { setCompaniesLoading, setSelectedCompany, setCompanies, resetCompanies } = companiesSlice.actions;

//////////////////////////////// SELECTORS ////////////////////////////////
export const selectCompaniesLoading = (state: CompaniesSlice): boolean => state.companies.loading;
export const selectSelectedCompany = (state: CompaniesSlice): Company | null => state.companies.selectedCompany;
export const selectIsSelectedCompanyOwnedByUser = (state: RootState): boolean | null =>
  state.companies.selectedCompany?.ownerId === state?.user?.user?.user?.id;

export const selectSelectedCompanyName = (state: CompaniesSlice): string =>
  state.companies.selectedCompany?.title || '';
export const selectCompanies = (state: CompaniesSlice): Company[] => state.companies.companies;

export const selectShippingOfCompany = createSelector(selectSelectedCompany, (state) => state?.shipping || []);

export default companiesSlice.reducer;

export const getCompaniesAsync = createAsyncThunk('companies/getCompanies', async (_, { dispatch }) => {
  batch(() => {
    dispatch(setCompaniesLoading({ value: true }));
    dispatch(setServerError({ value: null }));
  });

  const companies = await getCompanies();
  if (companies.thrownError) {
    dispatch(setServerError({ value: i18next.t('API_ERRORS.SERVER_IS_DOWN') }));
    return;
  }

  const selectedCompany = await getSelectedCompanyStorage();

  const isCompanyValid = companies.find((comp) => comp.id === selectedCompany?.id);

  if (isCompanyValid) {
    dispatch(setSelectedCompany({ company: isCompanyValid }));
  } else {
    if (companies[0]) {
      dispatch(setSelectedCompany({ company: companies[0] }));
      await setSelectedCompanyStorage(companies[0]);
    }

    // TODO: what should happen if no companies are available
  }
  dispatch(setCompanies({ companies }));

  dispatch(setCompaniesLoading({ value: false }));
});

export const setSelectedCompanyAsync = createAsyncThunk(
  'companies/setSelectedCompany',
  async (data: Company | null, { dispatch }) => {
    dispatch(setSelectedCompany({ company: data }));

    await setSelectedCompanyStorage(data);
    await queryClient.invalidateQueries();
  },
);

export const selectIsCompanyOwner = createSelector(selectUser, selectSelectedCompany, (user, company) => {
  return company?.ownerId === user?.user?.id;
});
