import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceInterface } from '../../../interfaces/invoice.interface';
import { showProductsCopyright } from '../../../services/utils/pdf';

interface Props {
  doc: InvoiceInterface;
}

export const DocViewCopyright = ({ doc }: Props) => {
  const { t } = useTranslation();
  const showProdCopyright = useMemo(() => showProductsCopyright(doc), [doc]);

  return (
    <div className="copyright width100">
      <div className="flex-space-between-center-row-nowrap ">
        <div className="boldText8px">{t('PDF.BY_BILLEASE')}</div>
        <div className="flex-start-start-row-nowrap">
          <div className="normalText8px padding-r-4">{t('PDF.COPYRIGHT_1')}</div>
          {showProdCopyright && <div className="normalText8px">{t('PDF.COPYRIGHT_2')}</div>}
        </div>
      </div>
    </div>
  );
};
