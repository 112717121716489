import { InvoiceInterface } from '../../../../interfaces/invoice.interface';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useCallback } from 'react';
import { getTotalQuantities } from '../../../../services/utils/productCost';
import { Text, View } from '@react-pdf/renderer';
import { pdfStyles } from '../pdfStyles';
import { getDecimalFixed } from '../../../../config/utils/decimalUtls';

interface Props {
  doc: InvoiceInterface;
}

export const PdfTotalQuantities = ({ doc }: Props) => {
  const { t } = useTranslation();

  const getSections = useCallback(() => {
    const quantities = getTotalQuantities(doc.products);

    return quantities.map((quan) => {
      return (
        <View style={pdfStyles.flexStartStartRow} key={quan.label}>
          <Text style={[pdfStyles.width60start, pdfStyles.smallTitleRegular]}>{t(`PRODUCT_UNITS_SHORT.${quan.label}`)}</Text>
          <Text style={pdfStyles.smallTitleRegular}>{getDecimalFixed(quan.value)}</Text>
        </View>
      );
    });
  }, [doc.products]);

  return (
    <View style={[pdfStyles.column, pdfStyles.simpleBorder]}>
      <Text style={pdfStyles.smallTitle}>{t('SALE.TOTAL')}</Text>
      {getSections()}
    </View>
  );
};
