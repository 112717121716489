import { apiRQ } from '../../api';
import { SORT_BY_CUSTOMERS } from '../../../interfaces/enumerables';
import { useQuery } from 'react-query';
import { Customer } from '../../../interfaces/customer.interface';

const getCustomerReport = async (params: { orderBy: string; order: string }): Promise<Customer[]> => {
  const resp = await apiRQ.get<Customer[]>('/reports/customers/', { params });

  return resp.data;
};

export const useCustomersReport = (sortBy = SORT_BY_CUSTOMERS.CUSTOMER_SUBTOTAL, order = 'ASC') => {
  return useQuery<Customer[], Error>(
    ['useCustomersReport', sortBy, order],
    () => getCustomerReport({ orderBy: sortBy, order }),
    {
      cacheTime: 6000,
      staleTime: 6000,
    },
  );
};
