import { Error } from '../../interfaces/shared.interface';
import { apiRQ } from '../api';
import { useQuery } from 'react-query';

export interface NextSeries {
  seriesName: string;
  serialNumber: number;
  canResetSerialNumber: boolean;
}

export interface GetNextSeries extends NextSeries {
  byDocType?: NextSeries;
}

const getNextSeries = async (params: {
  seriesName: string;
  documentTypeId: number;
  entityId?: string;
  byDocType: boolean;
  isClone?: boolean;
}): Promise<GetNextSeries> => {
  // If clone remove entityId to get correct next series
  if (params?.isClone) delete params?.entityId;

  const resp = await apiRQ.get<GetNextSeries>('/document-series', { params });

  return resp.data || {};
};

export const useNextSeries = (
  seriesName: string,
  documentTypeId: number,
  entityId?: string | undefined,
  byDocType = false,
  isClone = false,
) => {
  return useQuery<GetNextSeries, Error>(
    ['getNextSeries', seriesName, documentTypeId, entityId, byDocType, isClone],
    () => getNextSeries({ seriesName, documentTypeId, entityId, byDocType, isClone }),
  );
};
