import { useQuery } from 'react-query';
import { Buffer } from 'buffer';
import axios from 'axios';

export const getCompanyLogoService = async (url: string): Promise<string | null> => {
  const resp = await axios.get<ArrayBuffer>(url, {
    responseType: 'arraybuffer',
  });

  if (resp?.data) {
    return Buffer.from(resp.data).toString('base64');
  }

  return null;
};

export const useCompanyLogo = (url: string) => {
  return useQuery<string | null, Error>(['useCompanyLogo', url], () => getCompanyLogoService(url), {
    cacheTime: 60000 * 60 * 3, // 3 hours cache
    staleTime: Infinity,
    enabled: !!url,
  });
};
