import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PATHS } from '../../../navigation/data';

export const CompaniesSettings = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const goToCompanies = useCallback(() => {
    history.push(PATHS.COMPANIES);
  }, [history]);

  return (
    <div
      className="info-card flex-center-center-column-nowrap hoverable settings-card-dimensions"
      onClick={goToCompanies}>
      <div className="boldText7 margin-b-24">{t('SETTINGS.COMPANIES_TITLE')}</div>
      <div className="normalText4 text-center">{t('SETTINGS.COMPANIES_DESCRIPTION')}</div>
    </div>
  );
};
