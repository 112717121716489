import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { CURRENCY } from '../../../../interfaces/enumerables';
import { useTranslation } from 'react-i18next';
import { setToast } from '../../../../store/core/coreSlice';
import { useDispatch } from 'react-redux';
import { decimalToFixed2 } from '../../../../config/utils/calcUtils';
import { useDocValues } from '../../../../services/hooks/useDocValues';

interface Props {
  formik: any;
}

export const DocProductsCost = ({ formik }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isReceipt = useMemo(
    () =>
      formik?.values?.documentTypeId?.object?.aadeCode === '11.2' ||
      formik?.values?.documentTypeId?.aadeCode === '11.2',
    [formik?.values?.documentTypeId],
  );
  const isCash = useMemo(
    () => formik?.values?.paymentTypeId?.object?.aadeCode === '3' || formik?.values?.paymentTypeId?.aadeCode === '3',
    [formik?.values?.paymentTypeId],
  );

  const taxWithholdPercentage = useMemo(
    () => formik?.values?.taxWithholdId?.object?.value || formik?.values?.taxWithholdId?.value || '0.00',
    [formik?.values?.taxWithholdId?.object?.value, formik?.values?.taxWithholdId?.value],
  );

  const docValues = useDocValues(formik.values?.products, 0, taxWithholdPercentage);

  const showError = useCallback(() => {
    dispatch(
      setToast({
        value: {
          show: true,
          msg: t('FORMS.ERRORS.VALUE_OVER_500'),
          isError: true,
          position: 'top',
          duration: 4000,
        },
      }),
    );
  }, [dispatch, t]);

  useEffect(() => {
    // cash not allowed for net value over 500
    if (isCash) {
      let total = formik?.values?.totalValue;
      if (isReceipt) {
        const totalVat = docValues.totalVat;
        total += Number(totalVat);
        if (total >= 500) {
          showError();
          return;
        }
      }

      if (total > 500) {
        showError();
      }
    }
  }, [
    formik?.values?.totalValue,
    formik?.values.documentTypeId,
    formik?.values.paymentTypeId,
    isReceipt,
    isCash,
    showError,
    docValues.totalVat,
  ]);

  useEffect(() => {
    formik.setFieldValue('totalValue', docValues?.cleanValueOfProducts);
  }, [docValues?.cleanValueOfProducts]);

  const getVatValuesView = useCallback(() => {
    return docValues?.valuesByVatCategory?.map?.((cat, index) => {
      return (
        <div className="flex-space-between-start-row-nowrap width-100" key={`${cat.percentage}-${index}`}>
          <div className="width-60px">{cat.percentage}%</div>
          <div className="width-180px textAlignEnd">
            {decimalToFixed2(cat.cleanCost)} {CURRENCY.EURO}
          </div>
          <div className="width-120px textAlignEnd">
            {decimalToFixed2(cat.discount)} {CURRENCY.EURO}
          </div>
          <div className="width-180px textAlignEnd">
            {decimalToFixed2(cat.finalCleanCost)} {CURRENCY.EURO}
          </div>
          <div className="width-120px textAlignEnd">
            {decimalToFixed2(cat.total)} {CURRENCY.EURO}
          </div>
        </div>
      );
    });
  }, [docValues?.valuesByVatCategory]);

  const getTaxWithholdSection = useCallback(() => {
    if (docValues?.taxWithholdAmount !== '0.00') {
      return (
        <div className="flex-space-between-start-row-nowrap width-100 padding-t-6">
          <div className="boldText5">{t('SALE.TAX_WITHHOLD')}</div>
          <div className="boldText5">
            -{docValues?.taxWithholdAmount} {CURRENCY.EURO}
          </div>
        </div>
      );
    }
  }, [docValues?.taxWithholdAmount, t]);

  return (
    <div className="flex-start-center-column-nowrap min-width-460px">
      <div className="flex-space-between-start-row-nowrap width-100 padding-b-4">
        <div className="width-60px boldText5">{t('SALE.VAT')} %</div>
        <div className="width-180px textAlignEnd boldText5">{t('SALE.CLEAN_COST_BEFORE_DISCOUNT')}</div>
        <div className="width-120px textAlignEnd boldText5">{t('SALE.DISCOUNT')}</div>
        <div className="width-180px textAlignEnd boldText5">{t('SALE.CLEAN_COST')}</div>
        <div className="width-120px textAlignEnd boldText5">
          {t('SALE.VAT')} {CURRENCY.EURO}
        </div>
      </div>
      {getVatValuesView()}
      <div className="g-separator margin-t-8 margin-b-8" />
      <div className="flex-space-between-start-row-nowrap width-100 padding-b-6">
        <div>{t('SALE.TOTAL_CLEAN_VALUE')}</div>
        <div>{`${docValues?.cleanValueOfProducts} ${CURRENCY.EURO}`}</div>
      </div>
      <div className="flex-space-between-start-row-nowrap width-100 padding-b-6">
        <div>{t('SALE.TOTAL_VAT')}</div>
        <div>{`${docValues?.totalVat} ${CURRENCY.EURO}`}</div>
      </div>
      <div className="flex-space-between-start-row-nowrap width-100">
        <div className="boldText5">{t('SALE.TOTAL')}</div>
        <div className="boldText5">
          {docValues?.totalGrossAmount} {CURRENCY.EURO}
        </div>
      </div>
      {getTaxWithholdSection()}
      <div className="flex-space-between-start-row-nowrap width-100 margin-t-40">
        <div className="boldText5">{t('SALE.PAYABLE_AMOUNT')}</div>
        <div className="boldText5">
          {docValues.payableAmount} {CURRENCY.EURO}
        </div>
      </div>
    </div>
  );
};
