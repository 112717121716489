import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { AnyObject } from 'yup';
import { useFormik } from 'formik';
import { formDataToSubmit, getInitialFormData } from '../../../config/utils/formUtils';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMutateCustomer } from '../../../api/hooks/customers/useMutateCustomer';
import { Loading } from '../../../components/atoms/Loading';
import { IonButton } from '@ionic/react';
import { CustomerMainInfo } from './CustomerMainInfo';
import { CustomerSecondaryInfo } from './CustomerSecondaryInfo';
import { CustomerShipping } from './CustomerShipping';
import { setToast } from '../../../store/core/coreSlice';
import { getChangedFields } from '../../../config/utils/formDiffUtils';
import { isObjectEmpty } from '../../../services/utils/helpers';

interface Props {
  data: any;
  validationSchema: AnyObject;
}

export const CustomerForm = ({ data, validationSchema }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { mutate: createModel, isLoading: isLoadingMutate, isSuccess, data: resp } = useMutateCustomer();
  const initialFormData = useMemo(() => getInitialFormData(data), [data]);
  // console.log('initialFormData: ', initialFormData);

  useEffect(() => {
    if (isSuccess && resp?.success) {
      history.goBack();
    }
  }, [isSuccess]);

  const formik = useFormik({
    initialValues: initialFormData,
    validationSchema,
    enableReinitialize: false,
    onSubmit: (values) => {
      // console.log('================= onSubmit =================');
      // console.log('=== initialFormData: ', initialFormData);

      let dataToSubmit: Record<string, any> = { ...values };
      // console.log('=== dataToSubmit: ', dataToSubmit);

      if (data.id) {
        const changedFields = getChangedFields(data.fields, initialFormData, dataToSubmit);
        // console.log('=== changedFields: ', changedFields);

        // Nothing changed, triggered info message
        if (isObjectEmpty(changedFields)) {
          dispatch(setToast({ value: { show: true, msg: t('SHARED.NO_CHANGES'), isError: false } }));
          return;
        }

        dataToSubmit = {
          id: data.id,
          ...changedFields,
        };
      }

      // console.log('dataToSubmit: ', dataToSubmit);
      const finalData = formDataToSubmit(data.fields, dataToSubmit);

      if (finalData?.shipping?.length === 1 && finalData?.shipping[0] === '') {
        finalData.shipping = null;
      }

      // console.log('=== finalData: ', finalData);
      // return;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      createModel(finalData);
    },
  });

  // console.log('formik.errors: ', formik.errors);
  // console.log('formik.values: ', formik.values);

  return (
    <div className="scrollable padding-b-10">
      {isLoadingMutate && <Loading />}
      <form onSubmit={formik.handleSubmit}>
        <div className="padding-16">
          <div className="margin-b-16">
            <CustomerMainInfo
              countryField={data.fields[0]}
              customerTypeField={data.fields[1]}
              vatField={data.fields[2]}
              currencyField={data.fields[11]}
              vatRegimeField={data.fields[12]}
              vatExcCatField={data.fields[13]}
              formik={formik}
            />
          </div>
          <div className="margin-b-16">
            <CustomerSecondaryInfo
              formik={formik}
              companyNameField={data.fields[3]}
              doyField={data.fields[4]}
              emailField={data.fields[5]}
              phoneNumberField={data.fields[6]}
              professionField={data.fields[7]}
              addressField={data.fields[8]}
              cityField={data.fields[9]}
              postalCodeField={data.fields[10]}
            />
          </div>
          <div className="margin-b-16">
            <CustomerShipping shippingField={data.fields[14]} formik={formik} />
            {/*  TODO: add section for banks */}
          </div>
        </div>
        <div className="flex-center-center-row-wrap">
          <IonButton type="submit" disabled={!formik.isValid || !formik.dirty}>
            {t('FORMS.SAVE')}
          </IonButton>
        </div>
      </form>
    </div>
  );
};
