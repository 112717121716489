import * as React from 'react';
import { useCallback } from 'react';
import './ItemCard.scss';
import { EntityIdInCard } from './EntityIdInCard';
import { LinkTo } from './LinkTo';

interface Props {
  children: JSX.Element;
  classes?: string;
  routerLink?: string;
  entityId?: number | string | null;
}

export const ItemCard = ({ children, classes = '', routerLink = '', entityId = '' }: Props) => {
  const getIdInCard = useCallback(() => {
    if (entityId) return <EntityIdInCard id={entityId} />;
  }, [entityId]);

  return (
    <LinkTo link={routerLink} data={{}} classes={`link-reset card ${classes}`}>
      <>
        {getIdInCard()}
        {children}
      </>
    </LinkTo>
  );
};
