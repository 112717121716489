import { useMemo } from 'react';
import { getCleanValueOfProducts, getTotalVatFromVatCategories, getVatValuesService } from '../utils/productCost';
import { addWithDecimals, minusWithDecimals, multiplyWithDecimals } from '../../config/utils/calcUtils';
import { ProductInOrder } from '../../interfaces/product.interface';

export const useDocValues = (
  products: ProductInOrder[],
  vatExceptionCategoryId: number | undefined,
  taxWithholdPercentage: string | null | undefined,
) => {
  const valuesByVatCategory = useMemo(
    () => getVatValuesService(products, !!vatExceptionCategoryId),
    [products, vatExceptionCategoryId],
  );
  const cleanValueOfProducts = useMemo(() => getCleanValueOfProducts(products), [products]);

  const totalVat = useMemo(() => {
    if (vatExceptionCategoryId) {
      return (0.0).toFixed(2);
    }

    return getTotalVatFromVatCategories(valuesByVatCategory);
  }, [vatExceptionCategoryId, valuesByVatCategory]);

  const totalGrossAmount = useMemo(
    () => addWithDecimals(cleanValueOfProducts, totalVat),
    [cleanValueOfProducts, totalVat],
  );

  const taxWithholdAmount = useMemo(() => {
    if (taxWithholdPercentage === '0.00' || !taxWithholdPercentage) {
      return '0.00';
    }

    return multiplyWithDecimals(cleanValueOfProducts, Number(taxWithholdPercentage) / 100);
  }, [taxWithholdPercentage, cleanValueOfProducts]);

  const payableAmount = useMemo(() => {
    return minusWithDecimals(totalGrossAmount, taxWithholdAmount);
  }, [taxWithholdAmount, totalGrossAmount]);

  return { valuesByVatCategory, cleanValueOfProducts, totalVat, totalGrossAmount, taxWithholdAmount, payableAmount };
};
