import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { CustomerShippingItem } from './CustomerShippingItem';
import { useTranslation } from 'react-i18next';
import { FieldArray, FormikProvider } from 'formik';
import { IonFab, IonFabButton, IonIcon } from '@ionic/react';
import { addCircleOutline } from 'ionicons/icons';
import { showError } from '../../../config/utils/formDiffUtils';

interface Props {
  formik: any;
  shippingField: any;
}

export const CustomerShipping = ({ formik, shippingField }: Props) => {
  const { t } = useTranslation();
  const hasError = useMemo(() => showError(formik, formik?.errors?.shipping), [formik?.errors?.shipping]);

  const getShipping = useCallback(() => {
    return formik?.values?.shipping.map((ship: string, index: number) => {
      return <CustomerShippingItem key={index} shippingField={shippingField} formik={formik} index={index} />;
    });
  }, [formik?.values?.shipping, shippingField]);

  const getAddItemButton = useCallback(() => {
    return (
      <div className="flex-center-center-row-wrap padding-l-32 positionRelative">
        <FormikProvider value={formik}>
          <FieldArray
            name="shipping"
            render={({ push }) => (
              <IonFab slot="fixed" horizontal="center" vertical="center">
                <IonFabButton onClick={() => push([''])} color="transparent" className="add-fab">
                  <IonIcon icon={addCircleOutline} size="large" color="success" />
                </IonFabButton>
              </IonFab>
            )}
          />
        </FormikProvider>
      </div>
    );
  }, []);

  return (
    <div className="docForm">
      <div className="padding-t-16 padding-b-24 padding-l-16 padding-r-8">
        <div className={`padding-b-16 ${hasError && 'text-danger'}`}>{t('FORMS.SHIPPING_FIELDS')}</div>
        <div className="flex-start-center-row-wrap flex-gap-30">
          {getShipping()}
          {getAddItemButton()}
        </div>
      </div>
    </div>
  );
};
