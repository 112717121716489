import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useCallback } from 'react';
import { IonInput, IonItem } from '@ionic/react';
import { disableSubmitOnEnter } from '../../config/utils/formUtils';
import { TooltipInInput } from './TooltipInInput';

interface Props {
  field: {
    name: string;
    label: string;
    placeholder?: string;
    value: string | number;
    tooltip?: string;
  };
}

export const ViewOnlyField = ({ field }: Props) => {
  const { t } = useTranslation();

  const getValueTooltip = useCallback(() => {
    if (field.tooltip) return <TooltipInInput value={field.tooltip} />;
  }, [field.tooltip]);

  return (
    <div>
      <div className="padding-b-16 padding-l-12">{field.label}</div>
      <div key={field?.name} className="flex-start-center-column-nowrap positionRelative width-300px">
        {getValueTooltip()}
        <IonItem
          id={field.name}
          className="width-300px height-50px border-radius-input g-select-border b-disabled-border"
          mode="md">
          <IonInput
            className="height-48px"
            mode="md"
            aria-label="viewField"
            name={field.name}
            value={field.value}
            placeholder={t(`FORMS.${field.placeholder}`) + '...'}
            onKeyPress={disableSubmitOnEnter}
            disabled={true}
          />
        </IonItem>
      </div>
    </div>
  );
};
