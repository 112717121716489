import { ItemCard } from '../../../components/atoms/ItemCard';
import { PATHS } from '../../../navigation/data';
import { ItemCardField } from '../../../components/atoms/ItemCardField';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { InvoiceInterface } from '../../../interfaces/invoice.interface';
import { getLocalDate } from '../../../config/utils/dateHelpers';
import { SentToAadeIcon } from '../../../components/atoms/SentToAadeIcon';
import { CURRENCY } from '../../../interfaces/enumerables';
import { TypePill } from '../../../components/atoms/TypePill';
import { useTranslation } from 'react-i18next';
import { CloneEntityButton } from '../../../components/molecules/CloneEntityButton';

interface Props {
  data: InvoiceInterface;
}

export const SalesItemCard = ({ data }: Props) => {
  const { t } = useTranslation();
  const currencySymbol = useMemo(() => CURRENCY[data?.currency || 'EURO'], []);

  const getDate = useCallback(() => {
    return getLocalDate(data?.documentDate);
  }, [data]);

  return (
    <div className="width-100 itemOfList positionRelative">
      <ItemCard routerLink={`${PATHS.SALES}/view/${data.id}`} entityId={`${data.seriesName}-${data.serialNumber}`}>
        <>
          <TypePill type={t(`DOC_TYPES.${data?.documentType?.name}`)} />
          <SentToAadeIcon sentToAade={data.sentToAade} />
          <div className="flex-space-between-center-row-wrap width-100">
            <ItemCardField classes="margin-r-24" width="width-180px" value={data?.customer?.companyName} />
            <ItemCardField classes="margin-r-24" width="width-180px" value={`${data?.totalValue} ${currencySymbol}`} />
            <ItemCardField classes="margin-r-24" width="width-180px" value={getDate()} />
          </div>
        </>
      </ItemCard>
      <CloneEntityButton data={{ id: data.id }} path={PATHS.CLONE_SALE} />
    </div>
  );
};
