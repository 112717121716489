import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductInOrder } from '../../../../interfaces/product.interface';

interface Props {
  product: ProductInOrder;
  index: number;
  docHasVatExceptionCategory: boolean;
}

export const DocViewProduct = ({ product, index, docHasVatExceptionCategory }: Props) => {
  const { t } = useTranslation();

  const getVatPercentage = useCallback(() => {
    if (docHasVatExceptionCategory) return 0;

    return product?.vatPercentage;
  }, [docHasVatExceptionCategory, product?.vatPercentage]);

  const getVatExceptionCategory = useCallback(() => {
    if (product?.vatExceptionCategory) {
      return (
        <div className="normalText0 truncate-1-lines">
          {t(`VAT_EXCEPTION_CAT.${product?.vatExceptionCategory.name}`)}
        </div>
      );
    }
  }, [t, product?.vatExceptionCategory]);

  return (
    <div className="flex-start-start-row-nowrap padding-2 width100 positionRelative">
      <div className="productIndexSection">
        <div className="normalText1">{index + 1}.</div>
      </div>
      <div className="flex-start-start-column-nowrap padding-l-4 flex1">
        <div className="boldText1 width-product-1line">{product?.name}</div>
        <div className="normalText1 width-product-1line">{product?.description}</div>
        {getVatExceptionCategory()}
      </div>
      <div className="normalText1 text-center width80">{product?.quantity}</div>
      <div className="normalText1 text-center width40">{t(`PRODUCT_UNITS_SHORT.${product?.unit}`)}</div>
      <div className="normalText1 text-center width80">{product?.price}</div>
      <div className="normalText1 text-center width80">{product?.discountPercentage}</div>
      <div className="normalText1 text-center width58">{product?.finalCost}</div>
      <div className="normalText1 text-center width60">{getVatPercentage()}</div>
    </div>
  );
};
