import * as React from 'react';
import { useCallback } from 'react';
import { Customer } from '../../../../../interfaces/customer.interface';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { pdfStyles } from '../../../../../components/molecules/pdf/pdfStyles';
import { useTranslation } from 'react-i18next';
import { CURRENCY } from '../../../../../interfaces/enumerables';
import { addWithDecimals } from '../../../../../config/utils/calcUtils';

interface Props {
  customers: Customer[];
}

export const CustomerTableTotalsPdf = ({ customers }: Props) => {
  const { t } = useTranslation();

  const getCustomersSubtotal = useCallback(() => {
    let initVal = '0.00';
    for (let i = 0; i < customers.length; i++) {
      initVal = addWithDecimals(initVal, customers[i]?.customerSubtotal);
    }

    return initVal;
  }, [customers]);

  const getSuppliersSubtotal = useCallback(() => {
    let initVal = '0.00';
    for (let i = 0; i < customers.length; i++) {
      initVal = addWithDecimals(initVal, customers[i]?.supplierSubtotal);
    }

    return initVal;
  }, [customers]);

  return (
    <View style={styles.container}>
      <View style={{ flex: 1, flexDirection: 'column', paddingLeft: 4 }}>
        <Text style={[pdfStyles.smallTitle, pdfStyles.maxLines2]}>{t('CUSTOMERS_REPORT.TOTALS')}</Text>
      </View>
      <Text style={[pdfStyles.smallTitle, pdfStyles.width80]}>{getCustomersSubtotal()} {CURRENCY.EURO}</Text>
      <Text style={[pdfStyles.smallTitle, pdfStyles.width80]}>{getSuppliersSubtotal()} {CURRENCY.EURO}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    paddingTop: 10,
  },
});
