import * as React from 'react';
import { InvoiceInterface } from '../../../interfaces/invoice.interface';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { pdfStyles } from './pdfStyles';
import { useTranslation } from 'react-i18next';

interface Props {
  doc: InvoiceInterface;
}

export const PdfNotes = ({ doc }: Props) => {
  const { t } = useTranslation();

  return (
    <View style={styles.section}>
      <View style={styles.container}>
        <Text style={pdfStyles.regularTitle}>{t('PDF.NOTES')}</Text>
      </View>
      <View style={styles.notes}>
        <Text style={pdfStyles.body1}>{doc?.notes}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  section: {
    flexDirection: 'column',
    height: 56,
  },
  container: {
    paddingLeft: 4,
    backgroundColor: '#9d9fa6',
    justifyContent: 'center',
    // alignItems: 'center',
    // textAlign: 'center'
  },
  notes: {
    paddingLeft: 4,
    paddingTop: 4,
  },
});
