import * as React from 'react';
import { InvoiceInterface } from '../../../interfaces/invoice.interface';
import { useTranslation } from 'react-i18next';

interface Props {
  doc: InvoiceInterface;
}

export const DocViewNotes = ({ doc }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="notesSection">
      <div className="notesTitle">
        <div className="normalText2 padding-l-4">{t('PDF.NOTES')}</div>
      </div>
      <div className="padding-l-4 padding-t-4">
        <div className="normalText2">{doc?.notes}</div>
      </div>
    </div>
  );
};
