import { ProductInOrder } from '../../interfaces/product.interface';
import { getISOStringWithRoundSeconds } from './dateHelpers';
import { EntityFormField } from '../../interfaces/forms.inteface';
import { isObjectEmpty, keyBy } from '../../services/utils/helpers';
import { FormikValues } from 'formik/dist/types';

const FIELDS_TO_COMPARE = [
  'description',
  'discountPercentage',
  'name',
  'price',
  'finalCost',
  'productCategoryId',
  'productId',
  'quantity',
  'saleCategoryId',
  'saleCharacterizationId',
  'unit',
  'vatExceptionCategoryId',
  'vatPercentage',
  'vatPercentageRelationId',
];

const cleanProducts = (
  initialProducts: ProductInOrder[],
  productsToSubmit: ProductInOrder[],
): {
  productsToCreate: Partial<ProductInOrder>[];
  productsToUpdate: Partial<ProductInOrder>[];
  productsToDelete: string[];
} => {
  const { productsToCreate, productsToUpdate } = splitProductsToNewAndExisting(productsToSubmit);
  const productsToDelete = getProductsToDelete(initialProducts, productsToSubmit);
  const productsToUpdateWithChanges = [];

  for (let i = 0; i < productsToUpdate.length; i++) {
    const initProduct = initialProducts.find((initProd) => initProd.id === productsToUpdate[i].id);
    if (!initProduct) {
      // SHOULD NEVER HAPPEN
      console.error('UNABLE TO FIND PROD');
      break;
    }

    const changed = getChangedFieldsOfProduct(FIELDS_TO_COMPARE, initProduct, productsToUpdate[i]);

    if (!isObjectEmpty(changed)) {
      productsToUpdateWithChanges.push({ id: productsToUpdate[i].id, ...changed });
    }
  }

  return { productsToCreate, productsToUpdate: productsToUpdateWithChanges, productsToDelete };
};

const removeIdsFromObjects = (data: Record<string, unknown>[]) => {
  const helpData = [...data];
  for (let i = 0; i < data?.length; i++) {
    delete helpData[i]?.id;
    delete helpData[i]?.invoiceId;
  }

  return helpData;
};

const addDefaultZeroToPrices = (products: ProductInOrder[]) => {
  for (let i = 0; i < products.length; i++) {
    if ('price' in products[i]) {
      products[i].price = products[i].price || 0;
    }

    if ('finalCost' in products[i]) {
      // @ts-expect-error no worries
      products[i].finalCost = Number(products[i].finalCost || 0).toFixed(2);
    }
  }

  return products;
};

const getProductsToDelete = (
  initialProducts: Partial<ProductInOrder>[],
  productsToSubmit: Partial<ProductInOrder>[],
): string[] => {
  const initProds = keyBy(initialProducts, 'id');
  // console.log('=== initProds keys: ', initProds);
  const prodsToSubmit = keyBy(productsToSubmit, 'id');
  // console.log('=== prodsToSubmit keys: ', prodsToSubmit);

  return Object.keys(initProds).filter((key) => !(key in prodsToSubmit));
};

const splitProductsToNewAndExisting = (products: Partial<ProductInOrder>[]) => {
  const productsToCreate = [];
  const productsToUpdate = [];

  for (let i = 0; i < products.length; i++) {
    if (products[i].id) {
      productsToUpdate.push(products[i]);
    } else {
      productsToCreate.push(products[i]);
    }
  }

  return { productsToCreate, productsToUpdate };
};

const getChangedFieldsOfProduct = (
  arrayOfFields: string[],
  initialData: Record<string, any>,
  submitData: Record<string, any>,
) => {
  const updatedData: Record<string, any> = {};

  arrayOfFields.forEach((field) => {
    const submitField = getUpdatedDateField(initialData, submitData, field);
    if (submitField !== undefined) updatedData[field] = submitField;
  });

  return updatedData;
};

const getChangedFields = (
  arrayOfFields: EntityFormField<any>[],
  initialData: Record<string, any>,
  submitData: Record<string, any>,
) => {
  const updatedData: Record<string, any> = {};

  arrayOfFields.forEach((field) => {
    const submitField = getUpdatedDateField(initialData, submitData, field.name);
    if (submitField !== undefined) updatedData[field.name] = submitField;
  });

  return updatedData;
};

const isDateField = (fieldName: string) => {
  return ['pubDate', 'deadline', 'doneDate'].includes(fieldName);
};

const getUpdatedDateField = (
  initialData: Record<string, any>,
  submitData: Record<string, any>,
  fieldName: string,
): any | undefined => {
  let initialField = initialData[fieldName];
  let submitField = submitData[fieldName];

  if (isDateField(fieldName)) {
    initialField = getISOStringWithRoundSeconds(initialField);
    submitField = getISOStringWithRoundSeconds(submitField);
  }

  if (initialField !== submitField) {
    return submitField;
  }

  // No change in the field
  return undefined;
};

const showError = (formik: FormikValues, error: string | undefined | null) => {
  return !!(!!error && formik.touched);
};

export { cleanProducts, removeIdsFromObjects, getChangedFields, showError, addDefaultZeroToPrices };
