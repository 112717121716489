import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { IonPage, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { selectCurrentLanguage, selectShowMainNav } from '../store/user/userSlice';
import { useAppSelector } from '../store/hooks';
import { NavigationContainer } from './NavigationContainer';
import Menu from '../components/atoms/Menu/Menu';
import { PublicNavigator } from './PublicNavigator';
import { ConfigProvider, theme } from 'antd';

export const MainNavigator = () => {
  const showMainNav = useAppSelector(selectShowMainNav);
  const { darkAlgorithm, compactAlgorithm } = theme;
  const currentLanguage = useAppSelector(selectCurrentLanguage);
  const [darkMode, setDarkMode] = useState(false);
  const windowQuery = window.matchMedia('(prefers-color-scheme:dark)');

  const darkModeChange = useCallback((event: MediaQueryListEvent) => {
    // console.log(event.matches ? true : false);
    setDarkMode(event.matches ? true : false);
  }, []);

  useEffect(() => {
    windowQuery.addEventListener('change', darkModeChange);
    return () => {
      windowQuery.removeEventListener('change', darkModeChange);
    };
  }, [windowQuery, darkModeChange]);

  useEffect(() => {
    setDarkMode(windowQuery.matches ? true : false);
  }, []);

  const mainNav = (
    <IonSplitPane contentId="main">
      <Menu />
      <IonPage id="main">
        <IonRouterOutlet>
          <NavigationContainer />
        </IonRouterOutlet>
      </IonPage>
    </IonSplitPane>
  );

  const getNavigation = useCallback(() => {
    return showMainNav ? mainNav : <PublicNavigator />;
  }, [showMainNav]);

  return (
    <IonReactRouter>
      <ConfigProvider
        locale={currentLanguage?.antd}
        theme={{
          algorithm: darkMode ? darkAlgorithm : compactAlgorithm,
        }}>
        {getNavigation()}
      </ConfigProvider>
    </IonReactRouter>
  );
};
