import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TitleInList } from '../../../atoms/TitleInList';
import { IonInput, IonItem } from '@ionic/react';
import { disableSubmitOnEnter } from '../../../../config/utils/formUtils';
import { showError } from '../../../../config/utils/formDiffUtils';
import { TEST_IDS } from '../../../../config/utils/constants';

interface Props {
  fieldName: any;
  formik: any;
  index: number;
}

export const DocProductDiscount = ({ fieldName, formik, index }: Props) => {
  const { t } = useTranslation();
  const formName = `products[${index}][${fieldName}]`;
  const isProductsSectionDisabled = useMemo(() => !!formik?.errors?.customerId, [formik?.errors]);
  const hasError = useMemo(() => showError(formik, formik?.errors?.products?.[index]?.[fieldName]), [formik?.errors]);

  return (
    <div>
      <TitleInList index={index}>
        <div className="padding-b-16 padding-l-12">{t('SALE.DISCOUNT_PERCENTAGE')}</div>
      </TitleInList>
      <IonItem
        id={`product_${fieldName}_${index}`}
        className={`width-300px height-50px border-radius-input g-select-border cursorPointer ${
          hasError && 'b-field-border-error'
        }`}
        mode="md">
        <IonInput
          id={`${TEST_IDS.Invoice.productDiscountInput}_${index + 1}`}
          className="width-300px height-48px"
          disabled={isProductsSectionDisabled}
          mode="md"
          type="number"
          aria-label="discountePercentage"
          step="1"
          required
          min={0}
          onKeyPress={disableSubmitOnEnter}
          max={100}
          name={formName}
          placeholder={t('SALE.DISCOUNT_PERCENTAGE') + '...'}
          onIonChange={formik.handleChange}
          value={formik.values?.products[index]?.[fieldName]}
        />
      </IonItem>
    </div>
  );
};
