import * as React from 'react';
import { InvoiceInterface } from '../../../interfaces/invoice.interface';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { pdfStyles } from './pdfStyles';
import { useTranslation } from 'react-i18next';

interface Props {
  doc: InvoiceInterface;
}

export const PdfCustomerData = ({ doc }: Props) => {
  const { t } = useTranslation();

  return (
    <View style={styles.columnCenter}>
      <View style={styles.center}>
        <Text style={pdfStyles.smallTitle}>{t('PDF.CUSTOMER_DATA')}</Text>
      </View>
      <View style={[styles.container, { width: 275, marginRight: 4 }]}>
        <View style={styles.row}>
          <Text style={[pdfStyles.smallTitle, styles.placeholder, { paddingRight: 2 }]}>{t('PDF.CUSTOMER_NAME')}:</Text>
          <Text style={[pdfStyles.smallTitleRegular, pdfStyles.maxWidth200, pdfStyles.maxLines2]}>
            {doc?.customer?.companyName}
          </Text>
        </View>
        <View style={[styles.row]}>
          <Text style={[pdfStyles.smallTitle, styles.placeholder, { paddingRight: 2 }]}>
            {t('PDF.CUSTOMER_PROFESSION')}:
          </Text>
          <Text style={[pdfStyles.smallTitleRegular, pdfStyles.maxWidth200, pdfStyles.maxLines2]}>
            {doc?.customer?.profession}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={[pdfStyles.smallTitle, styles.placeholder, { paddingRight: 2 }]}>{t('PDF.ADDRESS')}:</Text>
          <Text style={[pdfStyles.smallTitleRegular, pdfStyles.maxWidth200, pdfStyles.maxLines2]}>
            {doc?.customer?.address}, {doc.customer?.postalCode}
          </Text>
        </View>
        <View style={styles.row}>
          <View style={[styles.row, { paddingRight: 4 }]}>
            <Text style={[pdfStyles.smallTitle, { paddingRight: 2 }]}>{t('PDF.PHONE_NUMBER_FULL')}:</Text>
            <Text style={pdfStyles.smallTitleRegular}>{doc?.customer?.phoneNumber}</Text>
          </View>
          <View style={styles.row}>
            <Text style={[pdfStyles.smallTitle, { paddingRight: 2 }]}>{t('PDF.TOWN')}:</Text>
            <Text style={pdfStyles.smallTitleRegular}>{doc?.customer?.city}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={[styles.row, { paddingRight: 2 }]}>
            <Text style={[pdfStyles.smallTitle, { paddingRight: 2 }]}>{t('PDF.VAT_NUMBER')}:</Text>
            <Text style={pdfStyles.smallTitleRegular}>{doc?.customer?.vatNumber}</Text>
          </View>
          <View style={styles.row}>
            <Text style={[pdfStyles.smallTitle, { paddingRight: 2 }]}>{t('PDF.DOY')}:</Text>
            <Text style={pdfStyles.smallTitleRegular}>{doc?.customer?.doy?.name}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  start: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  columnCenter: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  container: {
    borderWidth: 1,
    borderRadius: 6,
    flexDirection: 'column',
    padding: 2,
  },
  placeholder: {
    width: 60,
  },
});
