import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { MainNavigator } from './MainNavigator';
import { LoadingScreen } from '../components/atoms/LoadingScreen';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { loadLangAsync, loadUserAsync } from '../store/user/userActions';
import { selectUser } from '../store/user/userSlice';
import { getCompaniesAsync, selectCompaniesLoading } from '../store/companies/companiesSlice';

export const RootNavigator = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const companiesLoading = useAppSelector(selectCompaniesLoading);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // TODO: create hook to handle theme
  useEffect(() => {
    const init = async () => {
      // TOOD: use one loadDataAsync function
      await dispatch(loadUserAsync());
      await dispatch(loadLangAsync());
      setIsLoading(false);
    };

    init();
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(getCompaniesAsync());
    }
  }, [user]);

  const getNavigator = useCallback(() => {
    if (isLoading || companiesLoading) return <LoadingScreen />;

    return <MainNavigator />;
  }, [isLoading, companiesLoading]);

  return getNavigator();
};
