import * as React from 'react';
import { useCallback } from 'react';
import { Company } from '../../../interfaces/company.interface';

interface Props {
  company: Company; // TODO: probably remove, its not used
  logo: string | null | undefined;
}

export const DocViewCompanyLogo = ({ logo }: Props) => {
  const getLogo = useCallback(() => {
    if (logo) {
      return <img src={'data:image/png;base64,' + logo} alt="logo" style={{ maxWidth: 200, maxHeight: 66 }} />;
    }
  }, [logo]);

  return <div>{getLogo()}</div>;
};
