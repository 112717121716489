import * as React from 'react';
import { IonSpinner } from '@ionic/react';

interface Props {
  name?:
    | 'circular'
    | 'dots'
    | 'bubbles'
    | 'circles'
    | 'crescent'
    | 'lines'
    | 'lines-small'
    | 'lines-sharp'
    | 'lines-sharp-small'
    | undefined;
  size?: number;
}

export const LoadingIndicator = ({ name = 'circular', size = 32 }: Props) => {
  return (
    <div className="loader-body">
      <IonSpinner name={name} style={{ width: size, height: size }}></IonSpinner>
    </div>
  );
};
