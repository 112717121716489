import { apiRQ } from '../../api';
import { useQuery } from 'react-query';
import { VatInfoUIResponse } from '../../../interfaces/state.interface';
import { GenericResponse } from '../../../interfaces/shared.interface';
import { Doy } from '../../../interfaces/customer.interface';
import { store } from '../../../store/store';
import { setToast } from '../../../store/core/coreSlice';
import i18next from 'i18next';

export interface GetVatInfo extends GenericResponse {
  data?: VatInfoUIResponse;
  doy?: Doy;
}

const getVatInfo = async (vatNumber: string): Promise<GetVatInfo> => {
  const resp = await apiRQ.get<GetVatInfo>('/state/vat-info', { params: { vatNumber } });

  return resp.data;
};

export const useVatInfo = (vatNumber: string, customerTypeAadeCode: string, customerId: string) => {
  return useQuery<GetVatInfo, Error>(['useVatInfo', vatNumber], () => getVatInfo(vatNumber), {
    enabled: vatNumber?.length === 9 && customerTypeAadeCode === '1455' && customerId === undefined, // TODO: check aadeUsername from company to enable
    cacheTime: 10000,
    onSuccess: (data) => {
      if (!data?.data?.mainInfo?.afm) {
        store.dispatch(
          setToast({
            value: {
              show: true,
              msg: i18next.t('FORMS.ERRORS.INVALID_VAT_NUMBER'),
              isError: true,
              position: 'top',
              duration: 10000,
            },
          }),
        );
        return;
      }

      if (data?.data?.mainInfo?.stop_date) {
        store.dispatch(
          setToast({
            value: {
              show: true,
              msg: i18next.t('FORMS.ERRORS.DEACTIVATED_VAT_NUMBER'),
              isError: true,
              position: 'top',
              duration: 10000,
            },
          }),
        );
      }
    },
  });
};
