import { ProductInOrder } from '../../interfaces/product.interface';
import dayjs from 'dayjs';
import { getLocalDate } from '../../config/utils/dateHelpers';
import { InvoiceInterface } from '../../interfaces/invoice.interface';
import QRCode from 'qrcode';
import {
  DELIVERY_NOTE_DOC_TYPE_AADE_CODE,
  LIST_OF_AADE_IDS_OF_RETURN_DOC_TYPES,
  ON_ACCOUNT_PAYMENT_TYPE_AADE_CODE,
  ON_POS_PAYMENT_TYPE_AADE_CODE,
} from '../../interfaces/enumerables';
import {
  addWithDecimals,
  divideWithDecimals,
  minusWithDecimals,
  multiplyWithDecimals,
} from '../../config/utils/calcUtils';
import { getPayableAmountOfProducts } from './productCost';
import Decimal from 'decimal.js';

const MAX_PRODUCTS_IN_ONE_PAGE = 16;
const MAX_PRODUCTS_IN_LAST_PAGE = 8;

const PRODUCT_TITLE_HEIGHT = 15 + 4;
const PRODUCT_DESCRIPTION_HEIGHT = 15;
const PRODUCT_EXCEPTION_HEIGHT = 15;
const PRODUCT_DESCRIPTION_EXCEPTION_HEIGHT =
  PRODUCT_TITLE_HEIGHT + PRODUCT_DESCRIPTION_HEIGHT + PRODUCT_EXCEPTION_HEIGHT;

const MAX_HEIGHT_OF_PRODS_IN_PAGE = MAX_PRODUCTS_IN_ONE_PAGE * PRODUCT_DESCRIPTION_EXCEPTION_HEIGHT;
const MAX_HEIGHT_OF_PRODS_IN_LAST_PAGE = MAX_PRODUCTS_IN_LAST_PAGE * PRODUCT_DESCRIPTION_EXCEPTION_HEIGHT;

// console.log('MAX_HEIGHT_OF_PRODS_IN_PAGE: ', MAX_HEIGHT_OF_PRODS_IN_PAGE);
// console.log('MAX_HEIGHT_OF_PRODS_IN_LAST_PAGE: ', MAX_HEIGHT_OF_PRODS_IN_LAST_PAGE);

export const splitToPagesDynamic = (products: ProductInOrder[]) => {
  const splitted = [];
  let currentHeight = 0;
  let firstProdInPage = 0;

  for (let i = 0; i < products.length; i++) {
    const heightOfProd = getHeightOfProd(products[i]);

    // Prods fit to one page
    if (i + 1 === products.length) {
      if (currentHeight + heightOfProd <= MAX_HEIGHT_OF_PRODS_IN_LAST_PAGE) {
        currentHeight += heightOfProd;
      } else {
        splitted.push(products.slice(firstProdInPage, i));
        firstProdInPage = i;
        splitted.push(products.slice(firstProdInPage, i + 1));
        currentHeight = 0;
        continue;
      }
    }

    // Prods don't fit in last page but fit in one page
    if (currentHeight + heightOfProd <= MAX_HEIGHT_OF_PRODS_IN_PAGE) {
      currentHeight += heightOfProd;
    }

    // We need to push prods to page and change page
    if (currentHeight + heightOfProd > MAX_HEIGHT_OF_PRODS_IN_PAGE) {
      // Don't include current product in page since it doesn't fit
      splitted.push(products.slice(firstProdInPage, i));
      firstProdInPage = i;
      currentHeight = 0;
    }

    // We are in the last page
    if (i + 1 === products.length) {
      splitted.push(products.slice(firstProdInPage, i + 1));
    }
  }

  return splitted;
};

const getHeightOfProd = (product: ProductInOrder): number => {
  let heightOfProd = PRODUCT_TITLE_HEIGHT;
  if (product.description?.length) heightOfProd += PRODUCT_DESCRIPTION_HEIGHT;
  if (product.vatExceptionCategoryId) heightOfProd += PRODUCT_EXCEPTION_HEIGHT;

  return heightOfProd + 2;
};

export const getDateOfDoc = (docDate: string, sentToAadeDate: string | null) => {
  if (sentToAadeDate) {
    const postedSameDay = dayjs(sentToAadeDate).isSame(docDate, 'day');
    if (postedSameDay) {
      return dayjs(sentToAadeDate).format('DD/MM/YYYY - HH:mm');
    }
  }

  return getLocalDate(docDate);
};

export const getProdIndexInDoc = (products: ProductInOrder[], product: ProductInOrder) => {
  const findIndex = products?.findIndex((prod) => prod.id === product.id);

  return findIndex || 0;
};

export const showProductsCopyright = (doc: InvoiceInterface) => {
  return ['1.1', '1.2', '1.3', '1.12', '9.3'].includes(doc.documentType?.aadeCode);
};

export const getQRDataUrl = async (text: string) => {
  try {
    return await QRCode.toDataURL(text, { margin: 0 });
  } catch {
    // Should not fail ever
  }
};

// TODO: add extra type of docs here like (Titlos ktisis) to calculate correctly the new subtotal
export const getCustomerNewSubtotal = (doc: InvoiceInterface) => {
  const totalWithVat = new Decimal(getPayableAmountOfProducts(doc.products));

  // Check for possible tax withhold and use for new subtotal
  const taxWitholdPercentage = divideWithDecimals(doc.taxWithholdPercentage || '0', 100);
  const taxWitholdAmount = multiplyWithDecimals(taxWitholdPercentage, doc.totalValue);
  const totalToPay = totalWithVat.minus(new Decimal(taxWitholdAmount)).toFixed(2);

  if (
    ![ON_ACCOUNT_PAYMENT_TYPE_AADE_CODE, ON_POS_PAYMENT_TYPE_AADE_CODE].includes(doc.paymentType?.aadeCode) ||
    doc.documentType?.aadeCode === DELIVERY_NOTE_DOC_TYPE_AADE_CODE
  ) {
    return doc?.currentSubtotal || 0;
  }

  if (LIST_OF_AADE_IDS_OF_RETURN_DOC_TYPES.includes(doc.documentType?.aadeCode)) {
    return minusWithDecimals(doc.currentSubtotal || 0, totalToPay);
  }
  return addWithDecimals(doc.currentSubtotal || 0, totalToPay);
};
