import * as React from 'react';
import { LanguagePicker } from './components/LanguagePicker';
import { CompaniesSettings } from './components/CompaniesSettings';

export const SettingsScreen = () => {
  return (
    <div className="width-100 flex-center-center-column-nowrap">
      <div className="page">
        <div className="flex-center-center-row-wrap flex-gap-10">
          <LanguagePicker />
          <CompaniesSettings />
        </div>
      </div>
    </div>
  );
};
