import * as React from 'react';
import { useCallback } from 'react';
import { IonIcon } from '@ionic/react';
import { mailOutline } from 'ionicons/icons';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { getLocalDateTime } from '../../config/utils/dateHelpers';

interface Props {
  left?: number;
  top?: number;
  size?: number;
  date?: string;
}

export const EmailPill = ({ left = undefined, top = 28, size = 24, date }: Props) => {
  const { t } = useTranslation();

  const getTooltipTitle = useCallback(() => {
    if (date) {
      return t('SHARED.SENT_EMAIL_SUCCESS_AT', { date: getLocalDateTime(date) });
    }
    return t('SHARED.SEND_EMAIL_SUCCESS');
  }, [date]);

  return (
    <div className="flex-center-center-column-nowrap" style={{ position: 'relative' }}>
      <div className="text-shade normalText3" style={{ position: 'absolute', top, left }}>
        <Tooltip title={getTooltipTitle()}>
          <IonIcon style={{ fontSize: size }} icon={mailOutline} color="success" />
        </Tooltip>
      </div>
    </div>
  );
};
