import * as React from 'react';
import { useMemo } from 'react';
import { IonInput, IonItem } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { TitleInList } from '../../../atoms/TitleInList';
import { showError } from '../../../../config/utils/formDiffUtils';
import { disableSubmitOnEnter } from '../../../../config/utils/formUtils';
import { TEST_IDS } from '../../../../config/utils/constants';

interface Props {
  fieldName: any;
  formik: any;
  index: number;
}

export const DocProductPrice = ({ fieldName, formik, index }: Props) => {
  const { t } = useTranslation();
  const formName = `products[${index}][${fieldName}]`;
  const isProductsSectionDisabled = useMemo(() => !!formik?.errors?.customerId, [formik?.errors]);
  const hasError = useMemo(() => showError(formik, formik?.errors?.products?.[index]?.price), [formik?.errors]);

  return (
    <div>
      <TitleInList index={index}>
        <div className="padding-b-16 padding-l-12">{t('SALE.PRICE')} *</div>
      </TitleInList>
      <IonItem
        id={`product_${fieldName}_${index}`}
        className={`width-300px height-50px border-radius-input g-select-border cursorPointer ${
          hasError && 'b-field-border-error'
        }`}
        mode="md">
        <IonInput
          id={`${TEST_IDS.Invoice.productPriceInput}_${index + 1}`}
          className="height-48px"
          disabled={isProductsSectionDisabled}
          mode="md"
          type="number"
          aria-label="price"
          onKeyPress={disableSubmitOnEnter}
          step="0.01"
          min={0}
          name={formName}
          placeholder={t('SALE.PRICE') + '...'}
          onIonChange={formik.handleChange}
          value={formik.values?.products[index]?.[fieldName]}
        />
      </IonItem>
    </div>
  );
};
