import * as React from 'react';
import { Tooltip } from 'antd';
import i18next from 'i18next';
import { IonIcon } from '@ionic/react';
import { informationCircle } from 'ionicons/icons';

interface Props {
  value: string;
}

export const TooltipInInput = ({ value }: Props) => {
  return (
    <div className="b-tooltip">
      <Tooltip title={i18next.t('TOOLTIPS.' + value)}>
        <IonIcon icon={informationCircle} color="dark" style={{ fontSize: 24 }}></IonIcon>
      </Tooltip>
    </div>
  );
};
