import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { pdfStyles } from '../pdfStyles';
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductInOrder } from '../../../../interfaces/product.interface';

interface Props {
  product: ProductInOrder;
  index: number;
  docHasVatExceptionCategory: boolean;
}

export const PdfProduct = ({ product, index, docHasVatExceptionCategory }: Props) => {
  const { t } = useTranslation();

  const getVatPercentage = useCallback(() => {
    if (docHasVatExceptionCategory) return 0;

    return product?.vatPercentage;
  }, [docHasVatExceptionCategory, product?.vatPercentage]);

  const getVatExceptionCategory = useCallback(() => {
    if (product?.vatExceptionCategory) {
      return (
        <Text style={[pdfStyles.smallTitleRegular, styles.section, pdfStyles.maxLines1]}>
          {t(`VAT_EXCEPTION_CAT.${product?.vatExceptionCategory.name}`)}
        </Text>
      );
    }
  }, [t, product?.vatExceptionCategory]);

  return (
    <View style={styles.container}>
      <View style={{ flex: 1, flexDirection: 'column', paddingLeft: 4 }}>
        <View style={styles.indexSection}>
          <Text style={pdfStyles.smallTitleRegular}>{index + 1}.</Text>
        </View>
        <Text style={[pdfStyles.smallTitle, styles.section, pdfStyles.maxLines1]}>{product?.name}</Text>
        <Text style={[pdfStyles.smallTitleRegular, styles.section, pdfStyles.maxLines1]}>
          {product?.description}
        </Text>
        {getVatExceptionCategory()}
      </View>
      <Text style={[pdfStyles.smallTitleRegular, styles.section, pdfStyles.width60]}>{product?.quantity}</Text>
      <Text style={[pdfStyles.smallTitleRegular, styles.section, pdfStyles.width30]}>
        {t(`PRODUCT_UNITS_SHORT.${product?.unit}`)}
      </Text>
      <Text style={[pdfStyles.smallTitleRegular, styles.section, pdfStyles.width60]}>{product?.price}</Text>
      <Text style={[pdfStyles.smallTitleRegular, styles.section, pdfStyles.width60]}>
        {product?.discountPercentage}
      </Text>
      <Text style={[pdfStyles.smallTitleRegular, styles.section, pdfStyles.width40]}>{product?.finalCost}</Text>
      <Text style={[pdfStyles.smallTitleRegular, pdfStyles.width40, styles.section]}>{getVatPercentage()}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  indexSection: {
    position: 'absolute',
    left: -10,
  },
  container: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    paddingTop: 2,
    // paddingBottom: 2,
  },
  section: {
    // borderWidth: 1,
    // borderRightWidth: 1,
    // borderColor: 'white',
  },
  width60: {
    width: 60,
    textAlign: 'center',
  },
  width40: {
    width: 40,
    textAlign: 'center',
  },
});
