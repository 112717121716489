import * as React from 'react';
import { useCallback } from 'react';
import { Customer } from '../../../../../interfaces/customer.interface';
import { CustomerTableLineView } from './CustomerTableLineView';

interface Props {
  customers: Customer[];
}

export const CustomersReportTableListView = ({ customers }: Props) => {
  const getItems = useCallback(() => {
    return customers?.map((item, index) => {
      return (
        <CustomerTableLineView
          key={index}
          index={index}
          customer={item}
        />
      );
    });
  }, [customers]);

  return <div className="flex-start-start-column-nowrap padding-2 width-100">{getItems()}</div>;
};
