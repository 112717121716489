import * as React from 'react';
import { useCallback } from 'react';
import { EntityFormField } from '../../interfaces/forms.inteface';
import { TooltipInInput } from './TooltipInInput';
import i18next from 'i18next';
import { IonToggle } from '@ionic/react';

interface Props {
  field: EntityFormField<any>;
  formik: any;
}

export const BToggle = ({ field, formik }: Props) => {
  const formValue = formik.values?.[field.name];

  const getTooltip = useCallback(() => {
    if (field.tooltip) {
      return <TooltipInInput value={field.tooltip} />;
    }
  }, [field.tooltip]);

  return (
    <div className="width-300px margin-t-38">
      <div className="g-toggle-container height-50px b-field-border border-radius-input positionRelative">
        {getTooltip()}
        <div className="sc-ion-label-md-s normalText4 border-radius-24">{i18next.t(`FORMS.${field.label}`)}</div>
        <IonToggle
          disabled={field.disabled}
          name={field.name}
          mode="ios"
          checked={!!formValue}
          className="border-radius-24"
          value={formValue}
          onClick={() => formik.setFieldValue(field.name, !formValue)}></IonToggle>
      </div>
    </div>
  );
};
