import { IonContent, IonImg } from '@ionic/react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { usePublicMoneyCollection } from '../../api/hooks/sales/usePublicMoneyCollection';
import { useCompanyLogo } from '../../api/hooks/useCompanyLogo';
import { Loading } from '../../components/atoms/Loading';
import { ViewField } from '../../components/atoms/ViewField';
import { ViewTextAreaField } from '../../components/atoms/ViewTextAreaField';
import { MoneyCollectionPdf } from '../../components/molecules/pdf/moneyCollection/MoneyCollectionPdf';
import { pdfStyles } from '../../components/molecules/pdf/pdfStyles';
import { getLocalDate } from '../../config/utils/dateHelpers';
import { CURRENCY, TRADER_TYPE } from '../../interfaces/enumerables';
import logo from '../../theme/assets/logo.png';

export const PublicMoneyCollectionScreen = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { data, isFetched, isError, isLoading } = usePublicMoneyCollection(id);
  const { data: logoData, isFetched: isFetchedLogo } = useCompanyLogo(data?.data?.company?.logoUrl || '');
  const currency = useMemo(() => data?.data?.customer?.currency || 'EURO', [data?.data?.customer?.currency]);
  const currencySymbol = useMemo(() => CURRENCY?.[currency], [currency]);

  const getLogo = useCallback(() => {
    return (
      <div className="flex-center-center-column-nowrap">
        <IonImg src={logo} style={styles.logo} />
      </div>
    );
  }, []);

  const getError = useCallback(() => {
    if ((!data?.success && data?.errorCode) || isError) {
      return (
        <div className="normalText6 max-width-460px text-center padding-t-100">
          {t('API_ERRORS.UNABLE_TO_GET_PUBLIC_DOC')}
        </div>
      );
    }
  }, [data?.errorCode, data?.success, isError, t]);

  const downloadDoc = useCallback(() => {
    if (isFetched && data?.success && data?.data?.id && data?.data?.company && isFetchedLogo) {
      const doc = data?.data;
      const fileName = `billease-${doc?.customer?.companyName}-${doc.date}.pdf`;

      return (
        <div className="padding-b-10">
          <PDFDownloadLink
            document={<MoneyCollectionPdf company={doc.company} doc={doc} logo={logoData} currency={currencySymbol} />}
            fileName={fileName}
            style={pdfStyles.ionButtonOutline}>
            {t('FORMS.DOWNLOAD_PDF')}
          </PDFDownloadLink>
        </div>
      );
    }
  }, [isFetched, data?.success, data?.data, isFetchedLogo, logoData, currencySymbol, t]);

  const getPaymentDetails = useCallback(() => {
    if (data?.data?.paycheckBank) {
      const doc = data.data;

      return (
        <>
          <ViewField label={t('FORMS.PAY_CHECK_BANK')} value={doc?.paycheckBank || ''} />
          <ViewField label={t('FORMS.PAY_CHECK_NUMBER')} value={doc?.paycheckNumber || ''} />
          <ViewField label={t('FORMS.PAY_CHECK_DATE')} value={getLocalDate(doc?.paycheckDate || '')} />
        </>
      );
    }
  }, [data?.data, t]);

  const getMainSection = useCallback(() => {
    if (data?.success && data?.data && isFetched) {
      const doc = data.data;

      return (
        <div className="docForm margin-t-12 max-width-900 margin-l-12 margin-r-12">
          <div className="padding-t-24 padding-b-24 padding-l-8 padding-r-8">
            <div className="flex-start-center-row-wrap flex-gap-30 padding-b-30">
              <ViewField
                label="MONEY_COLLECTION.OTHER_END"
                value={t(`MONEY_COLLECTION.${TRADER_TYPE?.[doc?.traderType || 1]?.name}`)}
              />
              <ViewField label="FORMS.CUSTOMER" value={doc?.customer?.companyName || ''} />
              <ViewField label="MONEY_COLLECTION.DOC_DATE" value={getLocalDate(doc?.date)} />
              <ViewField label={t('MONEY_COLLECTION.PRICE', { currency: currencySymbol })} value={doc?.value} />
              <ViewField
                label={t('FORMS.PAYMENT_TYPE')}
                value={t(`MONEY_COLLECTION_PAYMENT_TYPES.${doc?.paymentType}`)}
              />
              {getPaymentDetails()}
            </div>
            <ViewTextAreaField label={t('FORMS.PUBLIC_NOTES_2')} value={doc?.notes || ' - '} minHeight={150} />
          </div>
        </div>
      );
    }
  }, [currencySymbol, data?.data, data?.success, getPaymentDetails, isFetched, t]);

  const getLoading = useCallback(() => {
    if (isLoading) {
      return <Loading backgroundFull />;
    }

    return null;
  }, [isLoading]);

  return (
    <IonContent scrollX={true} scrollY={true}>
      <div className="minWidth860 flex-center-center-column-nowrap">
        {getLoading()}
        {getLogo()}
        {downloadDoc()}
        {getError()}
        {getMainSection()}
      </div>
    </IonContent>
  );
};

const styles = {
  logo: {
    width: 200,
    height: 100,
  },
};
