import { GenericResponse } from '../../../interfaces/shared.interface';
import { apiRQ } from '../../api';
import { getHttpMethod } from '../../helpers';
import { useMutation } from 'react-query';
import { queryClient } from '../../APIProvider';
import { MoneyCollection } from '../../../interfaces/moneyCollection.interface';

interface CreateMoneyCollectionResponse extends GenericResponse {
  entity: MoneyCollection;
}

const updateCreateMoneyCollection = async (variables: any): Promise<CreateMoneyCollectionResponse> => {
  const isClone = variables.isClone;
  delete variables.isClone;

  const resp = await apiRQ[getHttpMethod(variables?.id, isClone)]<CreateMoneyCollectionResponse>(
    '/money-collection/',
    {
      data: variables,
    },
  );

  return resp.data;
};

export const useUpdateCreateMoneyCollection = () => {
  return useMutation<CreateMoneyCollectionResponse, Error>(updateCreateMoneyCollection, {
    onSettled: () => {
      queryClient?.invalidateQueries({ queryKey: ['useMoneyCollectionList'] });
    },
  });
};
