import { Redirect, Route, Switch } from 'react-router-dom';
import { LoginScreen } from '../screens/Login/LoginScreen';
import { PublicDocScreen } from '../screens/Public/PublicDocScreen';
import { PublicMoneyCollectionScreen } from '../screens/Public/PublicMoneyCollectionSreen';
import { PATHS } from './data';

export const PublicNavigator = () => {
  return (
    <Switch>
      <Route path={PATHS.HOME} exact={true} component={LoginScreen} />
      <Route path={`${PATHS.SHARED_DOC}/:id`} exact={true} component={PublicDocScreen} />
      <Route path={`${PATHS.SHARED_MONEY_COLLECTION}/:id`} exact={true} component={PublicMoneyCollectionScreen} />
      <Route path="*">
        <Redirect to={PATHS.HOME} />
      </Route>
    </Switch>
  );
};
