import * as React from 'react';
import { useMemo } from 'react';
import { IonItem, IonTextarea } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { TEST_IDS } from '../../../../config/utils/constants';

interface Props {
  fieldName: any;
  formik: any;
  index: number;
}

export const DocProductDescription = ({ fieldName, formik, index }: Props) => {
  const { t } = useTranslation();
  const formName = `products[${index}][${fieldName}]`;
  const isProductsSectionDisabled = useMemo(() => !!formik?.errors?.customerId, [formik?.errors]);

  return (
    <div>
      <IonItem
        id={`product_${fieldName}_${index}`}
        className="width-300px border-radius-input g-select-border cursorPointer"
        mode="md">
        <IonTextarea
          id={`${TEST_IDS.Invoice.productDescriptionInput}_${index + 1}`}
          disabled={isProductsSectionDisabled}
          mode="md"
          name={formName}
          aria-label="description"
          onIonChange={formik.handleChange}
          placeholder={t('SALE.DESCRIPTION') + '...'}
          value={formik.values?.products[index]?.[fieldName]}
          maxlength={299}
          autoGrow={true}
        />
      </IonItem>
    </div>
  );
};
