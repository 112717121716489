import { useTranslation } from 'react-i18next';
import { IonInput, IonItem } from '@ionic/react';
import { TextFieldTypes } from '@ionic/core';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { showError } from '../../../config/utils/formDiffUtils';
import { disableSubmitOnEnter } from '../../../config/utils/formUtils';

interface Props {
  field: any;
  formik: any;
}

export const CustomerSimpleInput = ({ field, formik }: Props) => {
  const { t } = useTranslation();
  const hasError = useMemo(() => showError(formik, formik?.errors?.[field.name]), [formik?.errors?.[field.name]]);

  const getRequiredSign = useCallback(() => {
    if (field.required) return ' *';
  }, [field]);

  return (
    <div>
      <div className="padding-b-16 padding-l-12">
        {t(`FORMS.${field?.label}`)}
        {getRequiredSign()}
      </div>
      <div key={field?.name} className="flex-start-center-column-nowrap positionRelative width-300px">
        <IonItem
          id={field?.name}
          className={`width-300px height-50px border-radius-input g-select-border cursorPointer ${
            hasError && 'b-field-border-error'
          }`}
          mode="md">
          <IonInput
            className="height-48px"
            mode="md"
            type={field?.type as TextFieldTypes}
            aria-label="delivery"
            name={field?.name}
            onKeyPress={disableSubmitOnEnter}
            placeholder={t(`FORMS.${field?.placeholder}`) + '...'}
            onIonChange={formik?.handleChange}
            value={formik?.values?.[field?.name]}
            maxlength={field?.maxLength}
          />
        </IonItem>
      </div>
    </div>
  );
};
