import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { DocProductSelect } from './DocProductSelect';
import { DocProductDescription } from './DocProductDescription';
import { DocProductQuantity } from './DocProductQuantity';
import { DocProductPrice } from './DocProductPrice';
import { IonFab, IonFabButton, IonIcon } from '@ionic/react';
import { closeCircleOutline } from 'ionicons/icons';
import { FieldArray, FormikProvider } from 'formik';
import { DocProductDiscount } from './DocProductDiscount';
import { DocProductFinalCost } from './DocProductFinalCost';
import { DocProductVat } from './DocProductVat';

interface Props {
  index: number;
  formik: any;
  field: any;
  isSectionDisabled?: boolean;
}

export const DocProduct = ({ formik, field, index, isSectionDisabled = false }: Props) => {
  const product = useMemo(() => formik.values.products[index], [index, formik.values?.products]);

  const getRemoveButton = () => {
    if (formik.values?.products?.length > 1) {
      return (
        <IonFab className="delete-product">
          <FormikProvider value={formik}>
            <FieldArray
              name="products"
              render={({ remove }) => (
                <IonFabButton disabled={isSectionDisabled} onClick={() => remove(index)} color="transparent">
                  <IonIcon icon={closeCircleOutline} size="large" color="danger" />
                </IonFabButton>
              )}
            />
          </FormikProvider>
        </IonFab>
      );
    }
  };

  const getProductSelect = useCallback(() => {
    return (
      <DocProductSelect
        formik={formik}
        index={index}
        focusOnMount={true}
        selected={product.productId || null}
        isDisabled={isSectionDisabled}
      />
    );
  }, [formik, index, product.productId, isSectionDisabled]);

  // TODO: add floating label to fields to check UI
  return (
    <div
      className="flex-start-center-column-nowrap flex-gap-30 mainBackground padding-t-16 padding-b-16 padding-l-16 padding-r-40"
      style={{ position: 'relative' }}>
      <div className="flex-start-start-row-wrap flex-gap-30">
        <div className="flex-start-center-column-nowrap">
          <div className="margin-b-6">{getProductSelect()}</div>
          <div>
            <DocProductDescription fieldName="description" formik={formik} index={index} />
          </div>
        </div>
        <DocProductQuantity fieldName="quantity" formik={formik} index={index} />
        <DocProductPrice fieldName="price" formik={formik} index={index} />
        <DocProductDiscount fieldName="discountPercentage" formik={formik} index={index} />
      </div>
      <div className="flex-start-start-row-wrap flex-gap-12">
        <DocProductVat formik={formik} index={index} />
        <DocProductFinalCost formik={formik} index={index} />
      </div>
      {getRemoveButton()}
    </div>
  );
};
