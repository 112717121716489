import * as React from 'react';
import i18next from 'i18next';

interface Props {
  label: string;
  value: string;
  actionElement?: React.ReactNode;
}

export const ViewField = ({ label, value, actionElement }: Props) => {
  return (
    <div className="width-250px">
      <div className="positionRelative b-field-border border-radius-input padding-6">
        <div className="sc-ion-label-md-s normalText4 border-radius-24">{i18next.t(label)}</div>
        <div className="padding-t-6 mediumText5 padding-r-10 width-250px">{value}</div>
        <div className="positionAbsolute viewFieldActionButton">
          {actionElement}
        </div>
      </div>
    </div>
  );
};
