import * as React from 'react';
import { useCallback } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { pdfStyles } from './pdfStyles';
import { Bank } from '../../../interfaces/company.interface';
import { useTranslation } from 'react-i18next';

interface Props {
  bankDetails: Bank[];
}

export const PdfBankDetails = ({ bankDetails }: Props) => {
  const { t } = useTranslation();

  const getSection = useCallback(() => {
    return bankDetails?.map?.((bank, index) => {
      return (
        <View style={[pdfStyles.simpleBorder, pdfStyles.maxWidth160]} key={index}>
          <Text style={pdfStyles.smallestTitle}>{`${t('PDF.BANK_NAME')} - ${t('PDF.SORT_CODE')}`}</Text>
          <Text style={pdfStyles.smallTitleRegular}>{`${bank?.name} - ${bank?.swiftCode}`}</Text>
          <Text style={[pdfStyles.smallTitleRegular, pdfStyles.maxLines1]}>{bank?.accountHolder}</Text>
          <Text style={pdfStyles.smallTitleRegular}>IBAN: {bank?.iban}</Text>
        </View>
      );
    });
  }, [bankDetails, t]);

  return <View>{getSection()}</View>;
};
