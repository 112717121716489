import { View } from '@react-pdf/renderer';
import * as React from 'react';
import { PdfProductsTable } from './PdfProductsTable';
import { PdfProductsList } from './PdfProductsList';
import { pdfStyles } from '../pdfStyles';
import { ProductInOrder } from '../../../../interfaces/product.interface';
import { InvoiceInterface } from '../../../../interfaces/invoice.interface';

interface Props {
  doc: InvoiceInterface;
  products: ProductInOrder[];
  docHasVatExceptionCategory?: boolean;
}

export const PdfProductsSection = ({ doc, products, docHasVatExceptionCategory = false }: Props) => {
  return (
    <View style={pdfStyles.column}>
      <View>
        <PdfProductsTable />
      </View>
      <View>
        <PdfProductsList doc={doc} products={products} docHasVatExceptionCategory={docHasVatExceptionCategory} />
      </View>
    </View>
  );
};
