import { CoreSlice, CoreState, CoreToast } from './coreSlice.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SetBooleanPayload, SetCoreToastPayload, SetStringNullPayload } from '../../interfaces/store.interface';

const initialState: CoreState = {
  loading: false,
  toast: { show: false, header: '', msg: '', isError: true, duration: 0, position: 'bottom' },
  alertIsOpen: false,
  btnLoading: false,
  serverError: null,
};

export const coreSlice = createSlice({
  name: 'core',
  initialState,
  reducers: {
    setCoreLoading: (state: CoreState, action: PayloadAction<SetBooleanPayload>): void => {
      state.loading = action.payload.value;
    },
    setToast: (state: CoreState, action: PayloadAction<SetCoreToastPayload>): void => {
      state.toast = action.payload.value;
    },
    setAlertIsOpen: (state: CoreState, action: PayloadAction<SetBooleanPayload>): void => {
      state.alertIsOpen = action.payload.value;
    },
    setBtnLoading: (state: CoreState, action: PayloadAction<SetBooleanPayload>): void => {
      state.btnLoading = action.payload.value;
    },
    setServerError: (state: CoreState, action: PayloadAction<SetStringNullPayload>): void => {
      state.serverError = action.payload.value;
    },
    resetError: (state: CoreState): void => {
      state.toast = initialState.toast;
    },
    resetCore: (): CoreState => initialState,
  },
});

//////////////////////////////// USER ASYNC ACTIONS ////////////////////////////////

//////////////////////////////// ACTIONS ////////////////////////////////
export const { setAlertIsOpen, setCoreLoading, setToast, setBtnLoading, setServerError, resetCore, resetError } =
  coreSlice.actions;

//////////////////////////////// SELECTORS ////////////////////////////////
export const selectCoreLoading = (state: CoreSlice): boolean => state.core.loading;
export const selectCoreToast = (state: CoreSlice): CoreToast => state.core.toast;
export const selectCoreServerError = (state: CoreSlice): string | null => state.core.serverError;
export const selectBtnLoading = (state: CoreSlice): boolean => state.core.btnLoading;

export default coreSlice.reducer;
