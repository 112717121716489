import * as React from 'react';
import { useCallback, useDeferredValue, useEffect, useMemo, useRef, useState } from 'react';
import { IonItem } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { EntityFormField } from '../../interfaces/forms.inteface';
import { FormikValues } from 'formik/dist/types';
import { UseQueryResult } from 'react-query';
import { Error, GetEntities } from '../../interfaces/shared.interface';
import { Select } from 'antd';
import { showError } from '../../config/utils/formDiffUtils';
import { TEST_IDS_TYPE } from '../../config/utils/constants';

interface Props {
  field: EntityFormField<any>;
  useHook: (
    page: number,
    orderBy: string | undefined,
    order: string | undefined,
    search: string,
  ) => UseQueryResult<GetEntities<any>, Error>;
  formik: FormikValues;
  focusOnMount?: boolean;
  testId?: TEST_IDS_TYPE;
  actionElement?: React.ReactNode;
}

export const BSelectSearch = ({
  field,
  useHook,
  formik,
  focusOnMount,
  testId = 'bSelectSearch',
  actionElement,
}: Props) => {
  const labelStyle = field.labelStyle || '';
  const heightStyle = field.heightLarge ? 'height-64px' : '';
  const selectRef = useRef<any>(null);
  const { t } = useTranslation();
  const [search, setSearch] = useState(
    formik.values?.[field?.name]?.object?.name ||
      formik.values?.[field?.name]?.[field?.fieldValue || 'name'] ||
      formik.values?.[field?.name],
  );
  const deferredValue = useDeferredValue(search);
  const { data, isLoading } = useHook(1, 'id', 'ASC', deferredValue || '');
  const formValue = formik?.values?.[field.name]?.id || formik?.values?.[field.name];
  const hasError = useMemo(
    () => showError(formik, formik?.errors?.[field.name]),
    [formik?.errors?.[field.name], formik.touched],
  );
  const fieldToSearch = useMemo(() => field.fieldToSearch || '', [field.fieldToSearch]);

  useEffect(() => {
    if (focusOnMount) {
      setTimeout(() => {
        selectRef?.current?.focus();
      }, 50);
    }
  }, [focusOnMount]);

  const getOptions = useCallback(() => {
    const list = data?.data?.map((option) => {
      return {
        label: option?.name || option?.companyName || option?.lastName,
        value: option.id,
        object: option,
      };
    });

    return list || [];
  }, [data]);

  const onChange = (value: string, option: any) => {
    formik.setFieldValue(field?.name, option?.object || null);
  };

  const onSearch = (value: string) => {
    setSearch(value);
  };

  const filterOption = useCallback(
    (input: string, option?: { label: string; value: string; object: Record<string, any> }) => {
      if (fieldToSearch) {
        return (
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) ||
          (option?.object?.[fieldToSearch] ?? '').toLowerCase().includes(input.toLowerCase())
        );
      }
      return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    },
    [fieldToSearch],
  );

  const geTitle = useCallback(() => {
    let mainBody = t(`FORMS.${field.label}`);
    if (field.required) {
      mainBody = t(`FORMS.${field.label}`) + ' *';
    }

    return <div className={`padding-b-16 padding-l-12 ${labelStyle}`}>{mainBody}</div>;
  }, [field.required, labelStyle]);

  return (
    <div className="positionRelative">
      {geTitle()}
      <div className="positionAbsolute inputLabelActionButton">{actionElement}</div>
      <IonItem
        id={field?.name}
        className={`width-300px flex-center-center-column-nowrap border-radius-input b-field-border ${heightStyle} ${
          hasError && 'b-field-border-error'
        }  ${field?.disabled && 'b-disabled-border'}`}
        mode="md">
        <Select
          id={testId}
          ref={selectRef}
          showSearch
          loading={isLoading}
          placeholder={t(`FORMS.${field?.placeholder}`) + '...'}
          optionFilterProp="children"
          className="my-ant-select normalText5"
          allowClear={field?.allowClear}
          variant="borderless"
          onChange={onChange}
          onSearch={onSearch}
          filterOption={filterOption}
          options={getOptions()}
          defaultValue={formValue}
          disabled={field.disabled}
        />
      </IonItem>
    </div>
  );
};
