import { apiRQ } from '../../api';
import { useQuery } from 'react-query';
import { Product } from '../../../interfaces/product.interface';

const getProduct = async (id: string): Promise<Product> => {
  const resp = await apiRQ.get<Product>(`/products/${id}`, { params: { editForm: true } });

  return resp.data;
};

export const useProduct = (id: string) => {
  return useQuery<Product, Error>(['getProduct', id], () => getProduct(id), {
    enabled: id !== 'create',
    cacheTime: 200,
  });
};
