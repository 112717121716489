import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IonIcon, IonItem } from '@ionic/react';
import { Select, Tooltip } from 'antd';
import i18next from 'i18next';
import { informationCircle } from 'ionicons/icons';
import { TEST_IDS, TEST_IDS_TYPE } from '../../config/utils/constants';

interface Props {
  field: any;
  formik: any;
  isRequired?: boolean;
  disabled?: boolean;
  widthClass?: string;
  testId?: TEST_IDS_TYPE;
}

export const BSelect = ({
  field,
  formik,
  isRequired,
  disabled = false,
  widthClass = 'width-300px',
  testId = TEST_IDS.Shared.bSelectSearch,
}: Props) => {
  const labelStyle = field.labelStyle || '';
  const heightStyle = field.heightLarge ? 'height-64px' : '';
  const { t } = useTranslation();
  const formValue = useMemo(
    () => formik?.values?.[field.name]?.id || formik?.values?.[field.name],
    [formik?.values?.[field.name], formik?.values?.[field.name]?.id],
  );
  const hasError = useMemo(() => formik.touched && isRequired && !formValue, [formValue, formik.touched, isRequired]);

  const onChange = (value: string, option: any) => {
    formik.setFieldValue(field.name, option);
  };

  const getOptions = useCallback(() => {
    return field?.data?.map((option: any) => {
      const labelPrefix = field?.labelPrefix?.(option) || '';
      const labelSuffix = field?.translationGroupKey
        ? t(`${field?.translationGroupKey}.${option?.name}`)
        : option?.name;

      return {
        label: labelPrefix + labelSuffix,
        value: option.id,
        object: option,
      };
    });
  }, [field?.data]);

  const isDisabled = useCallback(() => {
    // Force disable from init of form
    if (disabled) return true;

    if (field.disabledCheck) {
      return field?.disabledCheck?.(formik.values);
    }

    return false;
  }, [formik?.values, field.disabledCheck, disabled]);

  const getTooltip = useCallback(() => {
    if (field.tooltip) {
      return (
        <div className="b-tooltip">
          <Tooltip title={i18next.t('TOOLTIPS.' + field.tooltip)}>
            <IonIcon icon={informationCircle} color="dark" style={{ fontSize: 24 }}></IonIcon>
          </Tooltip>
        </div>
      );
    }
  }, [field.tooltip]);

  const getTitle = useCallback(() => {
    let mainBody = t(`FORMS.${field.label}`);
    if (field.required) {
      mainBody = t(`FORMS.${field.label}`) + ' *';
    }

    return <div className={`padding-b-6 ${labelStyle}`}>{mainBody}</div>;
  }, [field.required, labelStyle]);

  return (
    <div className="positionRelative">
      {getTitle()}
      <div key={field?.name} className={`flex-start-center-column-nowrap positionRelative ${widthClass}`}>
        {getTooltip()}
        <IonItem
          className={`${widthClass} flex-center-center-column-nowrap border-radius-input b-field-border ${heightStyle} ${
            isDisabled() && 'b-disabled-border'
          } ${hasError && 'b-field-border-error'}`}
          mode="md">
          <Select
            id={testId}
            allowClear={field.allowClear}
            placeholder={t(`FORMS.${field.placeholder}`) + '...'}
            disabled={isDisabled()}
            optionFilterProp="children"
            className="my-ant-select normalText5"
            variant="borderless"
            onChange={onChange}
            options={getOptions()}
            value={formValue?.value} // TODO: Check if something breaks at New sale form
            defaultValue={formValue}
          />
        </IonItem>
      </div>
    </div>
  );
};
