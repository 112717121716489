import { apiRQ } from '../api';
import { useQuery } from 'react-query';

export enum MONEY_COLLECTION_PAYMENT_TYPE {
  CASH = 'CASH',
  BUSINESS_BANK_ACCOUNT = 'BUSINESS_BANK_ACCOUNT',
  MONEY_CHECK = 'MONEY_CHECK',
  RETURN_MONEY_CASH = 'RETURN_MONEY_CASH',
  RETURN_MONEY_BANK = 'RETURN_MONEY_BANK',
  RETURN_MONEY_CHECK = 'RETURN_MONEY_CHECK',
  CLEARING = 'CLEARING',
}

export enum MONEY_COLLECTION_TRADER {
  CUSTOMER = 1,
  SUPPLIER = 2,
}

export const MONEY_COLLECTION_TRADER_LABEL: Record<number, string> = {
  1: 'CUSTOMER',
  2: 'SUPPLIER',
};

export interface MoneyCollectionTrader {
  id: number;
  name: string;
  description: string;
}

export interface MoneyCollectionFormData {
  paymentTypes: MONEY_COLLECTION_PAYMENT_TYPE[];
  traderTypes: MoneyCollectionTrader[];
}

const getMoneyCollectionFormData = async (): Promise<MoneyCollectionFormData> => {
  const resp = await apiRQ.get<MoneyCollectionFormData>('/form-data/money-collection');

  return resp.data;
};

export const useFormMoneyCollection = () => {
  return useQuery<MoneyCollectionFormData, Error>(['useFormMoneyCollection'], () => getMoneyCollectionFormData(), {
    cacheTime: 60000 * 60, // cache 1 hour
    staleTime: Infinity,
  });
};
