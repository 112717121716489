import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { BSelectSearch } from '../../../components/molecules/BSelectSearch';
import { DocDate } from '../../../components/molecules/docForm/DocDate';
import { useTranslation } from 'react-i18next';
import { IonButton, IonButtons, IonIcon, IonInput, IonItem } from '@ionic/react';
import { disableSubmitOnEnter } from '../../../config/utils/formUtils';
import { showError } from '../../../config/utils/formDiffUtils';
import { CURRENCY } from '../../../interfaces/enumerables';
import { BSelect } from '../../../components/molecules/BSelect';
import dayjs from 'dayjs';
import { TooltipInInput } from '../../../components/molecules/TooltipInInput';
import Decimal from 'decimal.js';
import { calculateNewSubtotalUtility, getTraderSubtotal } from '../../../config/utils/moneyCollectionUtils';
import { MONEY_COLLECTION_PAYMENT_TYPE, MONEY_COLLECTION_TRADER } from '../../../api/hooks/useFormMoneyCollection';
import { Customer } from '../../../interfaces/customer.interface';
import { setToast } from '../../../store/core/coreSlice';
import { useDispatch } from 'react-redux';
import { useMoneyCollectionCanReset } from '../../../api/hooks/moneyCollection/useMoneyCollectionCanReset';
import { BToggle } from '../../../components/molecules/BToggle';
import { arrowUp } from 'ionicons/icons';

interface Props {
  formik: any;
  traderTypeField: any;
  customerField: any;
  dateField: any;
  valueField: any;
  resetSerialNumberField: any;
}

export const MoneyCollectionHeaderForm = ({
  traderTypeField,
  customerField,
  dateField,
  valueField,
  resetSerialNumberField,
  formik,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [traderSubtotal, setTraderSubtotal] = useState(new Decimal(0));
  const [newTraderSubtotal, setNewTraderSubtotal] = useState('-');
  const hasErrorInValue = useMemo(
    () => showError(formik, formik?.errors?.[valueField.name]),
    [formik?.errors, valueField.name],
  );
  const traderTypeSelected = useMemo(
    () =>
      formik.values?.[traderTypeField.name]?.value ||
      formik.values?.[traderTypeField.name]?.id ||
      formik.values?.[traderTypeField.name],
    [formik.values?.[traderTypeField.name]],
  );
  const customerSelected = useMemo(() => formik.values?.[customerField.name], [formik.values?.[customerField.name]]);
  const priceInputValue = useMemo(() => formik.values?.[valueField.name], [formik.values?.[valueField.name]]);
  const paymentTypeSelected = useMemo(
    () => formik.values?.paymentType?.value || formik.values?.paymentType,
    [formik.values?.paymentType],
  );
  const currency = useMemo(() => customerSelected?.currency?.name || 'EURO', [customerSelected?.currency?.name]);
  const maxValue = useMemo(() => {
    if (paymentTypeSelected === MONEY_COLLECTION_PAYMENT_TYPE.CLEARING) {
      let smallSubtotal = new Decimal(customerSelected?.customerSubtotal);
      const supplierSubtotal = new Decimal(customerSelected?.supplierSubtotal);
      if (smallSubtotal.comparedTo(supplierSubtotal) > 0) {
        smallSubtotal = supplierSubtotal;
      }

      return smallSubtotal.toFixed(2);
    }

    return undefined;
  }, [paymentTypeSelected, customerSelected]);
  const { data: canReset } = useMoneyCollectionCanReset();

  useEffect(() => {
    if (!canReset) {
      formik.setFieldValue(resetSerialNumberField.name, false);
    }
  }, [resetSerialNumberField, canReset]);

  useEffect(() => {
    if (customerSelected) {
      const subtotal = getTraderSubtotal(traderTypeSelected, customerSelected);
      setTraderSubtotal(subtotal);
    }
  }, [traderTypeSelected, customerSelected?.customerSubtotal, customerSelected?.supplierSubtotal]);

  const calculateNewSubtotal = useCallback(
    (
      traderType: MONEY_COLLECTION_TRADER,
      paymentType: MONEY_COLLECTION_PAYMENT_TYPE,
      customer: Customer,
      value: number | string,
    ): Decimal => {
      return calculateNewSubtotalUtility(traderType, paymentType, customer, value);
    },
    [],
  );

  useEffect(() => {
    if (customerSelected) {
      const res = calculateNewSubtotal(
        traderTypeSelected,
        paymentTypeSelected,
        customerSelected,
        priceInputValue || 0,
      ).toFixed(2);

      setNewTraderSubtotal(res);
    }
  }, [customerSelected, traderSubtotal, paymentTypeSelected, priceInputValue, traderTypeSelected]);

  useEffect(() => {
    if (maxValue !== undefined && !valueField.disabled) {
      const maxValueDecimal = new Decimal(maxValue);
      const priceInputValueDecimal = new Decimal(priceInputValue || 0);

      if (priceInputValueDecimal.comparedTo(maxValueDecimal) > 0) {
        formik.setFieldValue('value', maxValueDecimal.toFixed(2));
        dispatch(setToast({ value: { show: true, msg: t('MONEY_COLLECTION.MAX_VALUE_ON_CLEARING'), isError: true } }));
      }
    }
  }, [maxValue, priceInputValue, valueField.disabled]);

  const isValueDisabled = useCallback(() => {
    if (valueField?.disabled) return true;
  }, [valueField?.disabled]);

  const getValueTooltip = useCallback(() => {
    if (valueField.tooltip) return <TooltipInInput value={valueField.tooltip} />;
  }, [valueField.tooltip]);

  const topUpValue = useCallback(() => {
    if (traderSubtotal.isPositive()) {
      formik.setFieldValue(valueField.name, traderSubtotal.toFixed(2));
    }
  }, [formik?.setFieldValue, traderSubtotal]);

  const getValueField = useCallback(() => {
    return (
      <div>
        <div className="padding-b-16 padding-l-12 flex-start-center-row-nowrap positionRelative">
          <div>{t('MONEY_COLLECTION.PRICE', { currency: CURRENCY[currency] })} *</div>
          <div className="positionAbsolute inputLabelActionButton">
            <IonButtons>
              <IonButton onClick={topUpValue} color="transparent">
                <IonIcon slot="icon-only" icon={arrowUp} color="tertiary" />
              </IonButton>
            </IonButtons>
          </div>
        </div>
        <div className="positionRelative">
          {getValueTooltip()}
          <IonItem
            className={`width-300px height-50px border-radius-input g-select-border cursorPointer ${
              hasErrorInValue && 'b-field-border-error'
            } ${isValueDisabled() && 'b-disabled-border'}`}
            mode="md">
            <IonInput
              className="height-48px"
              mode="md"
              type="number"
              step="0.01"
              min={0.01}
              onKeyPress={disableSubmitOnEnter}
              aria-label="value"
              name="value"
              placeholder={t('SALE.PRICE') + '...'}
              onIonChange={formik.handleChange}
              value={formik.values?.[valueField.name]}
              disabled={isValueDisabled()}
              max={maxValue}
            />
          </IonItem>
        </div>
      </div>
    );
  }, [hasErrorInValue, isValueDisabled, formik, maxValue, topUpValue]);

  const getToggle = useCallback(() => {
    if (canReset) {
      return <BToggle field={resetSerialNumberField} formik={formik} />;
    }
  }, [canReset, resetSerialNumberField, formik]);

  const getNewSubtotal = useCallback(() => {
    // `valueField` is disabled when we are on edit
    if (!valueField.disabled) {
      return (
        <div>
          <div className="padding-b-16 padding-l-12">
            {t('MONEY_COLLECTION.SUBTOTAL', { currency: CURRENCY[currency] })}
          </div>
          <IonItem
            mode="md"
            className="width-300px height-50px border-radius-input g-select-border cursorPointer b-disabled-border">
            <IonInput
              disabled
              className="height-48px"
              mode="md"
              type="text"
              aria-label="subtotal"
              value={newTraderSubtotal}
            />
          </IonItem>
        </div>
      );
    }
  }, [valueField.disabled, newTraderSubtotal, currency]);

  return (
    <div style={{ minHeight: 160 }} className="docForm">
      <div className="padding-t-24 padding-b-24 padding-l-8 padding-r-8 flex-start-center-row-wrap flex-gap-30">
        <BSelect field={traderTypeField} formik={formik} disabled={traderTypeField.disabled} />
        <BSelectSearch field={customerField} useHook={customerField.useHook} formik={formik} focusOnMount />
        <DocDate field={dateField} formik={formik} title={`${t('MONEY_COLLECTION.DOC_DATE')} *`} maxDate={dayjs()} />
        {getValueField()}
        {getNewSubtotal()}
        {getToggle()}
      </div>
    </div>
  );
};
