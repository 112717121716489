import { useQuery } from 'react-query';
import { apiRQ } from '../api';
import { store } from '../../store/store';
import { setToast } from '../../store/core/coreSlice';
import i18next from 'i18next';
import dayjs from 'dayjs';

interface PlanValidUntil {
  plan: string;
  validUntil: string;
}

interface ActivePlansResponse {
  hasPlansActive: boolean;
  activePlans?: Record<string, boolean>;
  plans?: PlanValidUntil[];
  error?: string;
}

export const getActivePlans = async (): Promise<ActivePlansResponse> => {
  const resp = await apiRQ.get<ActivePlansResponse>('/plans/current');

  return resp?.data;
};

export const usePlans = (vatNumber: string) => {
  return useQuery<ActivePlansResponse, Error>(['usePlan', vatNumber], () => getActivePlans(), {
    enabled: !!vatNumber,
    cacheTime: 60000 * 60 * 3, // 3 hours cache
    staleTime: Infinity,
    onSuccess: (data) => {
      // Customer doesn't have an active plan
      if (!data.hasPlansActive) {
        store.dispatch(
          setToast({
            value: { show: true, msg: i18next.t('PLANS.NO_ACTIVE_PLANS'), isError: true, duration: 30000 },
          }),
        );
      }

      showSubscriptionExpirationMessage(data);
    },
  });
};

const getBiggestDate = (plans: PlanValidUntil[]) => {
  let biggestDate = dayjs(plans[0]?.validUntil);

  for (let i = 1; i < plans.length; i++) {
    const helpDate = dayjs(plans[i]?.validUntil);
    if (helpDate.isAfter(biggestDate)) {
      biggestDate = helpDate;
    }
  }

  return biggestDate;
};

const showSubscriptionExpirationMessage = (data: ActivePlansResponse) => {
  if (!data?.plans?.length) return;

  const biggestDate = getBiggestDate(data?.plans || []);

  if (biggestDate) {
    const now = dayjs();
    const diff = biggestDate.diff(now, 'day');

    // No need to show message to user if plan is going to be active for more that 15 days
    if (diff > 15) return;

    let msg = 'PLANS.YOUR_PLAN_WILL_EXPIRE_IN_X_DAYS';
    if (diff < 0) msg = 'PLANS.NO_ACTIVE_PLANS';
    if (diff === 0) msg = 'PLANS.YOUR_PLAN_EXPIRES_TODAY';

    store.dispatch(
      setToast({
        value: { show: true, msg: i18next.t(msg, { days: diff }), isError: true, duration: 30000 },
      }),
    );
  }
};
