import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectSelectedCompany, setSelectedCompanyAsync } from '../../../../store/companies/companiesSlice';
import { Company } from '../../../../interfaces/company.interface';

interface Props {
  company: Company;
}

export const CompanyCard = ({ company }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedCompany = useAppSelector(selectSelectedCompany);

  const isCompanySelected = useMemo(() => company?.id === selectedCompany?.id, [selectedCompany]);

  const selectCompany = useCallback(() => {
    dispatch(setSelectedCompanyAsync(company));
  }, []);

  const getSelectedCompanyClass = useCallback(() => {
    if (isCompanySelected) return 'selectedCompany';

    return '';
  }, [isCompanySelected]);

  return (
    <div className={`flex-center-center-column-nowrap companyCard ${getSelectedCompanyClass()}`} onClick={selectCompany}>
      <div className="boldText5 margin-b-12">{company.title}</div>
      <div className="mediumText4 margin-b-4">
        {t('SHARED.VAT_NUMBER')}: {company.vatNumber}
      </div>
      <div className="mediumText4">
        {company.owner?.firstName} {company.owner?.lastName}
      </div>
    </div>
  );
};
