import * as React from 'react';
import { useCallback } from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { selectSelectedCompany } from '../../../../store/companies/companiesSlice';
import { useTranslation } from 'react-i18next';
import { useCompany } from '../../../../api/hooks/useCompanies';
import { getLocalDate } from '../../../../config/utils/dateHelpers';
import { CompanyLogo } from './CompanyLogo';

export const CompanyDetails = () => {
  const { t } = useTranslation();
  const selectedCompany = useAppSelector(selectSelectedCompany);
  const { data, isSuccess } = useCompany(selectedCompany?.id || '');

  const getBankDetails = useCallback(() => {
    if (data?.bankDetails?.length) {
      return (
        <div className="margin-t-20 flex-start-start-column-nowrap">
          <div className="padding-b-16 boldText6">{t('SETTINGS.BANKS')}</div>
          <div className="flex-start-center-row-wrap flex-gap-20 border-1-rad-10 padding-10">
            {data?.bankDetails?.map?.((bank, index) => {
              return (
                <div key={index}>
                  <div className="boldText5">{`${t('PDF.BANK_NAME')} - ${t('PDF.SORT_CODE')}`}</div>
                  <div className="normalText5">{`${bank?.name} - ${bank?.swiftCode}`}</div>
                  <div className="normalText5">{bank?.accountHolder}</div>
                  <div className="normalText5">IBAN: {bank?.iban}</div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }, [data?.bankDetails, t]);

  const getShippingDetails = useCallback(() => {
    if (data?.shipping?.length) {
      return (
        <div className="margin-t-20 flex-start-start-column-nowrap">
          <div className="padding-b-16 boldText6">{t('SETTINGS.SHIPPING')}</div>
          <div className="flex-start-center-row-wrap flex-gap-20 border-1-rad-10 padding-10">
            {data?.shipping?.map((ship, index) => {
              return (
                <div key={index} className="normalText5">
                  <b>{index + 1}.</b> {ship}
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }, [data?.bankDetails]);

  const getAadeCredentials = useCallback(() => {
    if (data?.aadeUsername) {
      return (
        <>
          <div className="normalText5">
            <b>{t('SETTINGS.AADE_USERNAME')}:</b> {data?.aadeUsername}
          </div>
          <div className="normalText5">
            <b>{t('SETTINGS.AADE_PASSWORD')}:</b> *********
          </div>
        </>
      );
    }
  }, [data]);

  const getTaxisCredentials = useCallback(() => {
    if (data?.taxisUsername) {
      return (
        <>
          <div className="normalText5">
            <b>{t('SETTINGS.TAXIS_USERNAME')}:</b> {data?.taxisUsername}
          </div>
          <div className="normalText5">
            <b>{t('SETTINGS.TAXIS_PASSWORD')}:</b> *********
          </div>
        </>
      );
    }
  }, [data]);

  const getSection = useCallback(() => {
    if (data && isSuccess) {
      return (
        <div className="flex-start-center-column-nowrap info-card max-width-900">
          <div className="flex-space-between-start-row-nowrap width100 padding-b-16">
            <div>
              <div className="boldText7 padding-b-10">{data?.title}</div>
              <div className="normalText5">
                <b>{t('SHARED.VAT_NUMBER')}:</b> {data?.vatNumber}
              </div>
            </div>
            <div>
              <CompanyLogo logoUrl={data.logoUrl} />
            </div>
          </div>
          <div className="normalText5 padding-b-20">
            <b>{t('SETTINGS.PROFESSION')}:</b> {data?.profession}
          </div>
          <div className="flex-start-center-row-wrap flex-gap-20">
            <div className="normalText5">
              <b>{t('SETTINGS.TOWN')}:</b> {data?.city}
            </div>
            <div className="normalText5">
              <b>{t('SETTINGS.ADDRESS')}:</b> {data?.address}
            </div>
            <div className="normalText5">
              <b>{t('SETTINGS.POSTAL_CODE')}:</b> {data?.postalCode}
            </div>
            <div className="normalText5">
              <b>{t('FORMS.DOY')}:</b> ({data?.doy?.aadeCode}) {data?.doy?.name}
            </div>
            <div className="normalText5">
              <b>{t('FORMS.EMAIL')}:</b> {data?.email}
            </div>
            <div className="normalText5">
              <b>{t('SETTINGS.WEBSITE')}:</b> {data?.websiteUrl}
            </div>
            <div className="normalText5">
              <b>{t('SETTINGS.PHONE_NUMBER')}:</b> {data?.phoneNumber}
            </div>
            <div className="normalText5">
              <b>{t('SETTINGS.GEMI_NUMBER')}:</b> {data?.gemiInfo}
            </div>
            <div className="normalText5">
              <b>{t('SETTINGS.MENO_NUMBER')}:</b> {data?.menoNumber}
            </div>
            <div className="normalText5">
              <b>{t('SETTINGS.EAE_NUMBER')}:</b> {data?.eaeNumber}
            </div>
            <div className="normalText5">
              <b>{t('SHARED.OWNER')}:</b> {data?.owner?.firstName} {data?.owner?.lastName}
            </div>
          </div>
          <div className="flex-start-center-row-wrap flex-gap-20 padding-t-20">{getAadeCredentials()}</div>
          <div className="flex-start-center-row-wrap flex-gap-20 padding-t-20">{getTaxisCredentials()}</div>
          {getBankDetails()}
          {getShippingDetails()}
          <div className="flex-start-center-row-wrap flex-gap-20 padding-t-20">
            <div className="normalText5">
              <b>{t('SETTINGS.CREATED_AT')}:</b> {getLocalDate(data?.createdAt)}
            </div>
            <div className="normalText5">
              <b>{t('SETTINGS.UPDATED_AT')}:</b> {getLocalDate(data?.updatedAt)}
            </div>
          </div>
        </div>
      );
    }

    return <></>;
  }, [isSuccess, data]);

  return getSection();
};
