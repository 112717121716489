import * as React from 'react';
import { ProductInOrder } from '../../../../interfaces/product.interface';
import { DocViewProductsTable } from './DocViewProductsTable';
import { DocViewProductsList } from './DocViewProductsList';

interface Props {
  products: ProductInOrder[];
  docHasVatExceptionCategory?: boolean;
}

export const DocViewProductsSection = ({ products, docHasVatExceptionCategory = false }: Props) => {
  return (
    <div className="flex-start-start-column-nowrap width100">
      <div className="width100">
        <DocViewProductsTable />
      </div>
      <div className="width100">
        <DocViewProductsList products={products} docHasVatExceptionCategory={docHasVatExceptionCategory} />
      </div>
    </div>
  );
};
