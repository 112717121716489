import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { InvoiceInterface } from '../../../interfaces/invoice.interface';
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { pdfStyles } from './pdfStyles';
import { useTranslation } from 'react-i18next';
import { getQRDataUrl } from '../../../services/utils/pdf';

interface Props {
  doc: InvoiceInterface;
}

export const PdfAadeData = ({ doc }: Props) => {
  const { t } = useTranslation();
  const qrText = doc?.aadeQr || 'https://billease.gr';
  const [qr, setQr] = useState<null | string>(null);

  useEffect(() => {
    const createQrData = async () => {
      const qrData = await getQRDataUrl(qrText);

      if (qrData) setQr(qrData);
    };

    createQrData();
  }, [qrText]);

  const getQR = useCallback(() => {
    if (qr) {
      return (
        <View style={{ height: 56, width: 56, marginRight: 10 }}>
          <Image src={qr} style={{ height: 56, width: 56, borderWidth: 1 }} />
        </View>
      );
    }
  }, [qr]);

  const getAadeData = useCallback(() => {
    if (doc?.sentToAade) {
      return (
        <View style={pdfStyles.flexStartCenterRow}>
          {getQR()}
          <View style={pdfStyles.column}>
            <View style={styles.row}>
              <Text style={[pdfStyles.smallestTitle, styles.smallPlaceholder]}>{t('PDF.AADE_ID')}</Text>
              <Text style={{ paddingRight: 6, paddingBottom: 2 }}>:</Text>
              <Text style={pdfStyles.smallestTitleRegular}>{doc?.aadeUID}</Text>
            </View>
            <View style={styles.row}>
              <Text style={[pdfStyles.smallestTitle, styles.smallPlaceholder]}>{t('PDF.AADE_MARK')}</Text>
              <Text style={{ paddingRight: 6, paddingBottom: 2 }}>:</Text>
              <Text style={pdfStyles.smallestTitleRegular}>{doc?.mark}</Text>
            </View>
          </View>
        </View>
      );
    }
  }, [doc?.sentToAade, getQR]);

  const getSection = useCallback(() => {
    return (
      <View style={[styles.center, { borderWidth: 0 }]}>
        <View style={styles.minWidthAade}>{getAadeData()}</View>
        <View style={[pdfStyles.row, { paddingRight: 60, paddingBottom: 20, paddingLeft: 40 }]}>
          <View style={{ paddingRight: 100 }}>
            <Text style={[pdfStyles.smallTitle, styles.placeholder]}>{t('PDF.PUBLISHER')}</Text>
          </View>
          <View>
            <Text style={[pdfStyles.smallTitle, styles.placeholder]}>{t('PDF.RECEIVER')}</Text>
          </View>
        </View>
      </View>
    );
  }, [getAadeData]);

  return getSection();
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  center: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  smallPlaceholder: {
    width: 26,
  },
  placeholder: {
    width: 60,
  },
  minWidthAade: {
    minWidth: 260,
  },
});
