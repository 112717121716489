export enum SORT_DIRECTION {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type SORT_BY_TYPE = SORT_BY_PRODUCTS | SORT_BY_SALES;

export const CURRENCY: Record<string, string> = {
  EURO: '€',
  UK_POUNDS: '£',
  USA_DOLLAR: '$',
};

export enum SORT_BY_PRODUCTS {
  NAME = 'name',
  INTERNAL_ID = 'internalId',
  INTERNAL_SERIAL = 'internalSerial',
  CREATED_AT = 'createdAt',
}

export enum SORT_BY_CUSTOMERS {
  COMPANY_NAME = 'companyName',
  VAT_NUMBER = 'vatNumber',
  CREATED_AT = 'createdAt',
  CUSTOMER_SUBTOTAL = 'customerSubtotal',
  SUPPLIER_SUBTOTAL = 'supplierSubtotal',
}

export enum SORT_BY_SALES {
  SERIES_NAME = 'seriesName',
  SERIAL_NUMBER = 'serialNumber',
  YEAR = 'year',
  SENT_TO_AADE = 'sentToAade',
  CREATED_AT = 'createdAt',
  CUSTOMER = 'customerId',
  PAYMENT_TYPE = 'paymentTypeId',
  DOCUMENT_TYPE = 'documentTypeId',
  TOTAL_VALUE = 'totalValue',
}

export enum SORT_BY_DELIVERY_NOTES {
  SERIES_NAME = 'seriesName',
  SERIAL_NUMBER = 'serialNumber',
  YEAR = 'year',
  SENT_TO_AADE = 'sentToAade',
  CREATED_AT = 'createdAt',
  CUSTOMER = 'customerId',
}

export enum SORT_BY_MONEY_COLLECTION {
  CREATED_AT = 'createdAt',
}

export enum SUBSCRIPTION {
  'SIMPLE_V1' = '1',
  'WAREHOUSE' = '2',
  'SUBTOTALS' = '3',
}

export const TRADER_TYPE: Record<number, any> = {
  1: { id: 1, name: 'CUSTOMER', description: '' },
  2: { id: 2, name: 'SUPPLIER', description: '' },
};

export const CURRENT_BIGGEST_SUBSCRIPTION_ID = 3;
export const LIST_OF_AADE_IDS_OF_RETURN_DOC_TYPES = ['5.1', '5.2', '5.3'];
export const ON_ACCOUNT_PAYMENT_TYPE_AADE_CODE = '5';
export const ON_POS_PAYMENT_TYPE_AADE_CODE = '7';
export const DELIVERY_NOTE_DOC_TYPE_AADE_CODE = '9.3';

export const UNPOSTABLE_AADE_DOC_TYPE_CODES = ['0.0'];

export const BILL_EASE_VAT_NUMBER = '802337269';
