import { IonButton, IonIcon, useIonAlert } from '@ionic/react';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { sendSharp, shareSocialOutline } from 'ionicons/icons';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useDeleteMoneyCollection } from '../../api/hooks/moneyCollection/useDeleteMoneyCollection';
import { useMoneyCollection } from '../../api/hooks/moneyCollection/useMoneyCollection';
import { useMutateMoneyCollectionPublicShare } from '../../api/hooks/moneyCollection/useMutateMoneyCollectionPublicShare';
import { useSendMoneyCollectionEmail } from '../../api/hooks/moneyCollection/useSendMoneyCollectionEmail';
import { useCompanyLogo } from '../../api/hooks/useCompanyLogo';
import { EmailPill } from '../../components/atoms/EmailPill';
import { Loading } from '../../components/atoms/Loading';
import { ViewField } from '../../components/atoms/ViewField';
import { ViewTextAreaField } from '../../components/atoms/ViewTextAreaField';
import { MoneyCollectionPdf } from '../../components/molecules/pdf/moneyCollection/MoneyCollectionPdf';
import { pdfStyles } from '../../components/molecules/pdf/pdfStyles';
import { ENV } from '../../config/env';
import { getLocalDate } from '../../config/utils/dateHelpers';
import { CURRENCY, SUBSCRIPTION, TRADER_TYPE } from '../../interfaces/enumerables';
import { PATHS } from '../../navigation/data';
import { isAfter24Hours } from '../../services/utils/date';
import { useHasActivePlan } from '../../services/utils/useHasActivePlan';
import { selectSelectedCompany } from '../../store/companies/companiesSlice';
import { setToast } from '../../store/core/coreSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

export const MoneyCollectionScreen = () => {
  useHasActivePlan(true, SUBSCRIPTION.SUBTOTALS);
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const [presentAlert] = useIonAlert();
  const selectedCompany = useAppSelector(selectSelectedCompany);
  const { data, isLoading } = useMoneyCollection(id);
  const { data: logoData } = useCompanyLogo(data?.company?.logoUrl || '');
  const { mutate: deleteEntity, isLoading: isLoadingDelete } = useDeleteMoneyCollection(history);
  const { isLoading: isLoadingSendEmail, mutate: sendEmail } = useSendMoneyCollectionEmail();
  const { mutate: publicShareDoc } = useMutateMoneyCollectionPublicShare();

  // TODO: populate currency of customer, for now it's fine because we only have euros
  const currency = useMemo(() => data?.customer?.currency || 'EURO', [data?.customer?.currency]);
  const currencySymbol = useMemo(() => CURRENCY?.[currency], [currency]);

  const editDoc = useCallback(() => {
    history.push(`${PATHS.MONEY_COLLECTION}/${id}`);
  }, [history, id]);

  const getLoading = useCallback(() => {
    if (isLoading || isLoadingDelete) {
      return <Loading backgroundFull />;
    }

    return null;
  }, [isLoading, isLoadingDelete]);

  const getLoadingEmail = useCallback(() => {
    if (isLoadingSendEmail) {
      return <Loading />;
    }

    return null;
  }, [isLoadingSendEmail]);

  const deleteConfirmation = useCallback(() => {
    presentAlert({
      mode: 'ios',
      header: t('FORMS.CONFIRM_DELETE_MSG'),
      buttons: [
        {
          text: t('SHARED.CANCEL'),
          role: 'cancel',
          cssClass: 'noTextTransformation',
        },
        {
          text: t('SHARED.CONFIRM'),
          role: 'confirm',
          handler: () => {
            // @ts-expect-error mutation
            deleteEntity({ id });
          },
          cssClass: 'noTextTransformation',
        },
      ],
    });
  }, [presentAlert, t, deleteEntity, id]);

  const getDeleteButton = useCallback(() => {
    return (
      <IonButton onClick={deleteConfirmation} fill="outline" className="ion-delete-button padding-r-4">
        {t('FORMS.DELETE')}
      </IonButton>
    );
  }, [deleteConfirmation, t]);

  const downloadDoc = useCallback(() => {
    if (data?.id && selectedCompany) {
      const fileName = `billease-${data?.customer?.companyName}-${data.date}.pdf`;

      return (
        <PDFDownloadLink
          document={
            <MoneyCollectionPdf company={selectedCompany} doc={data} logo={logoData} currency={currencySymbol} />
          }
          fileName={fileName}
          style={pdfStyles.ionButton}>
          {t('FORMS.DOWNLOAD_PDF')}
        </PDFDownloadLink>
      );
    }
  }, [data, selectedCompany, logoData, currencySymbol, t]);

  const shareDocPublicly = useCallback(() => {
    publicShareDoc({ entityId: id });

    const publicUrl = `${ENV.DOMAIN}share/money-collection/${id}`;
    navigator?.clipboard?.writeText(publicUrl);
    dispatch(
      setToast({ value: { show: true, msg: t('SHARED.COPIED_TO_CLIPBOARD'), isError: false, position: 'top' } }),
    );
  }, [dispatch, id, publicShareDoc, t]);

  const getShareDocButton = useCallback(
    () => (
      <IonButton onClick={shareDocPublicly} data-testid="share-button">
        {t('SHARED.SHARE')}
        <IonIcon slot="end" icon={shareSocialOutline}></IonIcon>
      </IonButton>
    ),
    [t, shareDocPublicly],
  );

  const sendEmailToCustomer = useCallback(() => {
    if (id) {
      sendEmail({ entityId: id });
    }
  }, [id, sendEmail]);

  const canSendEmail = useCallback((emailSentAt: string | null | undefined) => {
    if (!emailSentAt) return true;

    return isAfter24Hours(emailSentAt);
  }, []);

  const getSendEmailButton = useCallback(() => {
    if (data?.customer?.email && canSendEmail(data?.emailSentToCustomerAt)) {
      return (
        <IonButton onClick={sendEmailToCustomer}>
          {t('SHARED.SEND_EMAIL')}
          <IonIcon slot="end" icon={sendSharp}></IonIcon>
        </IonButton>
      );
    }
  }, [data?.customer?.email, data?.emailSentToCustomerAt, canSendEmail, sendEmailToCustomer, t]);

  const getActionButtons = useCallback(() => {
    return (
      <div className="padding-b-10 flex-space-between-start-row-wrap width-100">
        <div className="flex-start-center-row-wrap">
          <IonButton onClick={editDoc}>{t('FORMS.EDIT')}</IonButton>
          {downloadDoc()}
          {getShareDocButton()}
        </div>
        <div>
          {getDeleteButton()}
          {getSendEmailButton()}
        </div>
      </div>
    );
  }, [downloadDoc, editDoc, getDeleteButton, getSendEmailButton, getShareDocButton, t]);

  const getEmailPill = useCallback(() => {
    if (data?.emailSentToCustomerAt) {
      return <EmailPill size={16} top={-26} date={data?.emailSentToCustomerAt} />;
    }
  }, [data?.emailSentToCustomerAt]);

  const getPaymentDetails = useCallback(() => {
    if (data?.paycheckBank) {
      return (
        <>
          <ViewField label={t('FORMS.PAY_CHECK_BANK')} value={data?.paycheckBank || ''} />
          <ViewField label={t('FORMS.PAY_CHECK_NUMBER')} value={data?.paycheckNumber || ''} />
          <ViewField label={t('FORMS.PAY_CHECK_DATE')} value={getLocalDate(data?.paycheckDate || '')} />
        </>
      );
    }
  }, [data?.paycheckBank, data?.paycheckDate, data?.paycheckNumber, t]);

  const getMainSection = useCallback(() => {
    if (data && !isLoading) {
      return (
        <div className="docForm margin-t-12">
          {getEmailPill()}
          <div className="padding-t-24 padding-b-24 padding-l-8 padding-r-8">
            <div className="flex-start-center-row-wrap flex-gap-30 padding-b-30">
              <ViewField
                label="MONEY_COLLECTION.OTHER_END"
                value={t(`MONEY_COLLECTION.${TRADER_TYPE?.[data.traderType || 1]?.name}`)}
              />
              <ViewField label="FORMS.CUSTOMER" value={data?.customer?.companyName || ''} />
              <ViewField label="MONEY_COLLECTION.DOC_DATE" value={getLocalDate(data?.date)} />
              <ViewField label={t('MONEY_COLLECTION.PRICE', { currency: currencySymbol })} value={data?.value} />
              <ViewField
                label={t('FORMS.PAYMENT_TYPE')}
                value={t(`MONEY_COLLECTION_PAYMENT_TYPES.${data?.paymentType}`)}
              />
              {getPaymentDetails()}
            </div>
            <ViewTextAreaField label={t('FORMS.PUBLIC_NOTES_2')} value={data.notes || ''} minHeight={150} />
          </div>
        </div>
      );
    }
  }, [currencySymbol, data, getEmailPill, getPaymentDetails, isLoading, t]);

  const getPdf = useCallback(() => {
    if (data?.id && selectedCompany) {
      return (
        <PDFViewer style={{ width: '100%', height: '1300px' }} showToolbar={true}>
          <MoneyCollectionPdf company={selectedCompany} doc={data} logo={logoData} currency={currencySymbol} />
        </PDFViewer>
      );
    }
  }, [data, selectedCompany, logoData, currencySymbol]);

  return (
    <div className="width-100 flex-center-center-column-nowrap">
      {getLoading()}
      {getLoadingEmail()}
      <div className="page flex-start-center-column-nowrap">
        {getActionButtons()}
        {getMainSection()}
        {/* {getPdf()} */}
      </div>
    </div>
  );
};
