import * as React from 'react';
import { useCallback, useDeferredValue, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useProducts } from '../../../../api/hooks/products/useProducts';
import { IonButton, IonButtons, IonIcon, IonItem } from '@ionic/react';
import { Select } from 'antd';
import { TitleInList } from '../../../atoms/TitleInList';
import { showError } from '../../../../config/utils/formDiffUtils';
import { TEST_IDS, TEST_IDS_TYPE } from '../../../../config/utils/constants';
import { PATHS } from '../../../../navigation/data';
import { add } from 'ionicons/icons';
import { useHistory } from 'react-router-dom';

interface Props {
  formik: any;
  index: number;
  isDisabled: boolean;
  selected: string | null;
  focusOnMount?: boolean;
  testId?: TEST_IDS_TYPE;
}

export const DocProductSelect = ({ formik, index, focusOnMount, selected, isDisabled }: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const selectRef = useRef<any>(null);
  const [search, setSearch] = useState(selected || '');
  const deferredValue = useDeferredValue(search);
  const { data, isLoading } = useProducts(1, 'id', 'ASC', deferredValue || '');
  const hasError = useMemo(() => showError(formik, formik?.errors?.products?.[index]?.name), [formik?.errors]);

  /**
   * This is just to set the prod id as the searched one to get correct prod from API and show correct name
   * This bug was reproduced when editing a Sale, remove an item from the top of the list
   */
  useEffect(() => {
    if (selected) {
      setSearch(selected);
    }
  }, [selected]);

  useEffect(() => {
    if (focusOnMount && !isDisabled) {
      setTimeout(() => {
        selectRef?.current?.focus();
      });
    }
  }, [focusOnMount]);

  const getOptions = useCallback(() => {
    const list = data?.data?.map((option) => {
      return {
        label: option?.name,
        value: option.id,
        object: option,
      };
    });

    return list || [];
  }, [data]);

  const onChange = useCallback(
    (value: string, option: any) => {
      formik.setFieldValue(`products[${index}].name`, option?.object?.name || '', true);
      formik.setFieldValue(`products[${index}].description`, option?.object?.description || '', true);
      formik.setFieldValue(`products[${index}].productId`, option?.object?.id || null);
      formik.setFieldValue(`products[${index}].product`, option?.object || null);

      // Get vat percentage value to set
      const vatPercentage =
        option?.object?.vatPercentage?.value > -1 ? String(option?.object?.vatPercentage?.value) : null;
      formik.setFieldValue(`products[${index}].vatPercentage`, vatPercentage);

      formik.setFieldValue(`products[${index}].unit`, option?.object?.unit?.name || null);
      formik.setFieldValue(`products[${index}].vatExceptionCategoryId`, option?.object?.vatExceptionCategoryId || null);
      formik.setFieldValue(`products[${index}].productCategoryId`, option?.object?.productCategoryId || null);
      formik.setFieldValue(`products[${index}].saleCategoryId`, option?.object?.saleCategoryId || null);
      formik.setFieldValue(`products[${index}].saleCharacterizationId`, option?.object?.saleCharacterizationId || null);
      formik.setFieldValue(`products[${index}].vatPercentageRelationId`, option?.object?.vatPercentageId || null);
      // Set `vatPercentageRelation` to use data in case the doc has reduced/zero vat in general
      formik.setFieldValue(`products[${index}].vatPercentageRelation`, option?.object?.vatPercentage || null);

      // Called here to trigger form validation and dismiss no product selected ('name' field)
      setTimeout(async () => {
        formik.setFieldValue(`products[${index}]['description]`, option?.object?.description || '');
      });
    },
    [formik],
  );

  const onSearch = (value: string) => {
    setSearch(value);
  };

  const getAddEntityActionButton = useCallback(() => {
    if (index === 0) {
      const navigateToNewForm = () => history.push(`${PATHS.PRODUCTS}/create`);

      return (
        <IonButtons>
          <IonButton onClick={navigateToNewForm} color="transparent">
            <IonIcon slot="icon-only" icon={add} color="success" />
          </IonButton>
        </IonButtons>
      );
    }
  }, [history, index]);

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <div>
      <TitleInList index={index}>
        <div className="positionRelative">
          <div className="padding-b-16 padding-l-12">{t('SALE.TITLE')} *</div>
          <div className="positionAbsolute inputLabelActionButton">{getAddEntityActionButton()}</div>
        </div>
      </TitleInList>
      <IonItem
        id={`select-product-${index}`}
        className={`width-300px height-50px border-radius-input g-select-border cursorPointer ${
          hasError && 'b-field-border-error'
        }`}
        mode="md">
        <Select
          id={`${TEST_IDS.Invoice.productNamePicker}_${index + 1}`}
          ref={selectRef}
          allowClear
          showSearch
          loading={isLoading}
          placeholder={t('SALE.SELECT_PRODUCT') + '...'}
          optionFilterProp="children"
          className="my-ant-select normalText5"
          variant="borderless"
          onChange={onChange}
          onSearch={onSearch}
          filterOption={filterOption}
          value={selected || null}
          options={getOptions()}
        />
      </IonItem>
    </div>
  );
};
