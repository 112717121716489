import * as React from 'react';

interface Props {
  index: number;
  children: JSX.Element;
}

export const TitleInList = ({ index, children }: Props) => {
  return <div>{index === 0 && children}</div>;
};
