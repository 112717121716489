import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { FormikValues } from 'formik/dist/types';
import { IonItem } from '@ionic/react';
import { Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { EntityFormField } from '../../interfaces/forms.inteface';

interface OptionInterface {
  isMine: boolean;
  label: string;
  value: string;
  isNew?: boolean;
}

interface Props {
  field: EntityFormField<any>;
  formik: FormikValues;
  options?: any[];
  testId?: string;
}

export const BSelectSearchInput = ({ field, formik, options, testId = 'BSelectSearchInput' }: Props) => {
  const { t } = useTranslation();
  const [newOption, setNewOption] = useState<OptionInterface | null>(null);
  const formValue = useMemo(
    () => formik?.values?.[field.name]?.value || formik?.values?.[field.name],
    [formik?.values?.[field.name]],
  );
  const hasError = useMemo(
    () => formik.touched && formik?.errors?.[field.name],
    [formik?.errors?.[field.name], formik.touched],
  );

  const onChange = (_: string, option: any) => {
    formik.setFieldValue(field.name, option || null);
  };

  const onSearch = (value: string) => {
    let finalVal = value;
    if (field.maxLength && finalVal?.length > field.maxLength) finalVal = finalVal.slice(0, field.maxLength);

    if (finalVal.length > 4) {
      setNewOption({
        isMine: false,
        label: finalVal,
        value: finalVal,
        isNew: true,
      });
      return;
    }
    setNewOption(null);
  };

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const getOptions = useCallback(() => {
    if (options) {
      const list = options?.map((option: any) => {
        return {
          label: option?.label,
          value: option?.value,
          object: option,
        };
      });

      if (newOption) {
        list?.push({
          label: newOption?.label,
          value: newOption?.value,
          object: newOption,
        });
      }

      return list;
    }

    const list = field?.data?.map((option: any) => {
      return {
        label: option,
        value: option,
        object: option,
      };
    });

    if (newOption) {
      list?.push({
        label: newOption?.label,
        value: newOption?.value,
        object: newOption,
      });
    }

    return list;
  }, [field?.data, options, newOption]);

  const getOptionBorder = useCallback((option: any) => {
    if (option?.data?.object?.isMine) return 'option-border-mine';
    if (option?.data?.object?.isNew) return 'option-border-new';

    return 'option-border-customer';
  }, []);

  const getTitle = useCallback(() => {
    const translation = t(`SALE.${field.label}`);
    if (field.required) return translation + ' *';

    return translation;
  }, [field.label, field.required]);

  return (
    <div>
      <div className="padding-b-16 padding-l-12">{getTitle()}</div>
      <div key={field.name} className="flex-start-center-column-nowrap positionRelative width-300px">
        <IonItem
          className={`width-300px border-radius-input g-select-border ${hasError && 'b-field-border-error'}`}
          mode="md">
          <Select
            id={testId}
            showSearch
            allowClear
            placeholder={t(`SALE.${field?.placeholder}`) + '...'}
            className="my-ant-select normalText5"
            variant="borderless"
            onSearch={onSearch}
            onChange={onChange}
            options={getOptions()}
            optionRender={(option: any) => <Space className={getOptionBorder(option)}>{option.label}</Space>}
            filterOption={filterOption}
            value={formValue || null}
            defaultValue={formValue || null}
          />
        </IonItem>
      </div>
    </div>
  );
};
