import * as React from 'react';
import { useCallback } from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { selectSelectedCompany } from '../../../../store/companies/companiesSlice';
import { useCustomersReport } from '../../../../api/hooks/customers/useCustomersReport';
import { SORT_BY_CUSTOMERS } from '../../../../interfaces/enumerables';
import { CustomersReportDownloadButton } from './CustomersReportDownloadButton';
import { PDFViewer } from '@react-pdf/renderer';
import { CustomersReportPdf } from './pdf/CustomersReportPdf';
import { CustomersReportView } from './view/CustomersReportView';

export const CustomersReport = () => {
  const selectedCompany = useAppSelector(selectSelectedCompany);
  const { data, isLoading } = useCustomersReport(SORT_BY_CUSTOMERS.COMPANY_NAME, 'ASC');

  // TODO: remove when testing is done
  const getPdf = useCallback(() => {
    if (data && selectedCompany) {
      return (
        <PDFViewer style={{ width: '100%', height: '1200px' }} showToolbar={true}>
          <CustomersReportPdf company={selectedCompany} customers={data} />
        </PDFViewer>
      );
    }
  }, [data, selectedCompany]);

  const getReport = useCallback(() => {
    if (selectedCompany && data) {
      return <CustomersReportView company={selectedCompany} customers={data} />;
    }
  }, [selectedCompany, data]);

  return (
    <div className="width-100 flex-center-start-column-nowrap">
      <div className="padding-b-10">
        <CustomersReportDownloadButton />
      </div>
      {getReport()}
      {/*{getPdf()}*/}
    </div>
  );
};
