import { apiRQ } from '../../api';
import { useQuery } from 'react-query';

const getMoneyCollectionCanReset = async (): Promise<boolean> => {
  const resp = await apiRQ.get<boolean>('/money-collection/can-reset/');

  return resp.data;
};

export const useMoneyCollectionCanReset = () => {
  return useQuery<boolean, Error>(['useMoneyCollectionCanReset'], getMoneyCollectionCanReset, {
    cacheTime: 500,
    staleTime: Infinity,
  });
};
