import * as React from 'react';
import { SaleScreenContext } from './components/SaleScreenContext';
import { SaleWrapper } from './components/SaleWrapper';

export const SaleScreen = () => {
  return (
    <SaleScreenContext>
      <SaleWrapper />
    </SaleScreenContext>
  );
};
