import { useSelector } from 'react-redux';
import { selectSelectedCompany } from '../../store/companies/companiesSlice';
import { usePlans } from '../../api/hooks/usePlans';
import { useCallback } from 'react';
import { CURRENT_BIGGEST_SUBSCRIPTION_ID, SUBSCRIPTION } from '../../interfaces/enumerables';

export const usePlanIsActive = (planId: SUBSCRIPTION) => {
  const company = useSelector(selectSelectedCompany);
  const { data, isLoading, isError } = usePlans(company?.vatNumber || '');

  const isPlanActive = useCallback(() => {
    for (let i = Number(planId); i <= CURRENT_BIGGEST_SUBSCRIPTION_ID; i++) {
      if (data?.activePlans?.[i]) return true;
    }

    return false;
  }, [data?.activePlans, planId]);

  // Something is off with the server => let user use the app
  if (isError) return true;

  return isLoading || isPlanActive();
};
