import * as React from 'react';
import { useCallback } from 'react';
import { InvoiceInterface } from '../../../interfaces/invoice.interface';
import { useTranslation } from 'react-i18next';
import { getDateOfDoc } from '../../../services/utils/pdf';

interface Props {
  doc: InvoiceInterface;
}

export const DocViewTitle = ({ doc }: Props) => {
  const { t } = useTranslation();

  const getDate = useCallback(() => {
    return getDateOfDoc(doc?.documentDate, doc?.sentToAadeAt);
  }, [doc?.documentDate, doc?.sentToAadeAt]);

  return (
    <div className="flex-space-between-start-row-nowrap">
      <div className="flex-start-start-column-nowrap width400">
        <div className="boldText1">{t('PDF.DOC_TYPE')}</div>
        <div className="normalText2">
          {t(`DOC_TYPES.${doc?.documentType?.name}`)}
        </div>
      </div>

      <div className="flex-center-center-column-nowrap width400">
        <div className="boldText1">
          {t('PDF.SERIES')} - {t('PDF.SERIAL_NUMBER')}
        </div>
        <div className="flex-center-center-row-wrap">
          <div className="normalText2">
            {doc?.seriesName || '0'} - {doc?.serialNumber || 'empty'}
          </div>
        </div>
      </div>

      <div className="flex-end-center-column-nowrap width400">
        <div className="boldText1">{t('PDF.DATE')}</div>
        <div className="normalText2">{getDate()}</div>
      </div>
    </div>
  );
};
