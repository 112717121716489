import { configureStore } from '@reduxjs/toolkit';
import { Provider as RNProvider } from 'react-redux';
import rootReducer from './index';
import React from 'react';

// export interface RootStoreInterface {
//   core: CoreState;
//   user: UserState;
//   companies: CompaniesState;
// }

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const Provider = ({ children }: { children: React.ReactNode }) => (
  <RNProvider store={store}>{children}</RNProvider>
);
