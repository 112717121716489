import { GetEntities } from '../../../interfaces/shared.interface';
import { apiRQ } from '../../api';
import { useQuery } from 'react-query';
import { InvoiceInterface } from '../../../interfaces/invoice.interface';
import { SORT_BY_SALES } from '../../../interfaces/enumerables';

const getSales = async (params: {
  page: number;
  orderBy: string;
  order: string;
  search?: string;
}): Promise<GetEntities<InvoiceInterface>> => {
  const resp = await apiRQ.get<GetEntities<InvoiceInterface>>('/invoices', { params });

  return resp.data;
};

export const useSales = (page: number, sortBy = SORT_BY_SALES.SERIAL_NUMBER, order = 'ASC', search: string) => {
  return useQuery<GetEntities<InvoiceInterface>, Error>(
    ['getSales', page, sortBy, order, search],
    () => getSales({ page, orderBy: sortBy, order, search: search }),
    {
      cacheTime: 200,
      staleTime: 200,
    },
  );
};
