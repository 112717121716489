import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { pdfStyles } from '../pdfStyles';
import * as React from 'react';

export const PdfProductsTable = () => {
  const { t } = useTranslation();

  return (
    <View style={styles.container}>
      <Text style={[pdfStyles.smallTitleRegular, styles.section, styles.center, { flex: 1, paddingLeft: 4 }]}>
        {t('PDF.PRODUCT_TITLE')}
      </Text>
      <Text style={[pdfStyles.smallTitleRegular, styles.section, styles.center, pdfStyles.width60]}>
        {t('PDF.PRODUCT_QUANTITY')}
      </Text>
      <Text style={[pdfStyles.smallTitleRegular, styles.section, styles.center, pdfStyles.width30]}>
        {t('PDF.PRODUCT_UNIT_TYPE')}
      </Text>
      <Text style={[pdfStyles.smallTitleRegular, styles.section, styles.center, pdfStyles.width60]}>
        {t('PDF.PRODUCT_UNIT_COST')}
      </Text>
      <Text style={[pdfStyles.smallTitleRegular, styles.section, styles.center, pdfStyles.width60]}>
        {t('PDF.PRODUCT_DISCOUNT')} %
      </Text>
      <Text style={[pdfStyles.smallTitleRegular, styles.section, styles.center, pdfStyles.width40]}>
        {t('PDF.PRODUCT_TOTAL_CLEAN_VALUE')}
      </Text>
      <Text style={[pdfStyles.smallTitleRegular, styles.center, pdfStyles.width40]}>
        {t('PDF.PRODUCT_VAT_PERCENTAGE')}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    backgroundColor: '#c9cace',
    padding: 2,
  },
  section: {
    borderRightWidth: 1,
    borderColor: 'white',
  },
  center: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
