import i18next from 'i18next';
import { useMutation } from 'react-query';
import { GenericResponse } from '../../../interfaces/shared.interface';
import { setToast } from '../../../store/core/coreSlice';
import { store } from '../../../store/store';
import { apiRQ } from '../../api';

interface DataDTO {
  entityId: string;
}

const publicMoneyCollectionShareService = async (data: DataDTO): Promise<GenericResponse> => {
  const resp = await apiRQ.post<GenericResponse>('/money-collection/share-doc', data);
  return resp.data;
};

export const useMutateMoneyCollectionPublicShare = () => {
  return useMutation<GenericResponse, Error, DataDTO>(publicMoneyCollectionShareService, {
    onError: () => {
      store.dispatch(
        setToast({
          value: {
            show: true,
            msg: i18next.t('SHARED.SOMETHING_WENT_WRONG'),
            position: 'top',
            override: true,
            isError: true,
          },
        }),
      );
    },
  });
};
