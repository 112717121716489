import * as React from 'react';
import { IonIcon } from '@ionic/react';
import { duplicateOutline } from 'ionicons/icons';
import { LinkTo } from '../atoms/LinkTo';

interface Props {
  data: Record<any, any>;
  path: string;
}

export const CloneEntityButton = ({ data, path }: Props) => {
  return (
    <div style={{ position: 'absolute', bottom: 10, right: 6 }}>
      <LinkTo link={`${path}/${data?.id}`} data={data}>
        <IonIcon icon={duplicateOutline} style={{ fontSize: 18 }} color="dark"></IonIcon>
      </LinkTo>
    </div>
  );
};
