import { useQuery } from 'react-query';
import { apiRQ } from '../api';
import { Company } from '../../interfaces/company.interface';
import { GenericResponse } from '../../interfaces/shared.interface';

type CompaniesResponse = Company[] & GenericResponse;

export const getCompanies = async (): Promise<CompaniesResponse> => {
  const resp = await apiRQ.get<any>('/companies');

  return resp.data || [];
};

export const useCompanies = () => {
  return useQuery<Company[], Error>(['getCompanies'], getCompanies);
};

export const getCompany = async (id: string): Promise<Company> => {
  const resp = await apiRQ.get<Company>(`/companies/${id}`);

  return resp.data || [];
};

export const useCompany = (id: string) => {
  return useQuery<Company, Error>(['getCompany', id], () => getCompany(id), {
    enabled: !!id,
  });
};
