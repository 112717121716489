import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DocProduct } from './product/DocProduct';
import { IonButton } from '@ionic/react';
import { emptyProductObject } from '../../../services/utils/data';
import { FieldArray, FormikProvider } from 'formik';
import { DocProductsCost } from './product/DocProductsCost';
import { DocTaxWithhold } from './DocTaxWithhold';
import { defaultTaxWithhold, isDocTypeCodeValidForTaxWithhold } from '../../../config/utils/salesHelper';

interface Props {
  field: any;
  formik: any;
  isSectionDisabled?: boolean;
  taxWithholdField: any;
}

export const DocProducts = ({ formik, field, isSectionDisabled = false, taxWithholdField }: Props) => {
  const { t } = useTranslation();
  const docTypeAadeCode = useMemo(
    () => formik?.values?.['documentTypeId']?.aadeCode || formik?.values?.['documentTypeId']?.object?.aadeCode,
    [formik?.values?.['documentTypeId']],
  );

  useEffect(() => {
    if (isDocTypeCodeValidForTaxWithhold(docTypeAadeCode)) {
      if (!taxWithholdField.value) {
        formik?.setFieldValue(taxWithholdField.name, defaultTaxWithhold(taxWithholdField.data)[0]);
      }
      return;
    }
    formik?.setFieldValue(taxWithholdField.name, null);
  }, [docTypeAadeCode]);

  const getProducts = useCallback(() => {
    return formik.values?.products?.map((prod: any, index: number) => (
      <DocProduct formik={formik} field={field} key={index} index={index} isSectionDisabled={isSectionDisabled} />
    ));
  }, [formik.values?.products]);

  const getTaxWithholdSection = useCallback(() => {
    if (isDocTypeCodeValidForTaxWithhold(docTypeAadeCode)) {
      return <DocTaxWithhold formik={formik} taxWithholdField={taxWithholdField} />;
    }
  }, [formik, taxWithholdField]);

  return (
    <div style={{ minHeight: 400 }} className="docForm padding-t-16 padding-b-16">
      <div className="mediumText6 margin-b-16 padding-l-16">
        {t('SALE.PRODUCTS')} / {t('SALE.SERVICES')}
      </div>
      <div className="flex-stretch-column-nowrap flex-gap-10">{getProducts()}</div>
      <div className="margin-t-20 padding-l-16 padding-r-16 flex-space-between-start-row-wrap">
        <div>
          <FormikProvider value={formik}>
            <FieldArray
              name="products"
              render={({ push }) => (
                <IonButton
                  disabled={isSectionDisabled}
                  onClick={() => push(emptyProductObject)}
                  className="button-focused">
                  {t('SALE.ADD_ROW')}
                </IonButton>
              )}
            />
          </FormikProvider>
          {getTaxWithholdSection()}
        </div>
        <DocProductsCost formik={formik} />
      </div>
    </div>
  );
};
