import * as React from 'react';

interface Props {
  data: any;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CompanyScreen = ({ data }: Props) => {
  return <></>;
};
