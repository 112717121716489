import * as React from 'react';
import { useCallback, useRef, useState } from 'react';
import { IonContent, IonIcon, IonItem, IonModal, IonPopover, IonSearchbar } from '@ionic/react';
import i18next from 'i18next';

import { arrowDownCircleOutline } from 'ionicons/icons';
import { SelectItemCard } from './SelectItemCard';
import { isDesktop } from '../../config/utils/dateHelpers';
import { Loading } from './Loading';
import { ClickablePill } from './ClickablePill';
import { EntityFormField } from '../../interfaces/forms.inteface';

interface Props {
  field: EntityFormField<any>;
  formik: any;
}

export const MultiSelectSearch = ({ field, formik }: Props) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const [search, setSearch] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { isLoading, data, isError, error } = field.useHook(1, SORT_BY_EXPENSES.ID, SORT_DIRECTION.ASC, search);

  const setValue = (item: any) => {
    const currentValue = [...(formik?.values?.[field?.name] || [])];

    const foundIndex = currentValue?.findIndex((val: any) => val.id === item.id);
    if (foundIndex === -1) currentValue.unshift(item);

    formik.setFieldValue(field?.name, [...currentValue]);
    modal.current?.dismiss();
  };

  const renderList = () => {
    if (data && data?.data?.length > 0) {
      return data.data.map((item: any) => {
        return (
          <SelectItemCard
            key={item?.id}
            id={item?.id}
            field={item?.[field.fieldValue || 'id']}
            field2={item?.[field.fieldValue2 || '']}
            onClick={() => setValue(item)}
          />
        );
      });
    }
  };

  const handleSearchChange = (ev: any) => {
    setSearch(ev?.detail?.value || '');
  };

  const removeItem = (item: any) => {
    const currVal = formik?.values?.[field?.name] || [];

    const valueToSet = currVal.filter((val: any) => val.id !== item.id);

    formik.setFieldValue(field?.name, valueToSet);
  };

  const getSelectedItems = useCallback(() => {
    const currentValue = formik?.values?.[field?.name];

    return currentValue.map((item: any) => {
      /*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/
      // @ts-ignore
      const viewValue = (item?.[field?.fieldValue] || '') + ' ' + (item?.[field?.fieldValue2] || '');
      return <ClickablePill key={item.id} value={viewValue} onClick={() => removeItem(item)} />;
    });
  }, [formik?.values?.[field?.name]]);

  const getPicker = () => {
    if (isDesktop()) {
      return (
        <IonPopover trigger={`open-picker-${field.name}`} triggerAction="click" size="cover">
          <IonContent className="ion-padding">
            <IonSearchbar
              value={search}
              debounce={200}
              placeholder={i18next.t('FILTERS.SEARCH')}
              onIonChange={handleSearchChange}
              onClick={() => modal.current?.setCurrentBreakpoint(0.75)}
            />
            <div className="list-items-itemOfList padding-b-30 list-padding-horizontal">{renderList()}</div>
            {isLoading && <Loading />}
          </IonContent>
        </IonPopover>
      );
    }

    return (
      <IonModal
        ref={modal}
        trigger={`open-picker-${field?.name}`}
        initialBreakpoint={0.25}
        breakpoints={[0, 0.25, 0.5, 0.75]}>
        <IonContent className="ion-padding">
          <IonSearchbar
            value={search}
            debounce={200}
            placeholder={i18next.t('FILTERS.SEARCH')}
            onIonChange={handleSearchChange}
            onClick={() => modal.current?.setCurrentBreakpoint(0.75)}
          />
          <div className="list-items-itemOfList padding-b-30 list-padding-horizontal">{renderList()}</div>
          {isLoading && <Loading />}
        </IonContent>
      </IonModal>
    );
  };

  return (
    <div>
      <div className="g-multi-select-header flex-end-center-row-nowrap" id={`open-picker-${field.name}`}>
        <IonIcon icon={arrowDownCircleOutline} style={{ fontSize: 20 }}></IonIcon>
      </div>
      <IonItem
        mode="md"
        className="g-select-size border-radius-input g-select-border flex-center-center-row-wrap">
        <div className="flex-start-center-column-nowrap g-select width-100 height-100 border-radius-24">
          <div className="sc-ion-label-md-s normalText3 width-100">
            {i18next.t(`FORMS.${field?.label}`)}
          </div>
          <div className="flex-start-center-row-wrap">{getSelectedItems()}</div>
        </div>
      </IonItem>
      {getPicker()}
    </div>
  );
};
