import { useTranslation } from 'react-i18next';
import { IonItem } from '@ionic/react';
import { ErrorForm } from '../../atoms/ErrorForm';
import * as React from 'react';
import { useCallback } from 'react';
import { Select } from 'antd';
import { PaymentType } from '../../../interfaces/paymentTypes.interface';

interface Props {
  field: any;
  formik: any;
}

export const DocPaymentType = ({ field, formik }: Props) => {
  const { t } = useTranslation();
  const formValue = formik?.values?.[field.name]?.id || formik?.values?.[field.name];

  const onChange = (value: string, option: any) => {
    formik.setFieldValue(field.name, option);
  };

  const getOptions = useCallback(() => {
    return field?.data?.map((option: PaymentType) => {
      return {
        label: t(`PAYMENT_TYPES.${option?.name}`),
        value: option.id,
        object: option,
      };
    });
  }, [field?.data]);

  return (
    <div>
      <div className="padding-b-16 padding-l-12">{t('SALE.PAYMENT_TYPE')} *</div>
      <div key={field?.name} className="flex-start-center-column-nowrap positionRelative width-300px">
        <IonItem className="width-300px border-radius-input g-select-border" mode="md">
          <Select
            optionFilterProp="children"
            className="my-ant-select normalText5"
            variant="borderless"
            onChange={onChange}
            options={getOptions()}
            defaultValue={formValue}
          />
        </IonItem>
        <ErrorForm>{formik?.errors?.[field?.name] && formik?.errors?.[field?.name]}</ErrorForm>
      </div>
    </div>
  );
};
