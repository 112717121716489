import * as React from 'react';
import { useTranslation } from 'react-i18next';

export const DocViewProductsTable = () => {
  const { t } = useTranslation();

  return (
    <div className="tableContainer">
      <div className="normalText1 tableTitle centerTitle padding-l-4 flex1">{t('PDF.PRODUCT_TITLE')}</div>
      <div className="normalText1 tableTitle center width80 text-center">{t('PDF.PRODUCT_QUANTITY')}</div>
      <div className="normalText1 tableTitle center width40 text-center">{t('PDF.PRODUCT_UNIT_TYPE')}</div>
      <div className="normalText1 tableTitle center width80 text-center">{t('PDF.PRODUCT_UNIT_COST')}</div>
      <div className="normalText1 tableTitle center width80 text-center">{t('PDF.PRODUCT_DISCOUNT')} %</div>
      <div className="normalText1 tableTitle center width58 text-center">{t('PDF.PRODUCT_TOTAL_CLEAN_VALUE')}</div>
      <div className="normalText1 center width60">{t('PDF.PRODUCT_VAT_PERCENTAGE')}</div>
    </div>
  );
};
