import * as React from 'react';
import { useMemo } from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { pdfStyles } from './pdfStyles';
import { useTranslation } from 'react-i18next';
import { InvoiceInterface } from '../../../interfaces/invoice.interface';
import { showProductsCopyright } from '../../../services/utils/pdf';

interface Props {
  doc: InvoiceInterface;
}

export const PdfCopyright = ({ doc }: Props) => {
  const { t } = useTranslation();
  const showProdCopyright = useMemo(() => showProductsCopyright(doc), [doc]);

  return (
    <View style={styles.bot}>
      <View style={styles.container}>
        <View>
          <Text style={pdfStyles.smallestTitle}>{t('PDF.BY_BILLEASE')}</Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <Text style={[pdfStyles.smallestTitleRegular, { paddingRight: 4 }]}>{t('PDF.COPYRIGHT_1')}</Text>
          {showProdCopyright && <Text style={pdfStyles.smallestTitleRegular}>{t('PDF.COPYRIGHT_2')}</Text>}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  bot: {
    position: 'absolute',
    bottom: -10,
    right: 0,
    width: '100%',
  },
});
