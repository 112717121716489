import * as React from 'react';
import { InvoiceInterface } from '../../../interfaces/invoice.interface';
import { useTranslation } from 'react-i18next';

interface Props {
  doc: InvoiceInterface;
}

export const DocViewCustomerData = ({ doc }: Props) => {
  const { t } = useTranslation();

  // TODO: check max width and how we are going to handle bigger texts
  // We could wrap it probably
  return (
    <div className="flex-center-start-column-nowrap">
      <div className="flex-center-center-row-wrap">
        <div className="boldText1">{t('PDF.CUSTOMER_DATA')}</div>
      </div>
      <div className="flex-center-start-column-nowrap simpleBorder">
        <div className="container">
          <div className="flex-start-center-row-nowrap">
            <div className="boldText1 padding-r-12">{t('PDF.CUSTOMER_NAME')}:</div>
            {/*TODO: 2 lines max*/}
            <div className="normalText1 truncate-2lines">{doc?.customer?.companyName}</div>
          </div>
          <div className="flex-start-center-row-nowrap">
            <div className="boldText1 padding-r-6">{t('PDF.CUSTOMER_PROFESSION')}:</div>
            <div className="normalText1 truncate-2lines">{doc?.customer?.profession}</div>
          </div>
          <div className="flex-start-center-row-nowrap">
            <div className="boldText1 padding-r-10">{t('PDF.ADDRESS')}:</div>
            <div className="normalText1 truncate-2lines">
              {doc?.customer?.address}, {doc.customer?.postalCode}
            </div>
          </div>
          <div className="flex-start-center-row-nowrap">
            <div className="flex-start-center-row-nowrap padding-r-8">
              <div className="boldText1 padding-r-6">{t('PDF.TOWN')}:</div>
              <div className="normalText1">{doc.customer.city}</div>
            </div>
            <div className="flex-start-center-row-nowrap">
              <div className="boldText1 padding-r-6">{t('PDF.PHONE_NUMBER_FULL')}:</div>
              <div className="normalText1">{doc.customer.phoneNumber}</div>
            </div>
          </div>
          <div className="flex-start-center-row-nowrap">
            <div className="flex-start-center-row-nowrap padding-r-6">
              <div className="boldText1 padding-r-6">{t('PDF.VAT_NUMBER')}:</div>
              <div className="normalText1">{doc?.customer?.vatNumber}</div>
            </div>
            <div className="flex-start-center-row-nowrap">
              <div className="boldText1 padding-r-6">{t('PDF.DOY')}:</div>
              <div className="normalText1">{doc?.customer?.doy?.name}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
