import { InvoiceInterface } from '../../../interfaces/invoice.interface';
import { useTranslation } from 'react-i18next';
import { CURRENCY } from '../../../interfaces/enumerables';
import * as React from 'react';
import { useMemo } from 'react';
import { pdfStyles } from './pdfStyles';
import { Text, View } from '@react-pdf/renderer';
import { getCustomerNewSubtotal } from '../../../services/utils/pdf';
import { DOCUMENT_TYPE_AADE_CODE } from '../../../config/utils/constants';

interface Props {
  doc: InvoiceInterface;
}

export const PdfCustomerDebt = ({ doc }: Props) => {
  const { t } = useTranslation();
  const newTotal = useMemo(() => getCustomerNewSubtotal(doc), [doc]);
  const currencySymbol = useMemo(() => CURRENCY[doc?.currency || 'EURO'], [doc?.currency]);
  const hideSection = useMemo(
    () => doc?.documentType?.aadeCode === DOCUMENT_TYPE_AADE_CODE.DELIVERY_NOTE,
    [doc?.documentType?.aadeCode],
  );

  if (hideSection || doc?.currentSubtotal === null) return null;

  return (
    <View style={[pdfStyles.column, pdfStyles.simpleBorder]}>
      <Text style={pdfStyles.smallTitle}>{t('SALE.CUSTOMER_DEBT')}</Text>
      <Text style={pdfStyles.smallTitleRegular}>
        {t('SALE.OLD_DEBT')}:
        <Text style={pdfStyles.smallTitle}>
          {doc?.currentSubtotal} {currencySymbol}
        </Text>
      </Text>
      <Text style={pdfStyles.smallTitleRegular}>
        {t('SALE.NEW_DEBT')}:{' '}
        <Text style={pdfStyles.smallTitle}>
          {newTotal} {currencySymbol}
        </Text>
      </Text>
    </View>
  );
};
