import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { BSelectSearchInput } from '../BSelectSearchInput';
import { useIsCloneOrEdit } from '../../../services/hooks/useIsCloneOrEdit';
import { TEST_IDS } from '../../../config/utils/constants';

interface Props {
  field: any;
  formik: any;
}

export const DocDeliverToPostalCode = ({ field, formik }: Props) => {
  const { originalCustomer } = useIsCloneOrEdit();
  const customerSelected = useMemo(() => formik?.values?.customerId, [formik?.values?.customerId]);
  const customerPostalCode = useMemo(() => customerSelected?.postalCode, [customerSelected?.postalCode]);

  useEffect(() => {
    if (customerPostalCode && customerSelected?.id !== originalCustomer && customerPostalCode !== field.value) {
      formik.setFieldValue(field.name, customerPostalCode);
    }
  }, [field.name, customerPostalCode, customerSelected?.id, originalCustomer, field.value]);

  const getOptions = useCallback(() => {
    if (customerPostalCode) {
      return [
        {
          isMine: true,
          label: customerPostalCode,
          value: customerPostalCode,
        },
      ];
    }

    return [];
  }, [customerPostalCode]);

  return (
    <BSelectSearchInput
      field={field}
      formik={formik}
      options={getOptions()}
      testId={TEST_IDS.Invoice.shipToPostalCodeSelect}
    />
  );
};
