import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { getPlatforms } from '@ionic/react';

export enum CAPACITOR_PLATFORM {
  WEB = 'web',
  ANDROID = 'android',
  IOS = 'ios',
}

export const platform = Capacitor.getPlatform();

export const checkIfMobile = async (): Promise<boolean> => {
  const info = await Device.getInfo();

  return ['ios', 'android'].includes(info.operatingSystem);
};

export const isDesktop = () => {
  const platforms = getPlatforms();
  return platforms.includes('desktop');
};

export const isMobile = () => {
  const platforms = getPlatforms();
  let isMobileDevice = false;

  // Is mobile
  if (platforms.includes('mobile') || platforms.includes('mobileweb') || platforms.includes('tablet')) {
    isMobileDevice = true;
  }

  // Is tablet
  if (platforms.includes('tablet')) {
    isMobileDevice = false;
  }

  return isMobileDevice;
};

export const isTablet = () => {
  const platforms = getPlatforms();

  if (platforms.includes('tablet')) return true;

  return false;
};

export const getPaginationLayoutStyle = (specificLayoutClass?: string) => {
  if (specificLayoutClass) return specificLayoutClass;

  if (isTablet()) return 'list-max-height-tablet';

  if (isMobile()) return 'list-max-height-mobile';

  return 'list-height';
};

export const getFormLayoutStyle = () => {
  if (isMobile() || isTablet()) return 'margin-b-60';

  return '';
};

export const isObjectEmpty = (data: Record<any, any>) => Object.keys(data).length === 0;

export const keyBy = (data: any[], key: string) => {
  const objectBy: Record<string, any> = {};

  for (const val of data) {
    if (val?.[key]) {
      objectBy[val[key]] = val;
    }
  }

  return objectBy;
};

export const mapToArrayWithOnlyId = (initData: any[]) => {
  const arrayToUse = [];

  for (let i = 0; i < initData.length; i++) {
    arrayToUse.push({ id: initData[i]?.id });
  }

  return arrayToUse;
};
