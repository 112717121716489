import { getPlatforms } from '@ionic/react';
import dayjs from 'dayjs';

const dateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  // hour: '2-digit',
  // minute: '2-digit',
};

const dateOptionsWithTime: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
};

export const getISOStringWithRoundSeconds = (dateString: string): string => {
  const date = new Date(dateString);
  date.setMilliseconds(0);
  date.setSeconds(0);
  return date?.toISOString();
};

export const getLocalDate = (date: string): string => {
  if (!date) return '';
  return new Date(date).toLocaleString(['el-GR'], dateOptions);
};

export const getLocalDateTime = (date: string): string => {
  if (!date) return '';
  return new Date(date).toLocaleString(['el-GR'], dateOptionsWithTime);
};

export const getFullDateTime = (date: string): string => {
  if (!date) return '';
  return new Date(date).toLocaleString(['en-GB'], dateOptionsWithTime);
};

export const isDesktop = () => {
  const platforms = getPlatforms();
  return platforms.includes('desktop');
};

export const nowDate = () => new Date().toISOString();

export const nowDateNoon = () => {
  const now = new Date();
  now.setHours(12, 0);
  return now.toISOString();
};

export const getDueDate = (date: string | null | undefined) => {
  if (!date) return nowDate();

  const dueDate = dayjs(date);
  const isDueDateInPast = dayjs().isAfter(dueDate, 'day');
  if (isDueDateInPast) return nowDate();

  return new Date(date).toISOString();
};

const getCurrentYear = () => new Date().getFullYear();

export const getFirstDayOfYearUtc = () => new Date(Date.UTC(getCurrentYear(), 0, 1, 0, 0, 0)).toISOString();
export const getLastDayOfYearUtc = () => new Date(Date.UTC(getCurrentYear(), 11, 31, 23, 59, 59)).toISOString();
