import * as React from 'react';
import i18next from 'i18next';

interface Props {
  label: string;
  value: string;
  minHeight?: number;
}

export const ViewTextAreaField = ({ label, value, minHeight = 300 }: Props) => {
  return (
    <div className="new-sale-notes" style={{ minHeight }}>
      <div
        className="positionRelative b-field-border border-radius-input padding-6 new-sale-notes"
        style={{ minHeight }}>
        <div className="sc-ion-label-md-s normalText4 border-radius-24">{i18next.t(label)}</div>
        <div className="padding-t-6 mediumText5 padding-r-10">{value}</div>
      </div>
    </div>
  );
};
