import * as React from 'react';
import { IonButton, IonInput } from '@ionic/react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import i18next from 'i18next';
import { useAppDispatch } from '../../../store/hooks';
import { loginUserAsync } from '../../../store/user/userActions';
import { ErrorForm } from '../../../components/atoms/ErrorForm';

interface ILoginForm {
  username: string;
  password: string;
}

const initialFormData: ILoginForm = { username: '', password: '' };

const validationSchema = yup.object({
  username: yup.string().required(i18next.t('FORMS.ERRORS.REQUIRED_USERNAME')),
  password: yup.string().required(i18next.t('FORMS.ERRORS.REQUIRED_PASSWORD')),
});

export const LoginForm = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: initialFormData,
    validationSchema,
    onSubmit: (values) => {
      values.username = values.username.trim();
      dispatch(loginUserAsync(values));
    },
  });

  const onKeyUp = (event: any) => {
    // Submit form on enter
    if (event.nativeEvent.key === 'Enter') {
      if (formik.isValid) {
        formik.handleSubmit();
      }
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} onKeyUp={($event) => onKeyUp($event)}>
      <div className="itemBackground" style={styles.container}>
        <div className="positionRelative padding-b-20">
          <IonInput
            className="min-width-300px"
            labelPlacement="floating"
            label={t('FORMS.USERNAME')}
            name="username"
            onIonChange={formik.handleChange}
            value={formik.values.username}></IonInput>
          <ErrorForm>{formik.errors.username && formik.touched.username && formik.errors.username}</ErrorForm>
        </div>
        <div className="positionRelative padding-b-20">
        <IonInput
          className="min-width-300px"
          labelPlacement="floating"
          label={t('FORMS.PASSWORD')}
          name="password"
          type="password"
          onIonChange={formik.handleChange}
          value={formik.values.password}
          enterkeyhint="go"></IonInput>
        <ErrorForm>{formik.errors.password && formik.touched.password && formik.errors.password}</ErrorForm>
        </div>
        <div className="margin-t-32">
          <IonButton shape="round" type="submit" disabled={!formik.isValid}>
            {t('FORMS.LOGIN')}
          </IonButton>
        </div>
      </div>
    </form>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 380,
    height: 300,
    borderRadius: 25,
  },
};
