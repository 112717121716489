import { GenericResponse } from '../../../interfaces/shared.interface';
import { useMutation } from 'react-query';
import { store } from '../../../store/store';
import { setToast } from '../../../store/core/coreSlice';
import i18next from 'i18next';
import { apiRQ } from '../../api';
import { queryClient } from '../../APIProvider';

interface DataDTO {
  entityId: string;
}

const sendEmailService = async (data: DataDTO): Promise<GenericResponse> => {
  const resp = await apiRQ.post<GenericResponse>('/invoices/send-email', data);
  return resp.data;
};

export const useSendEmailToCustomer = () => {
  return useMutation<GenericResponse, Error, DataDTO>(sendEmailService, {
    onSuccess: (data) => {
      if (data.success) {
        store.dispatch(
          setToast({
            value: { show: true, msg: i18next.t('SHARED.SEND_EMAIL_SUCCESS'), position: 'top', override: true },
          }),
        );
      }
    },
    onError: () => {
      store.dispatch(
        setToast({
          value: {
            show: true,
            msg: i18next.t('SHARED.SOMETHING_WENT_WRONG'),
            position: 'top',
            override: true,
            isError: true,
          },
        }),
      );
    },
    onSettled: () => {
      queryClient?.invalidateQueries({ queryKey: ['getSale'] });
    },
  });
};
