import * as React from 'react';
import { IonSpinner } from '@ionic/react';

interface Props {
  backgroundFull?: boolean;
}

export const Loading = ({ backgroundFull }: Props) => {
  return (
    <div className={`full-screen ${backgroundFull ? 'background-full' : ''}`}>
      <div className="loader-body">
        <IonSpinner name="circular" style={{ width: 32, height: 32 }}></IonSpinner>
      </div>
    </div>
  );
};
