import React, { createContext, Dispatch, SetStateAction, useState } from 'react';
import { InvoiceInterface } from '../../../interfaces/invoice.interface';

export interface SaleContextInterface {
  doc: InvoiceInterface | null;
  setDoc?: Dispatch<SetStateAction<InvoiceInterface | null>>;
}

export const SaleScreenContextProvider = createContext<SaleContextInterface>({
  doc: null,
});

interface Props {
  children: React.ReactNode;
}

export const SaleScreenContext = ({ children }: Props) => {
  const [doc, setDoc] = useState<InvoiceInterface | null>(null);

  return <SaleScreenContextProvider.Provider value={{ doc, setDoc }}>{children}</SaleScreenContextProvider.Provider>;
};
