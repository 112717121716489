import { Company } from '../../../interfaces/company.interface';
import { InvoiceInterface } from '../../../interfaces/invoice.interface';
import { ProductInOrder } from '../../../interfaces/product.interface';
import * as React from 'react';
import { useCallback } from 'react';
import { DocViewCompanyTitle } from './DocViewCompanyTitle';
import { DocViewTitle } from './DocViewTitle';
import { DocViewCustomerData } from './DocViewCustomerData';
import { DocViewExtraInfo } from './DocViewExtraInfo';
import { DocViewAadeData } from './DocViewAadeData';
import { DocViewNotes } from './DocViewNotes';
import { DocViewTotalCost } from './products/DocViewTotalCost';
import { DocViewProductsSection } from './products/DocViewProductsSection';
import { DocViewTotalQuantities } from './products/DocViewTotalQuantities';
import { DocViewCompanyLogo } from './DocViewCompanyLogo';
import { DocViewBankDetails } from './DocViewBankDetails';
import { DocViewCopyright } from './DocViewCopyright';
import { DocViewCustomerDebt } from './DocViewCustomerDebt';
import { BILL_EASE_VAT_NUMBER } from '../../../interfaces/enumerables';

interface Props {
  company: Company;
  doc: InvoiceInterface;
  products: ProductInOrder[];
  index: number;
  numberOfPages: number;
  logo: string | null | undefined;
}

export const DocViewPage = ({ company, doc, products, index, numberOfPages, logo }: Props) => {
  const getBankDetails = useCallback(() => {
    if (company.vatNumber === BILL_EASE_VAT_NUMBER && doc.notes?.includes('RF ')) {
      return null;
    }

    return <DocViewBankDetails bankDetails={company?.bankDetails || []} />;
  }, [doc.customer?.rfPaymentCode, company?.bankDetails]);

  const getBotSection = useCallback(() => {
    if (index + 1 === numberOfPages) {
      return (
        <div>
          <div className="flex-space-between-start-row-nowrap">
            <div className="flex-start-start-column-nowrap">
              <div className="margin-b-6">
                <DocViewTotalQuantities doc={doc} />
              </div>
              <DocViewCustomerDebt doc={doc} />
            </div>
            <div>
              {getBankDetails()}
            </div>
            <DocViewTotalCost doc={doc} />
          </div>
          <div className="notes">
            <DocViewNotes doc={doc} />
            <DocViewCopyright doc={doc} />
          </div>
        </div>
      );
    }
  }, [doc, numberOfPages, index, getBankDetails]);

  return (
    <div className="a4-container">
      <div className="page-number-container smallTitleRegular">
        {index + 1}/{numberOfPages}
      </div>
      <div className="section">
        <div className="padding-t-10">
          <div className="flex-space-between-start-row-nowrap">
            <DocViewCompanyTitle company={company} />
            <DocViewCompanyLogo company={company} logo={logo} />
          </div>
          <div className="padding-t-10">
            <DocViewTitle doc={doc} />
          </div>
          <div className="flex-start-start-row-nowrap padding-t-10">
            <div className="padding-r-8">
              <DocViewCustomerData doc={doc} />
            </div>
            <DocViewExtraInfo doc={doc} />
          </div>
          {/* TODO: Enable when feature is ready */}
          {/*<DocViewRelatedDocs doc={doc} />*/}
          <div className="padding-t-6">
            <DocViewProductsSection products={products} docHasVatExceptionCategory={!!doc.vatExceptionCategoryId} />
          </div>
        </div>
        {getBotSection()}
      </div>
      <div className="footer">
        <DocViewAadeData doc={doc} />
      </div>
    </div>
  );
};

const styles = {
  logo: {
    width: 80,
    height: 40,
  },
};
