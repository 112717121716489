import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router';
import { PATHS } from '../../navigation/data';
import { useSale } from '../../api/hooks/sales/useSale';

export const useIsCloneOrEdit = () => {
  const { id } = useParams<{ id: string }>();
  const { pathname } = useLocation();
  const isClone = useMemo(() => pathname.includes(PATHS.CLONE_SALE), [pathname]);
  const isEdit = useMemo(() => id !== undefined, [id]);
  const { data } = useSale(id);
  const originalCustomer = useMemo(() => data?.customerId, []);

  return { isCloneOrEdit: isClone || isEdit, originalCustomer };
};
