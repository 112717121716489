import { useTranslation } from 'react-i18next';
import { IonInput, IonItem } from '@ionic/react';
import { ErrorForm } from '../../atoms/ErrorForm';
import * as React from 'react';
import { useCallback } from 'react';
import { TextFieldTypes } from '@ionic/core';
import { disableSubmitOnEnter } from '../../../config/utils/formUtils';

interface Props {
  field: any;
  formik: any;
  testId?: string;
}

export const DocSimpleInput = ({ field, formik, testId = 'DocSimpleInput' }: Props) => {
  const { t } = useTranslation();

  const getTitle = useCallback(() => {
    const translation = t(`SALE.${field.label}`);
    if (field.required) return translation + ' *';

    return translation;
  }, [field.label, field.required])

  return (
    <div>
      <div className="padding-b-16 padding-l-12">{getTitle()}</div>
      <div
        key={field?.name}
        className="flex-start-center-column-nowrap positionRelative width-300px">
        <IonItem
          id={field.name}
          className="width-300px height-50px border-radius-input g-select-border cursorPointer"
          mode="md">
          <IonInput
            id={testId}
            className="height-48px"
            mode="md"
            type={field.type as TextFieldTypes}
            aria-label="delivery"
            name={field.name}
            placeholder={t(`SALE.${field.placeholder}`) + '...'}
            onIonChange={formik.handleChange}
            value={formik.values?.[field.name]}
            maxlength={field?.maxLength}
            onKeyPress={disableSubmitOnEnter}
          />
        </IonItem>
        <ErrorForm>{formik?.errors?.[field?.name] && formik?.errors?.[field?.name]}</ErrorForm>
      </div>
    </div>
  );
};
