import * as React from 'react';
import { useCallback } from 'react';
import { checkmarkCircleOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

interface Props {
  sentToAade: boolean;
}

export const SentToAadeIcon = ({ sentToAade }: Props) => {
  const getIcon = useCallback(() => {
    if (sentToAade) {
      return <IonIcon icon={checkmarkCircleOutline} style={{ fontSize: 18 }} color="success"></IonIcon>;
    }

    return <IonIcon icon={checkmarkCircleOutline} style={{ fontSize: 18 }} color="danger"></IonIcon>;
  }, [sentToAade]);

  return (
    <div className="flex-center-center-column-nowrap" style={{ position: 'relative' }}>
      <div className="text-shade normalText3" style={{ position: 'absolute', top: -18, right: -10 }}>
        {getIcon()}
      </div>
    </div>
  );
};
