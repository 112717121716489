import * as React from 'react';

export const HomeScreen = () => {
  return (
    <div className="width-100 flex-center-center-column-nowrap">
      <div className="page flex-center-center-column-nowrap">
        <div className="margin-b-16 mediumText5">Home screen</div>
        {/*<Loading />*/}
      </div>
    </div>
  );
};
