import { TextFieldTypes } from '@ionic/core';
import i18next from 'i18next';
import {
  IonContent,
  IonDatetime,
  IonIcon,
  IonInput,
  IonItem,
  IonModal,
  IonPopover,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonToggle,
} from '@ionic/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { getLocalDate, isDesktop } from './dateHelpers';
import { closeCircleOutline } from 'ionicons/icons';
import { Loading } from '../../components/atoms/Loading';
import { MultiSelectSearch } from '../../components/atoms/MultiSelectSearch';
import { SelectItemCard } from '../../components/atoms/SelectItemCard';
import { EntityFormField, INPUT_TYPE } from '../../interfaces/forms.inteface';
import { SORT_DIRECTION } from '../../interfaces/enumerables';
import { BSelect } from '../../components/molecules/BSelect';
import { TooltipInInput } from '../../components/molecules/TooltipInInput';
import { BSelectSearch } from '../../components/molecules/BSelectSearch';

export const getInitialFormData = (data: any) => {
  return data.fields.reduce((acc: Record<string, any>, field: any) => {
    acc[field.name] = field.value;
    return acc;
  }, {});
};

export const formDataToSubmit = (fields: EntityFormField<any>[], dataToSubmit: Record<string, any>) => {
  const dataBeforeCleanUp = { ...dataToSubmit };
  fields.forEach((field) => {
    // Select specific field to be sent to the server
    // console.log('=========================================');
    // console.log('field.name: ', field.name);
    // console.log('field.fieldToSubmit: ', field.fieldToSubmit);
    // console.log(
    //   'dataToSubmit?.[field.name]?.[field.fieldToSubmit]: ',
    //   dataToSubmit?.[field.name]?.[field.fieldToSubmit],
    // );

    if (field?.fieldToSubmit && dataToSubmit?.[field.name]?.[field.fieldToSubmit] !== undefined) {
      dataToSubmit[field.name] = dataToSubmit?.[field.name]?.[field.fieldToSubmit];
    }
    // console.log('1. final dataToSubmit[field.name]: ', dataToSubmit[field.name]);

    if (field?.fieldToSubmit2 && dataToSubmit?.[field.name]?.[field.fieldToSubmit2] !== undefined) {
      dataToSubmit[field.name] = dataToSubmit?.[field.name]?.[field.fieldToSubmit2];
    }
    // console.log('2. final dataToSubmit[field.name]: ', dataToSubmit[field.name]);

    // Use function map specific to the field to map form-data to be sent to the server
    if (field.fieldMap) {
      if (dataToSubmit?.[field.name]) {
        dataToSubmit[field.name] = field.fieldMap(dataToSubmit[field.name]);
      }
    }
    // console.log('3. final dataToSubmit[field.name]: ', dataToSubmit[field.name]);

    // Use function map specific to the field to map form-data to be sent to the server
    if (field?.defaultValue !== undefined) {
      if (field.name in dataToSubmit && !dataToSubmit?.[field.name]) {
        dataToSubmit[field.name] = field.defaultValue;
      }
    }
    // console.log('4. final dataToSubmit[field.name]: ', dataToSubmit[field.name]);

    // Replace comma from decimal number with valid dot annotation
    if (['value', 'payed'].includes(field.name) && dataToSubmit?.[field.name]) {
      dataToSubmit[field.name] = String(dataToSubmit?.[field.name])?.replace(',', '.');
    }
    // console.log('5. final dataToSubmit[field.name]: ', dataToSubmit[field.name]);

    // console.log('field?.disabledCheck?.(dataToSubmit): ', field?.disabledCheck?.(dataBeforeCleanUp));
    if (!dataToSubmit.id && field?.disabledCheck?.(dataBeforeCleanUp)) {
      dataToSubmit[field.name] = field.defaultValue;
    }
    // console.log('6. final dataToSubmit[field.name]: ', dataToSubmit[field.name]);
  });

  return dataToSubmit;
};

export const mapToFormInput: Partial<Record<INPUT_TYPE, any>> = {
  [INPUT_TYPE.MULTI_SELECT_SEARCH]: {
    component: (field: EntityFormField<any>, formik: any) => <MultiSelectSearch field={field} formik={formik} />,
  },
  [INPUT_TYPE.SELECT_SEARCH]: {
    component: (field: EntityFormField<any>, formik: any) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const modal = useRef<HTMLIonModalElement>(null);
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const [search, setSearch] = useState<string>('');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { isLoading, data, isError, error } = field.useHook(1, 'id', SORT_DIRECTION.ASC, search);

      const renderList = () => {
        if (data && data?.data?.length > 0) {
          return data.data.map((item: any) => {
            return (
              <SelectItemCard
                key={item?.id}
                id={item?.id}
                field={item?.[field.fieldValue || 'id']}
                field2={item?.[field.fieldValue2 || '']}
                showId={false}
                onClick={() => {
                  formik.setFieldValue(field?.name, item);
                  modal.current?.dismiss();
                }}
              />
            );
          });
        }
      };

      const handleSearchChange = (ev: any) => {
        setSearch(ev?.detail?.value || '');
      };

      const clearInput = (e: any) => {
        e.stopPropagation();
        formik.setFieldValue(field?.name, null);
      };

      const isValueSelected = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (formik?.values?.[field?.name]?.[field?.fieldValue]) {
          return true;
        }
        return false;
      };

      const getPicker = () => {
        if (isDesktop()) {
          return (
            <IonPopover trigger={`open-picker-${field.name}`} triggerAction="click" size="cover">
              <IonContent className="ion-padding">
                <IonSearchbar
                  value={search}
                  debounce={200}
                  placeholder={i18next.t('FILTERS.SEARCH')}
                  onIonInput={handleSearchChange}
                  onClick={() => modal.current?.setCurrentBreakpoint(0.75)}
                />
                <div className="list-items-itemOfList padding-b-30 list-padding-horizontal">{renderList()}</div>
                {isLoading && <Loading />}
              </IonContent>
            </IonPopover>
          );
        }

        return (
          <IonModal
            ref={modal}
            trigger={`open-picker-${field?.name}`}
            initialBreakpoint={0.25}
            breakpoints={[0, 0.25, 0.5, 0.75]}>
            <IonContent className="ion-padding">
              <IonSearchbar
                value={search}
                debounce={200}
                placeholder={i18next.t('FILTERS.SEARCH')}
                onIonInput={handleSearchChange}
                onClick={() => modal.current?.setCurrentBreakpoint(0.75)}
              />
              <div className="list-items-itemOfList padding-b-30 list-padding-horizontal">{renderList()}</div>
              {isLoading && <Loading />}
            </IonContent>
          </IonModal>
        );
      };

      return (
        <div>
          <div className="g-select-clear">
            <IonIcon icon={closeCircleOutline} style={{ fontSize: 24 }} onClick={clearInput}></IonIcon>
          </div>
          <IonItem
            mode="md"
            className="g-select-size border-radius-input g-select-border cursorPointer flex-center-center-row-wrap"
            id={`open-picker-${field.name}`}>
            <div className="flex-start-center-column-nowrap g-select width-100 height-100 border-radius-24 width-250px">
              <div className="sc-ion-label-md-s normalText3">{i18next.t(`FORMS.${field?.label}`)}</div>
              {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
              {/*@ts-ignore*/}
              {isValueSelected() && (
                <div>
                  {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                  {/*@ts-ignore*/}
                  {formik?.values?.[field?.name]?.[field?.fieldValue]}{' '}
                  {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                  {/*@ts-ignore*/}
                  {formik?.values?.[field?.name]?.[field?.fieldValue2]}
                </div>
              )}
            </div>
          </IonItem>
          {getPicker()}
        </div>
      );
    },
  },
  [INPUT_TYPE.SELECT]: {
    component: (field: EntityFormField<any>, formik: any) => {
      const formValue = formik.values?.[field.name];

      const onChange = (ev: any) => {
        formik.setFieldValue(field.name, ev?.detail?.value);
      };

      const getOptions = () => {
        return field?.data?.map((option, index) => {
          return (
            <IonSelectOption key={index} value={option?.value}>
              {i18next.t(option?.label)}
            </IonSelectOption>
          );
        });
      };

      return (
        <IonItem className="width-300px border-radius-input g-select-border flex-center-center-column-nowrap" mode="md">
          <div className="sc-ion-label-md-s normalText3 g-static-select-label">
            {i18next.t(`FORMS.${field?.label}`)}
          </div>
          <IonSelect
            disabled={field.disabled}
            className="border-radius-24 simple-select g-static-select"
            name={field.name}
            mode="ios"
            value={formValue}
            interface={isDesktop() ? 'popover' : 'action-sheet'}
            onIonChange={onChange}
            placeholder={i18next.t('NOTIFICATIONS.NOTIFICATION_TYPE')}
            cancelText={i18next.t('FORMS.CANCEL')}>
            {getOptions()}
          </IonSelect>
        </IonItem>
      );
    },
  },
  [INPUT_TYPE.TOGGLE]: {
    component: (field: EntityFormField<any>, formik: any) => {
      const formValue = formik.values?.[field.name];

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const getTooltip = useCallback(() => {
        if (field.tooltip) {
          return <TooltipInInput value={field.tooltip} />;
        }
      }, [field.tooltip]);

      return (
        <div className="width-300px border-radius-input b-field-border">
          <div className="g-toggle-container">
            {getTooltip()}
            <div className="sc-ion-label-md-s normalText4 border-radius-24">{i18next.t(`FORMS.${field.label}`)}</div>
            <IonToggle
              disabled={field.disabled}
              name={field.name}
              mode="ios"
              checked={!!formValue}
              className="border-radius-24"
              value={formValue}
              onClick={() => formik.setFieldValue(field.name, !formValue)}></IonToggle>
          </div>
        </div>
      );
    },
  },
  [INPUT_TYPE.TEXT_AREA]: {
    component: (field: EntityFormField<any>, formik: any) => {
      const formValue = formik.values?.[field.name];

      const onChange = (ev: any) => {
        formik.setFieldValue(field.name, ev?.detail?.value);
      };

      return (
        <IonItem className="width-300px border-radius-input g-select-border" mode="md">
          <div className="g-text-area-container border-radius-24">
            <div className="sc-ion-label-md-s normalText4 border-radius-24">{i18next.t(`FORMS.${field.label}`)}</div>
            <IonTextarea
              mode="ios"
              id={`textarea-${field.name}`}
              slot="floating"
              onIonChange={onChange}
              value={formValue}
              autoGrow={true}
            />
          </div>
        </IonItem>
      );
    },
  },
  [INPUT_TYPE.DATE]: {
    component: (field: EntityFormField<any>, formik: any) => {
      const finalDate = moment(formik.values?.[field.name]).format('YYYY-MM-DDTHH:mm:ssZ');

      const getDatePicker = () => {
        // if (isDesktop()) {
        return (
          <>
            {getLocalDate(finalDate)}
            <IonPopover trigger={`open-popover-${field.name}`} triggerAction="click" className="popover-date-picker">
              <IonDatetime
                hourCycle="h23"
                id={`datetime-${field.name}`}
                mode="ios"
                value={finalDate}
                showDefaultButtons
                cancelText={i18next.t('FORMS.CANCEL_DATE')}
                doneText={i18next.t('FORMS.SUBMIT_DATE')}
                locale="el-GR"
                onIonChange={(event) => formik.setFieldValue(field.name, event.detail.value)}>
                <span slot="time-label">{i18next.t('FORMS.TIME')}</span>
              </IonDatetime>
            </IonPopover>
          </>
        );
        // }

        // return (
        //   <>
        //     <IonDatetimeButton datetime={`datetime-${field.name}`} mode="ios"></IonDatetimeButton>
        //     <IonModal keepContentsMounted={true}>
        //       <IonDatetime
        //         hourCycle="h23"
        //         id={`datetime-${field.name}`}
        //         mode="ios"
        //         value={finalDate}
        //         showDefaultButtons
        //         cancelText={i18next.t('FORMS.CANCEL_DATE')}
        //         doneText={i18next.t('FORMS.SUBMIT_DATE')}
        //         locale="el-GR"
        //         onIonChange={(event) => formik.setFieldValue(field.name, event.detail.value)}></IonDatetime>
        //     </IonModal>
        //   </>
        // );
      };

      return (
        <IonItem
          id={`open-popover-${field.name}`}
          className="width-300px border-radius-input g-select-border cursorPointer"
          mode="md">
          <div className="g-datetime-container">
            <div className="sc-ion-label-md-s normalText3 padding-b-6">{i18next.t(`FORMS.${field.label}`)}</div>
            <div className="flex-center-center-row-wrap width-100 padding-b-6">{getDatePicker()}</div>
          </div>
        </IonItem>
      );
    },
  },
  [INPUT_TYPE.B_INPUT]: {
    component: (field: EntityFormField<any>, formik: any) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const ref = useRef<any>(null);

      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        if (field?.focusOnMount) {
          setTimeout(() => {
            ref?.current?.setFocus();
          });
        }
      }, []);

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const isDisabled = useCallback(() => {
        if (field.disabledCheck) {
          return field?.disabledCheck?.(formik.values);
        }

        return false;
      }, [formik?.values, field.disabledCheck]);

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const getTooltip = useCallback(() => {
        if (field.tooltip) {
          return <TooltipInInput value={field.tooltip} />;
        }
      }, [field.tooltip]);

      return (
        <>
          {getTooltip()}
          <IonInput
            ref={ref}
            id={`input-${field.name}`}
            mode="md"
            label={i18next.t(`FORMS.${field.label}`)}
            fill="outline"
            labelPlacement="floating"
            className="width-300px cursorPointer border-radius-input height-64px"
            placeholder={i18next.t(`FORMS.${field.label}`)}
            onIonChange={formik.handleChange}
            disabled={isDisabled()}
            name={field.name}
            value={formik.values?.[field.name]}
            maxlength={field?.maxLength}
            onKeyPress={disableSubmitOnEnter}
            type={field.inputType || 'text'}
          />
        </>
      );
    },
  },
  [INPUT_TYPE.ANTD_SELECT]: {
    component: (field: EntityFormField<any>, formik: any) => {
      return <BSelect field={field} formik={formik} />;
    },
  },
  [INPUT_TYPE.ANTD_SELECT_SEARCH]: {
    component: (field: EntityFormField<any>, formik: any) => {
      return <BSelectSearch field={field} formik={formik} useHook={field.useHook!} />;
    },
  },
};

export const getFormInput = (field: EntityFormField<any>, formik: any) => {
  const inputForm = mapToFormInput[field?.type as INPUT_TYPE];

  if (inputForm) {
    return inputForm.component(field, formik);
  }

  return (
    <IonItem className="width-300px border-radius-input" mode="md">
      <IonInput
        className="height-64px"
        mode="md"
        label={i18next.t(`FORMS.${field.label}`)}
        labelPlacement="stacked"
        type={field.type as TextFieldTypes}
        name={field.name}
        onIonChange={formik.handleChange}
        value={formik.values?.[field.name]}
        maxlength={field?.maxLength}
        onKeyPress={disableSubmitOnEnter}
      />
    </IonItem>
  );
};

export const getOnlyViewField = (field: EntityFormField<any>) => {
  return (
    <IonItem className="width-300px border-radius-input" mode="md">
      <IonInput
        className="height-64px"
        label={i18next.t(`FORMS.${field.label}`)}
        labelPlacement="stacked"
        mode="md"
        type={field.type as TextFieldTypes}
        name={field.name}
        value={field.value}
        disabled
      />
    </IonItem>
  );
};

export const disableSubmitOnEnter = (e: React.KeyboardEvent<HTMLIonInputElement | HTMLDivElement | HTMLElement>) =>
  e.key === 'Enter' && e.preventDefault();
