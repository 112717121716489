import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SORT_BY_CUSTOMERS } from '../../../../interfaces/enumerables';
import { useAppSelector } from '../../../../store/hooks';
import { selectSelectedCompany } from '../../../../store/companies/companiesSlice';
import { useCustomersReport } from '../../../../api/hooks/customers/useCustomersReport';
import { nowDate } from '../../../../config/utils/dateHelpers';
import { PdfDownloadLinkExtended } from '../../../../components/molecules/pdf/PdfDownloadLinkExtended';
import { CustomersReportPdf } from './pdf/CustomersReportPdf';

export const CustomersReportDownloadButton = () => {
  const { t } = useTranslation();
  const selectedCompany = useAppSelector(selectSelectedCompany);
  const { data, isLoading } = useCustomersReport(SORT_BY_CUSTOMERS.COMPANY_NAME, 'ASC');

  const downloadDoc = useCallback(() => {
    if (selectedCompany) {
      const fileName = `${selectedCompany?.title}_${t('CUSTOMERS_REPORT.DOWNLOAD_REPORT').replaceAll(' ', '')}_${nowDate()}.pdf`;

      return (
        <PdfDownloadLinkExtended
          document={<CustomersReportPdf company={selectedCompany} customers={data || []} />}
          fileName={fileName}
          text={t('FORMS.DOWNLOAD_PDF')}
          isLoading={isLoading}
        />
      );
    }
  }, [selectedCompany, t, data, isLoading]);

  return <>{downloadDoc()}</>;
};
