import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { SUBSCRIPTION } from '../../interfaces/enumerables';
import { useHasActivePlan } from '../../services/utils/useHasActivePlan';
import { PATHS } from '../../navigation/data';
import { Loading } from '../../components/atoms/Loading';
import { nowDate } from '../../config/utils/dateHelpers';
import {
  MONEY_COLLECTION_PAYMENT_TYPE,
  MoneyCollectionFormData,
  MoneyCollectionTrader,
  useFormMoneyCollection,
} from '../../api/hooks/useFormMoneyCollection';
import { EntityFormInterface, INPUT_TYPE } from '../../interfaces/forms.inteface';
import { MoneyCollection } from '../../interfaces/moneyCollection.interface';
import { useCustomers } from '../../api/hooks/customers/useCustomers';
import * as yup from 'yup';
import i18next from 'i18next';
import { useMoneyCollection } from '../../api/hooks/moneyCollection/useMoneyCollection';
import { MoneyCollectionForm } from './components/MoneyCollectionForm';

const prefillForm = (entity: any | null | undefined, isClone?: boolean) => {
  if (!entity) return null;

  return {
    traderType: entity?.traderType || null,
    customer: entity?.customer,
    paymentType: entity?.paymentType,
    date: isClone ? nowDate() : entity?.date,
    value: entity?.value,
    paycheckBank: entity?.paycheckBank,
    paycheckNumber: entity?.paycheckNumber,
    paycheckDate: entity?.paycheckDate ? new Date(entity?.paycheckDate).toISOString() : null,
    notes: entity?.notes,
  };
};

const defaultTraderType = (data: MoneyCollectionTrader[]) => {
  return data[0];
};

const defaultPaymentType = (payment: MONEY_COLLECTION_PAYMENT_TYPE[]) => {
  return payment[0];
};

const isEditEntity = (id?: string | undefined, isClone?: boolean) => {
  if (isClone) return false;
  if (id && id !== 'create') return true;

  return false;
};

const getFormData = (
  id?: string | undefined,
  formData?: any,
  entityData?: any | null | undefined,
  isClone?: boolean,
): EntityFormInterface<MoneyCollection> => {
  const prefillData = prefillForm(entityData, isClone);
  const isEdit = isEditEntity(id, isClone);

  return {
    id,
    fields: [
      {
        name: 'traderType',
        label: 'PICK_OTHER_END',
        placeholder: 'TRADER',
        value: prefillData?.traderType || defaultTraderType(formData.traderTypes || []),
        fieldToSubmit: 'value',
        fieldToSubmit2: 'id',
        labelStyle: 'padding-b-16 padding-l-12',
        translationGroupKey: 'MONEY_COLLECTION',
        data: formData?.traderTypes,
        type: INPUT_TYPE.ANTD_SELECT_SEARCH,
        disabled: isEdit,
        tooltip: isEdit ? 'MONEY_COLLECTION_UNABLE_TO_EDIT_FIELD' : '',
      },
      {
        name: 'customerId',
        label: 'PICK_OTHER_END',
        placeholder: 'CUSTOMER',
        value: prefillData?.customer || null,
        fieldValue: 'companyName',
        fieldValue2: 'lastName',
        fieldToSubmit: 'id',
        // @ts-expect-error happens
        useHook: useCustomers,
        required: true,
        type: INPUT_TYPE.ANTD_SELECT_SEARCH,
        disabled: isEdit,
        tooltip: isEdit ? 'MONEY_COLLECTION_UNABLE_TO_EDIT_FIELD' : '',
      },
      {
        name: 'date',
        label: 'DOC_DATE',
        placeholder: 'DOC_DATE',
        value: prefillData?.date || nowDate(),
        type: INPUT_TYPE.DATE,
        disabled: isEdit,
        tooltip: isEdit ? 'MONEY_COLLECTION_UNABLE_TO_EDIT_FIELD' : '',
      },
      {
        name: 'value',
        label: 'AMOUNT',
        placeholder: 'AMOUNT',
        value: entityData?.value || 0,
        type: INPUT_TYPE.B_INPUT,
        disabled: isEdit,
        tooltip: isEdit ? 'MONEY_COLLECTION_UNABLE_TO_EDIT_FIELD' : '',
      },
      {
        name: 'paymentType',
        label: 'MONEY_COLLECTION_PAYMENT_TYPES',
        placeholder: 'MONEY_COLLECTION_PAYMENT_TYPES',
        value: prefillData?.paymentType || defaultPaymentType(formData?.paymentTypes || []),
        fieldValue: 'value',
        fieldToSubmit: 'value',
        type: INPUT_TYPE.ANTD_SELECT,
        data: formData?.paymentTypes || [],
        disabled: isEdit,
        tooltip: isEdit ? 'MONEY_COLLECTION_UNABLE_TO_EDIT_FIELD' : '',
      },
      {
        name: 'paycheckBank',
        label: 'PAY_CHECK_BANK',
        placeholder: 'PAY_CHECK_BANK',
        value: prefillData?.paycheckBank || '',
        type: INPUT_TYPE.TEXT,
        required: true,
        maxLength: 100,
      },
      {
        name: 'paycheckNumber',
        label: 'PAY_CHECK_NUMBER',
        placeholder: 'PAY_CHECK_NUMBER',
        value: prefillData?.paycheckNumber || '',
        type: INPUT_TYPE.TEXT,
        required: true,
        maxLength: 100,
      },
      {
        name: 'paycheckDate',
        label: 'PAY_CHECK_DATE',
        placeholder: 'PAY_CHECK_DATE',
        value: prefillData?.paycheckDate || nowDate(),
        type: INPUT_TYPE.DATE,
      },
      {
        name: 'notes',
        label: 'PUBLIC_NOTES',
        placeholder: 'PUBLIC_NOTES_DESCRIPTION',
        value: prefillData?.notes || '',
        type: INPUT_TYPE.TEXT_AREA,
      },
      {
        name: 'resetSerialNumber',
        label: 'RESET_SERIAL_NUMBER_TITLE',
        placeholder: 'RESET_SERIAL_NUMBER_DESCRIPTION',
        tooltip: 'RESET_SERIAL_NUMBER_DESCRIPTION',
        value: false,
        type: INPUT_TYPE.TOGGLE,
      },
    ],
  };
};

const validationSchema = yup.object({
  traderType: yup.mixed().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
  customerId: yup.object().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
  date: yup.date().required(),
  value: yup
    .number()
    .required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD'))
    .min(0.01, i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
  paymentType: yup.mixed().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
  paycheckBank: yup.string().when('paymentType', {
    is: (paymentType: any) => {
      const paymentTypeValue = paymentType?.value || paymentType;
      return (
        paymentTypeValue === MONEY_COLLECTION_PAYMENT_TYPE.MONEY_CHECK ||
        paymentTypeValue === MONEY_COLLECTION_PAYMENT_TYPE.RETURN_MONEY_CHECK
      );
    },
    then: () => yup.string().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
    otherwise: () => yup.string().nullable(),
  }),
  paycheckNumber: yup.string().when('paymentType', {
    is: (paymentType: any) => {
      const paymentTypeValue = paymentType?.value || paymentType;
      return (
        paymentTypeValue === MONEY_COLLECTION_PAYMENT_TYPE.MONEY_CHECK ||
        paymentTypeValue === MONEY_COLLECTION_PAYMENT_TYPE.RETURN_MONEY_CHECK
      );
    },
    then: () => yup.string().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
    otherwise: () => yup.string().nullable(),
  }),
  paycheckDate: yup.date().when('paymentType', {
    is: (paymentType: any) => {
      const paymentTypeValue = paymentType?.value || paymentType;
      return (
        paymentTypeValue === MONEY_COLLECTION_PAYMENT_TYPE.MONEY_CHECK ||
        paymentTypeValue === MONEY_COLLECTION_PAYMENT_TYPE.RETURN_MONEY_CHECK
      );
    },
    then: () => yup.string().required(i18next.t('FORMS.ERRORS.REQUIRED_FIELD')),
    otherwise: () => yup.string().nullable(),
  }),
  notes: yup.string().notRequired(),
  resetSerialNumber: yup.boolean().notRequired(),
});

interface FormState {
  formData: MoneyCollectionFormData;
  entityData: MoneyCollection | null;
}

export const NewMoneyCollectionScreen = () => {
  useHasActivePlan(true, SUBSCRIPTION.SUBTOTALS);
  const { id } = useParams<{ id: string }>();
  const { pathname, state } = useLocation<any>();
  const isClone = useMemo(() => pathname.includes(PATHS.CLONE_MONEY_COLLECTION), [pathname]);
  const entityDataFromNavigation = useMemo(() => {
    if (state?.customer) return { customer: state.customer };

    return null;
  }, [state?.customer]);
  const isCreate = useMemo(() => id === undefined, [id]);
  const { data: entityData, isLoading: isLoadingEntity } = useMoneyCollection(id);
  const { data: formData, isLoading } = useFormMoneyCollection();
  const [data, setData] = useState<FormState | null>(null);
  const [isReady, setIsReady] = useState(false);
  const entityDataReady = useMemo(() => isCreate || entityData, [isCreate, entityData]);

  useEffect(() => {
    if (formData && entityDataReady) {
      const helpData: FormState = { formData, entityData: null };
      if (id && entityData) {
        helpData.entityData = entityData;
      }

      setData(helpData);

      setIsReady(true);
    }
  }, [formData, entityData, entityDataReady, id]);

  const getMainSection = useCallback(() => {
    if (isReady) {
      const dataForm = getFormData(id, data?.formData, data?.entityData || entityDataFromNavigation, isClone);
      return <MoneyCollectionForm data={dataForm} validationSchema={validationSchema} isClone={isClone} />;
    }
  }, [isReady, id, data?.formData, data?.entityData, entityDataFromNavigation, isClone]);

  return (
    <div>
      {(isLoadingEntity || isLoading) && <Loading backgroundFull />}
      {getMainSection()}
    </div>
  );
};
