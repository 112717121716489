import { StyleSheet, View } from '@react-pdf/renderer';
import * as React from 'react';
import { useCallback } from 'react';
import { PdfProduct } from './PdfProduct';
import { ProductInOrder } from '../../../../interfaces/product.interface';
import { getProdIndexInDoc } from '../../../../services/utils/pdf';
import { InvoiceInterface } from '../../../../interfaces/invoice.interface';

interface Props {
  doc: InvoiceInterface;
  products: ProductInOrder[];
  docHasVatExceptionCategory: boolean;
}

export const PdfProductsList = ({ doc, products, docHasVatExceptionCategory }: Props) => {
  const getProductIndex = useCallback(
    (product: ProductInOrder) => {
      if (doc?.products) {
        return getProdIndexInDoc(doc?.products, product);
      }

      return -1;
    },
    [doc?.products],
  );

  const getProducts = useCallback(() => {
    return products?.map((prod, index) => {
      const itemIndex = getProductIndex(prod);
      return (
        <PdfProduct
          key={index}
          product={prod}
          index={itemIndex}
          docHasVatExceptionCategory={docHasVatExceptionCategory}
        />
      );
    });
  }, [products, getProductIndex, docHasVatExceptionCategory]);

  return <View style={styles.container}>{getProducts()}</View>;
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: 2,
  },
});
