import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MONEY_COLLECTION_PAYMENT_TYPE } from '../../../api/hooks/useFormMoneyCollection';
import { IonItem } from '@ionic/react';
import { Select } from 'antd';
import { ErrorForm } from '../../../components/atoms/ErrorForm';
import { Customer } from '../../../interfaces/customer.interface';

interface Props {
  field: any;
  formik: any;
}

export const MoneyCollectionPaymentType = ({ field, formik }: Props) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<MONEY_COLLECTION_PAYMENT_TYPE[]>(field?.data || []);
  const formValue = useMemo(() => formik?.values?.[field.name], [formik?.values?.[field.name]]);
  const customerSelected = useMemo<Customer>(() => formik.values?.customerId, [formik.values?.customerId]);
  const [showClearingOption, setShowClearingOption] = useState(false);

  useEffect(() => {
    if (!field?.data?.length) return;

    if (showClearingOption) {
      setOptions(field?.data);
      return;
    }

    const newOptions = field?.data.filter((option: string) => option !== MONEY_COLLECTION_PAYMENT_TYPE.CLEARING);
    setOptions(newOptions);

    // Select valid payment type since trader is not both customer and supplier
    if (formValue?.value === MONEY_COLLECTION_PAYMENT_TYPE.CLEARING) {
      formik.setFieldValue('paymentType', MONEY_COLLECTION_PAYMENT_TYPE.CASH);
    }
  }, [showClearingOption, formValue]);

  useEffect(() => {
    if (
      customerSelected &&
      Number(customerSelected?.customerSubtotal || 0) > 0 &&
      Number(customerSelected?.supplierSubtotal || 0) > 0
    ) {
      setShowClearingOption(true);
      return;
    }

    setShowClearingOption(false);
  }, [customerSelected]);

  const onChange = (_: string, option: any) => {
    formik.setFieldValue(field.name, option);
  };

  const getOptions = useCallback(() => {
    return options?.map((option: MONEY_COLLECTION_PAYMENT_TYPE) => {
      return {
        label: t(`MONEY_COLLECTION_PAYMENT_TYPES.${option}`),
        value: option,
      };
    });
  }, [options]);

  return (
    <div>
      <div className="padding-b-16 padding-l-12">{t('SALE.PAYMENT_TYPE')} *</div>
      <div key={field?.name} className="flex-start-center-column-nowrap positionRelative width-300px">
        <IonItem className={`width-300px border-radius-input g-select-border ${field?.disabled && 'b-disabled-border'}`} mode="md">
          <Select
            optionFilterProp="children"
            className="my-ant-select normalText5"
            variant="borderless"
            onChange={onChange}
            options={getOptions()}
            value={formValue}
            disabled={field.disabled}
          />
        </IonItem>
        <ErrorForm>{formik?.errors?.[field?.name] && formik?.errors?.[field?.name]}</ErrorForm>
      </div>
    </div>
  );
};
